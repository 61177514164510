import React, { useState, useContext, useEffect } from 'react';
import '../../../css/lead.scss';
// import useMoengage from '../../../hooks/useMoengage';
import { trackEvent } from '../../../service/engagementMonitoringService';
import CONSTANTS from '../../../constants/constants';
import useGAEventsTracker from '../../../service/useGSEventsTracker';
import moment from "moment";
import { UpOutlined, DownOutlined, CheckOutlined, CloseOutlined, PhoneFilled, CheckCircleOutlined, DashOutlined, CalendarOutlined, UploadOutlined, WhatsAppOutlined, UserOutlined, PhoneOutlined, CopyOutlined, EnvironmentOutlined, ArrowRightOutlined, ArrowUpOutlined, UserAddOutlined, ExclamationCircleOutlined, InfoCircleOutlined, RedoOutlined } from '@ant-design/icons';
import { Button, Tag, Space, Divider, Typography, Image } from 'antd';
import { AdditionalFormsURLEnum } from '../../../utils/utility';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../App';
import { red } from '@material-ui/core/colors';
import { getDisplayableClientName } from '../../../utils/commonFunction';
import { claimMitraAppLeads } from '../../../service/additionalInfoService'
import { jobseekerApplicationInML } from '../../../constants/utils'
import { ROLES } from '../roles';
import WhatsappTemplateModal from '../../whatsappTemplateModal/WhatsappTemplateModal';
import SpinningLoader from '../../../static/images/loader-rolling.gif';
const { Text } = Typography;


function SingleApplicationContainer({key, lead, leadDetails, index, setSpin, setShowDetail, showViewDetails, setSelectedLead, checkStatus, referagainBool, setReferAgain, setCurrentJob, mitra, setSelectedScheduledInterviewId, currentClientSiId, currentClientRhId, setSelectedRecommendationHistoryId, realtimeUniquenessStatusData, siToCheckUniquenessStatus, showLoaderForUniqueness, showCompleteApplicationForUniqueSi, isUniquenessPending }) {
    const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
    const history = useHistory();
    const { mitraReducer, userRole } = useContext(AppContext);
    const [isBronzeML, setIsBronzeML] = React.useState(localStorage.getItem('isBronzeML'))
    const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);
    const [selectedApplicationIndex, setSelectedApplicationIndex] = useState(null);
    const [callLogsOpened, setCallLogsOpened] = useState(false);
    const [isUniqueLead, setIsUniqueLead] = useState(false);
    // const [createMoengageUser, trackEvent] = useMoengage();

    useEffect(() => {
        if (
            lead.scheduledInterviewId.length >= index - 1 && // checking if SI id is created for corresponding rhId
            realtimeUniquenessStatusData && Object.keys(realtimeUniquenessStatusData)?.includes(lead.scheduledInterviewId[index]) // checking if siId exists in uniqueness polling api data
        ) {
            if (realtimeUniquenessStatusData[lead.scheduledInterviewId[index]]?.isNonUnique) {
                setIsUniqueLead(false);
            }
            else {
                setIsUniqueLead(true);
            }
        }
    }, [realtimeUniquenessStatusData])

    const diffBetweenTwoDates = (statusTime, rhTime) => {
        const first = statusTime ? statusTime : rhTime
        return moment(first).format('DD MMMM YYYY')
    }
    const claimLeadClick = async (lead, index) => {
        const response = await claimMitraAppLeads(
            {
                phoneNumber: lead.phoneNumber,
                scheduledInterviewId: lead.scheduledInterviewId[index],
                siMetaData: { isTransferredToMl: true },
                mitraId: mitra.id
            },
            {
                updateSiMetaData: true
            }
        )
        if (response.data && response.data.updateSiMetaData == '200') {
            lead.siMetaData[index] = lead.siMetaData[index] ? { ...lead.siMetaData[index], isTransferredToMl: true } : { isTransferredToMl: true }
        }
    }

    const onClickUpDown = (event) => {
        event.stopPropagation();
        if (!callLogsOpened) {
            trackEvent('viewed_expandable_call_notes', { candidate_phone_number: lead.phoneNumber }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        }
        setCallLogsOpened(!callLogsOpened);
    }

    const onClickViewDetails = (e) => {
        setSelectedScheduledInterviewId([currentClientSiId]);
        setSelectedRecommendationHistoryId([currentClientRhId]);
        trackEvent('view_details_button_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setSpin({
            loading: true, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
        setShowDetail(true);
        setSelectedLead(lead);
        GAEventsTracker('OpenViewDetails')
        setSpin({
            loading: false, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
        e.stopPropagation();
    }

    const viewAdditionalFormHandler = (lead, index) => {
        const paramObject = {
            name: lead.lastName.includes('Unknown') ? lead.firstName : lead.firstName + " " + lead.lastName,
            phoneNumber: lead.phoneNumber,
            scheduledInterviewId: lead.scheduledInterviewId[index],
            companyName: lead.companies[index],
            jobId: lead.jobId[index],
            jobDemandId: lead.jobDemandId[index],
            companyCity: lead.companyCity[index],
            ...(lead.companies[index]?.toLowerCase().indexOf('rapido') !== -1 ? {
                candidateId: lead.siMetaData[index] ? lead.siMetaData[index].rapidoMilestone ? lead.siMetaData[index].rapidoMilestone.captainId : null : null
            } : {}),
            meta: JSON.stringify(lead.siMetaData[index]),
        }
        viewAdditionalForm(paramObject);
    }

    const viewAdditionalForm = (lead) => {
        if (lead?.companyName?.toLowerCase().indexOf('zomato') !== -1) {
            history.push({
                pathname: '/zomato-additional-form',
                route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
                search: new URLSearchParams(lead).toString()
            });
        } else if (lead?.companyName?.toLowerCase().indexOf('swiggy soc') !== -1) {
            history.push({
                pathname: '/swiggy-soc-additional-form',
                route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
                search: new URLSearchParams(lead).toString()
            });
        } else if (lead?.companyName?.toLowerCase().indexOf('blinkit') !== -1) {
            history.push({
                pathname: '/blinkit-additional-form',
                route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
                search: new URLSearchParams(lead).toString()
            });
        } else if (lead?.companyName?.toLowerCase() === 'zepto') {
            history.push({
                pathname: '/zepto-additional-form',
                route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
                search: new URLSearchParams(lead).toString()
            });
        } else if (lead?.companyName?.toLowerCase().indexOf('swiggy') !== -1) {
            history.push({
                pathname: '/swiggy-additional-form',
                route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
                search: new URLSearchParams(lead).toString()
            });
        } else {
            history.push({
                pathname: '/moreInfo',
                route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
                search: new URLSearchParams(lead).toString()
            })
        }

    }

    const onClickCompleteApplication = (e) => {
        viewAdditionalFormHandler(lead, index);
        trackEvent('complete_application_clicked_on_candidate_card', { candidate_phone_number: lead.phoneNumber, application_id: lead.scheduledInterviewId[index] }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }

    const onClickReferAgain = (e) => {
        setReferAgain(true);
        setCurrentJob({ scheduledInterviewId: lead.scheduledInterviewId[index], productSource: lead.productSource.length > 0 ? lead.productSource[index] : '', parentPhoneNumber: lead.parentPhoneNumber ? lead.parentPhoneNumber[index] : '', childPhoneNumber: lead.childPhoneNumber?.length > 0 ? lead.childPhoneNumber[index] : '', companyName: lead.companies[index], status: lead.latestMilestone[index], phoneNumber: lead.phoneNumber, name: lead.lastName.includes('Unknown') ? lead.firstName : lead.firstName + " " + lead.lastName, mitraId: mitra.id, jobType: lead.jobType ? lead.jobType[index] : "", jobId: lead.jobId[index], jobDemandId: lead.jobDemandId[index], companyCity: lead.companyCity[index], role: lead.role[index] });
        trackEvent('refer_again_clicked_on_candidate_card', { candidate_phone_number: lead.phoneNumber, application_id: lead.scheduledInterviewId[index] }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setSelectedLead(lead);
        e.stopPropagation();
    }

    const openWhatsAppPop = (event, index) => {
        event.stopPropagation();
        setSelectedApplicationIndex(index);
        setWhatsAppMessageModal(true)
    }
    const copyPhoneNumberFunc = (event, phoneNumber) => {
        event.stopPropagation();
        navigator.clipboard.writeText(phoneNumber);
    }

    const whatsAppMessageModalCancel = (e) => {
        e.stopPropagation();
        setWhatsAppMessageModal(false);
    };

    const callNotesArray = () => {
        return (
            <>
                <div className='hot-tip-main-container' style={{ marginTop: '10px' }}>
                    <PhoneFilled className='call-notes-icon' />
                    <span className='call-notes-heading'>Call Notes</span>
                </div>
                <div className='call-notes-tag'>
                    {lead?.callLogsTagsArray[index]?.map((item, idx) => (
                        item ?
                            (item.toLowerCase().includes('not connected') ?
                                <Tag style={{ marginTop: 8 }}><CloseOutlined className='not-connected-tag' />{item}</Tag> :
                                (item.toLowerCase().includes('connected') ?
                                    <Tag style={{ marginTop: 8 }}><CheckOutlined className='connected-tag' />{item}</Tag> :
                                    <Tag style={{ marginTop: 8 }}>{item}</Tag>)
                            ) : null
                    ))}
                    {lead.callLogCommentArray?.[index]?.length && lead.callLogCommentArray[index] != "" ?
                        <div className='call-notes-comment'>
                            &ldquo;{lead.callLogCommentArray[index]}&rdquo;
                        </div>
                        : null}
                </div>
            </>
        )
    };

    const returnLineImage = (lineLength = 0, dashed = false, isMobile = false) => {
        return (
            Array.from({ length: lineLength }).map(() => {
                return isMobile ? (
                    dashed ? <div style={{ width: "1px", height: "4px", backgroundColor: "rgba(0, 0, 0, 0.15)", marginLeft: "7px", marginTop: "2px" }}></div> : <div style={{ width: "1px", height: "6px", backgroundColor: "rgba(0, 0, 0, 0.15)", marginLeft: "7px" }}></div>
                ) : (
                    dashed ? <DashOutlined className='application-timeline-line-img' /> : <hr className='hr-no-margin' style={{ width: "4px", height: "1px", border: 'none', borderTop: '1px solid rgba(0, 0, 0, 0.15)' }} />
                )
            })
        )
    }

    const returnTimelineView = () => {
        return (
            <Space direction='horizontal' className='application-timeline-view show-only-desktop'>
                <div className='d-flex left-of-separator'>
                    <div className='d-flex flex-column justify-content-center align-items-center recently-completed-milestone'>
                        <div className='d-flex justify-content-center align-items-center'>
                            {returnLineImage(4, true, false)}
                            <CheckCircleOutlined className='completed-step-icon' />
                            <div className='d-flex' style={{ gap: 0 }}>
                                {returnLineImage(16, false, false)}
                            </div>
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <Text className='recently-completed-milestone-txt'>
                                {
                                    isUniqueLead ?
                                        lead.laterMilestones[index * 2]?.milestone
                                        :
                                        lead.completedMilestone[index]?.milestone
                                }
                            </Text>
                            <Text className='next-txt'>
                                {
                                    isUniqueLead ?
                                        lead.laterMilestones[index * 2]?.time
                                        :
                                        lead.completedMilestone[index]?.time
                                }
                            </Text>
                        </div>
                    </div>

                    {returnLineImage(24, false)}

                    <div className='d-flex flex-column justify-content-center align-items-center next-milestone'>
                        <div className='d-flex justify-content-center align-items-center'>
                            {returnLineImage(12, false, false)}
                            {lead.nextMilestone[index]?.time?.toLowerCase() != 'next'
                            ?
                                <CheckCircleOutlined className='completed-step-icon' />
                            :
                                <div className='orange-circle' />
                            }
                            {returnLineImage(4, true, false)}
                        </div>
                        <div className='d-flex flex-column justify-content-center align-items-center'>
                            <Text className={lead.nextMilestone[index]?.time?.toLowerCase() != 'next' ? 'recently-completed-milestone-txt' : 'next-milestone-txt'}>
                                {
                                    isUniqueLead ?
                                        lead.laterMilestones[(index * 2) + 1]?.milestone
                                        :
                                        lead.nextMilestone[index]?.milestone
                                }
                            </Text>
                            <Text className='next-txt'>
                                {
                                    isUniqueLead ?
                                        lead.laterMilestones[(index * 2) + 1]?.time
                                        :
                                        lead.nextMilestone[index]?.time
                                }
                            </Text>
                        </div>
                    </div>
                </div>

                <div className='timeline-steps-completed-separator' style={{ marginLeft: '15px', marginRight: '15px' }}>
                    <div className='application-timeline-view-separator'>

                    </div>
                </div>

                <div className='steps-completed d-flex justify-content-center align-items-center flex-column'>
                    <div className='trips-count-completed-txt'>{lead.totalOrders?.[index] ? lead.totalOrders[index] : '0'}</div>
                    <div className='trips-completed-txt'>
                        Trips completed
                    </div>
                </div>
            </Space>
        )
    }

    const getUniquenessLabel = () => {

        // For companies other than Zomato, return latestMilestone for that index returned by the API
        // if (lead.companies?.[index].toLowerCase() !== 'zomato') return lead.latestMilestone[index];
        if (
            lead.scheduledInterviewId.length >= index - 1 && // checking if SI id is created for corresponding rhId
            realtimeUniquenessStatusData && Object.keys(realtimeUniquenessStatusData)?.includes(lead.scheduledInterviewId[index]) // checking if siId exists in uniqueness polling api data
        ) {
            if (realtimeUniquenessStatusData[lead.scheduledInterviewId[index]]?.isNonUnique) {
                // setUniquenessStatus("nonUnique");
                return 'Non Unique';
            }
            else {
                // setUniquenessStatus("unique");
                return 'Unique';
            }
            // return realtimeUniquenessStatusData[lead.scheduledInterviewId[0]]?.isNonUnique ? 'Non Unique' : 'Unique'
        } else {
            // if current siId is included in evaluation then set/unset loader else send original response
            if (siToCheckUniquenessStatus?.includes(lead.scheduledInterviewId[index])) {
                if (showLoaderForUniqueness) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>{lead.latestMilestone[index]}</div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: '8px', alignSelf: 'center' }}>Checking Uniqueness</div>
                                <Image src={SpinningLoader} alt="loading" height={'auto'} width={18} />
                            </div>
                        </div>

                    )
                } else {
                    // setUniquenessStatus("pending");
                    return (
                        <>
                            <div>{lead.latestMilestone[index]} <br /> Please check uniqueness status after some time</div>
                        </>
                    )
                }
            } else return (
                <div>{lead.latestMilestone[index]}</div>
            )
        }
    };

    // console.log(mitraReducer);

    return (
        <>

            {whatsAppMessageModal ?
                <WhatsappTemplateModal
                    lead={lead}
                    leadDetails={leadDetails}
                    whatsAppMessageModal={whatsAppMessageModal}
                    whatsAppMessageModalCancel={whatsAppMessageModalCancel}
                    selectedApplicationIndex={selectedApplicationIndex}
                ></WhatsappTemplateModal>
                : null}
            <div className="single-application-container" style={{ width: "100%" }} onClick={onClickViewDetails}>
                {/* <Divider style={{margin:'8px 0'}}/> */}
                <div key={index} className={lead.latestMilestone[index] !== 'Upload Documents' ?
                    (checkStatus({
                        scheduledInterviewId: lead.scheduledInterviewId[index], productSource: lead.productSource.length > 0 ?
                            lead.productSource[index] : null, parentPhoneNumber: lead.parentPhoneNumber ?
                                lead.parentPhoneNumber[index] : null, childPhoneNumber: lead.childPhoneNumber?.length > 0 ? lead.childPhoneNumber[index] : null, companyName: lead.companies[index], status: lead.latestMilestone[index]
                    }) ?
                        'incompleteApplicationBG' : '') : (lead.referAgain ? '' : 'incompleteApplicationBG')} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                    <Space key={index} direction='vertical' style={{ width: "100%" }}>
                        <div className='d-flex justify-content-between' style={{ width: "100%" }}>
                            <div className='hot-lead-main-container-left-side' style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                                <div className="single-job-container">
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div className="client-logo">
                                                <img className="imgResponse" style={{ width: 68, heigth: 32 }} alt={getDisplayableClientName(lead.companies[index])} src={lead?.logos[index]} />
                                            </div>
                                            <div style={{ marginLeft: '1em', color: '#000000D9', fontWeight: '500', fontSize: '16px' }}>
                                                {lead.lastName.includes('Unknown') ? lead.firstName : lead.firstName + " " + lead.lastName}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Space direction='horizontal' className="onboarding-schedule">
                                    {lead.companyCity ?
                                        <div className='display-city-name'>
                                            <EnvironmentOutlined style={{ marginRight: '3px' }} /> {lead.companyCity[index]}
                                        </div> : null}
                                    <div className='display-city-name' style={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                                        <CalendarOutlined style={{ marginRight: '3px' }} />
                                        <span className='show-only-desktop'>Referred on&nbsp;</span>{diffBetweenTwoDates(lead?.ujfmCreatedAt?.[index], lead?.rhCreatedAt?.[index])}
                                    </div>
                                </Space>
                                <div className="leadsCardStatus d-flex flex-row" style={lead.latestMilestone[index] === 'Upload Documents' ? { color: '#000', fontFamily: 'Nunito Sans Bold' } :
                                    (lead.latestMilestone[index] === 'F25 ~ First 25 Trips' || lead.latestMilestone[index] === 'F10 ~ First 10 Trips' || lead.latestMilestone[index] === 'F1 ~ First Trip') ? { color: '#52C41A' } : null}>
                                    {(
                                        // (
                                            // lead.ujfMetaData && lead.ujfMetaData.length && lead.companies[index] === 'Swiggy' && lead?.ujfMetaData[index]?.swiggy_uniqueness) ? lead.ujfMetaData[index].swiggy_uniqueness :
                                            getUniquenessLabel()
                                    )}
                                </div>
                                {lead.completedMilestone?.[index]?.milestone && lead.nextMilestone?.[index]?.milestone ?
                                    <>
                                        {
                                            // uniquenessStatus && (uniquenessStatus == "unique" || uniquenessStatus == "pending") ?
                                            // key == 0
                                                // ?
                                                //  (getUniquenessLabel() == "Unique" || isUniquenessPending) ?
                                                //     returnTimelineView()
                                                //     : null
                                            //     :
                                                (getUniquenessLabel() !== "Non Unique") ?
                                                    returnTimelineView()
                                                    :
                                                    null
                                        }

                                        <Space direction='horizontal' className='application-timeline-view show-only-mobile'>
                                            <div className='d-flex flex-row align-center'>
                                                <div className='d-flex flex-column left-of-separator-mobile'>
                                                    <div className='d-flex flex-column justify-content-center align-items-center recently-completed-milestone'>
                                                        <div className='d-flex flex-column justify-content-flex-start align-items-flex-start'>
                                                            <div style={{ marginBottom: "-6px" }}>
                                                                {returnLineImage(2, true, true)}
                                                            </div>
                                                            <div className='d-flex flex-row justify-content-center align-items-center'>
                                                                <CheckCircleOutlined className='completed-step-icon-mobile' />
                                                                <div className='d-flex flex-column justify-content-flex-start align-items-flex-start recently-completed-milestone-mobile'>
                                                                    <Text className='recently-completed-milestone-txt recently-completed-milestone-txt-mobile'>
                                                                        {lead.completedMilestone[index]?.milestone}
                                                                    </Text>
                                                                    <Text className='next-txt'>
                                                                        {lead.completedMilestone[index]?.time}
                                                                    </Text>
                                                                </div>
                                                            </div>
                                                            {returnLineImage(2, false, true)}
                                                        </div>
                                                    </div>

                                                    <div style={{ marginTop: "-16px" }}>
                                                        {returnLineImage(4, false, true)}
                                                    </div>

                                                    <div className='d-flex flex-row justify-content-flex-start align-items-flex-start next-milestone'>
                                                        {/* <div className='d-flex justify-content-center align-items-center'> */}
                                                        {/* {returnLineImage(4, false)} */}
                                                        {lead.nextMilestone[index]?.time?.toLowerCase() != 'next'
                                                        ?
                                                            <CheckCircleOutlined className='completed-step-icon-mobile' />
                                                        :
                                                            <div className='orange-circle-mobile' />
                                                        }
                                                        {/* {returnLineImage(4, true)} */}
                                                        {/* </div> */}
                                                        <div className='d-flex flex-column justify-content-flex-start align-items-flex-start next-milestone-mobile'>
                                                            <Text className={lead.nextMilestone[index]?.time?.toLowerCase() != 'next' ? 'recently-completed-milestone-txt-mobile' : 'next-milestone-txt-mobile'}>
                                                                {lead.nextMilestone[index]?.milestone}
                                                            </Text>
                                                            <Text className='next-txt'>
                                                                {lead.nextMilestone[index]?.time}
                                                            </Text>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: "-16px" }}>
                                                        {returnLineImage(4, true, true)}
                                                    </div>
                                                </div>

                                                <div className='timeline-steps-completed-separator-mobile'>
                                                    <div className='application-timeline-view-separator-mobile'>

                                                    </div>
                                                </div>

                                                <div className='steps-completed-mobile d-flex flex-column justify-content-center align-items-center'>
                                                    <div className='trips-count-completed-txt'>0</div>
                                                    <div className='trips-completed-txt'>
                                                        Trips completed
                                                    </div>
                                                </div>
                                            </div>
                                        </Space>
                                    </>
                                    : null}
                                {lead.zomatoMilestones ?
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {JSON.parse(lead.zomatoMilestones).map((milestone, idx) => (
                                            milestone.milestoneName.includes('referral_date_si') || (milestone.milestoneRank >= 260 && milestone.milestoneRank <= 320) ? '' :
                                                <div key={idx} style={{ backgroundColor: '#d34612', color: '#FFF', marginRight: '5px', padding: '2px 5px', borderRadius: '3px' }}>
                                                    {milestone.milestoneName.replaceAll('_', ' ')}
                                                </div>
                                        ))}
                                    </div> :
                                    <div style={{ height: '10px', marginTop: '5px' }}></div>}
                                <div className='candidate-card-action-required-buttons-container'>
                                    {
                                        lead.companies[index].toLowerCase().includes('3pl') && ROLES.DC === userRole ?
                                            <>
                                                {
                                                    <div onClick={(e) => {
                                                        if (!lead.siMetaData[index] || (lead.siMetaData[index] && !lead.siMetaData[index]['isTransferredToMl'])) {
                                                            claimLeadClick(lead, index)
                                                        }
                                                    }}>
                                                        <Button icon={<RedoOutlined />} type="primary" style={{ marginRight: '5px', backgroundColor: !lead.siMetaData[index] || (lead.siMetaData[index] && !lead.siMetaData[index]['isTransferredToMl']) ? '#d34612' : '#03bd03' }}>{!lead.siMetaData[index] || (lead.siMetaData[index] && !lead.siMetaData[index]['isTransferredToMl']) ? 'Claim Application' : 'Claimed'}</Button>
                                                    </div>

                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        (lead?.siMetaData?.[index]?.rapidoMilestone?.value?.toString().includes('Please upload') || lead?.siMetaData?.[index]?.rapidoMilestone?.value?.toString().includes('Pending') || lead?.siMetaData?.[index]?.rapidoMilestone?.value?.toString().includes('Rejected') || (lead?.siMetaData?.[index]?.isApplicationCompleted === false && lead?.siMetaData?.[index]?.documentUploadStarted === true)) ?
                                            <>
                                                {
                                                    AdditionalFormsURLEnum[lead.companies[index]?.toLowerCase()] ?
                                                        <div onClick={onClickCompleteApplication}>
                                                            <Button icon={<UploadOutlined />} type="primary" ghost>Upload Documents</Button>
                                                        </div>
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        (lead.companies[index].toLowerCase().includes('shadowfax') && ( lead.siMetaData?.[index]?.incompleteApplication || (lead.latestMilestone[index] == 'Unique' && lead.siMetaData?.[index]?.incompleteApplication == null) ) && lead.latestMilestone[index] !== 'Non Unique') ?
                                            <>
                                                {
                                                    AdditionalFormsURLEnum[lead.companies[index]?.toLowerCase()] ?
                                                        <div onClick={onClickCompleteApplication}>
                                                            <Button icon={<UploadOutlined />} type="primary" ghost>Upload Documents</Button>
                                                        </div>
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        // console.log(lead.latestMilestone[index], !(JSON.stringify(lead?.siMetaData?.[index]).includes('formTwoCompleted')), lead.companies[index]?.toLowerCase().indexOf('zepto_3pl') !== -1, lead.latestMilestone[index] !== 'Incomplete Application' , lead.latestMilestone[index] !== 'Non Unique' , !(JSON.stringify(lead?.siMetaData?.[index]).includes('formThreeCompleted')) && (lead?.scheduledInterviewId) ) &&
                                        // console.log(lead?.latestMilestone[index]) &&
                                        !(JSON.stringify(lead?.siMetaData?.[index]).includes('formTwoCompleted')) && lead.companies[index]?.toLowerCase().indexOf('zepto_3pl') !== -1 && lead.latestMilestone[index] !== 'Incomplete Application' && lead.latestMilestone[index] !== 'Non Unique' && !(JSON.stringify(lead?.siMetaData?.[index]).includes('formThreeCompleted')) && (lead?.scheduledInterviewId) ?
                                            <>
                                                {
                                                    AdditionalFormsURLEnum[lead.companies[index]?.toLowerCase()] ?
                                                        <div onClick={onClickCompleteApplication}>
                                                            {/* { lead?.latestMilestone[index] !== 'Non Unique' ? */}
                                                            <Button icon={<UploadOutlined />} type="primary" ghost>Complete Application</Button>
                                                            {/* : ''} */}
                                                        </div>
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        lead.companies[index]?.toLowerCase().includes('rapido_3pl') && lead.bankDetailsVerified && lead.jobseekerDocsConfirmation && lead.mitraAppUserLoginAt && !lead?.siMetaData?.[index]?.rapidoMilestone?.captainId ?
                                            <>
                                                {
                                                    AdditionalFormsURLEnum[lead.companies[index]?.toLowerCase()] ?
                                                        <div onClick={onClickCompleteApplication}>
                                                            <Button icon={<UploadOutlined />} type="primary" ghost>Verify OTP</Button>
                                                        </div>
                                                        : null
                                                }
                                            </>
                                            : null
                                    }
                                    {
                                        referagainBool(index)
                                            ?
                                            <div style={{ marginLeft: 3 }} onClick={onClickReferAgain}>
                                                <Button icon={<UserAddOutlined />} style={{ marginRight: 8 }} type="primary" ghost>Refer Again</Button>
                                            </div>
                                            : null
                                    }

                                </div>
                            </div>

                            <div className="hot-lead-main-container-right-side show-only-desktop">
                                {lead?.callLogsTagsArray?.[index]?.length ?
                                    <div className='main-container-divider-right' style={{ height: '85px' }}>
                                        {callNotesArray()}
                                    </div>
                                    : null}
                            </div>

                            {/* <div className='d-flex' style={{ alignItems: 'center' }}>
                                <Button style={{ color: '#fce3e6', border: '2px solid #fce3e6', width: '60px', height: '60px' }} type="primary" size='large' ghost shape="circle" icon={<ArrowRightOutlined style={{ fontSize: '25px' }} />} />
                            </div> */}
                        </div>
                    </Space>

                </div>

                {
                    lead.campaignTags && lead.campaignTags.length
                        ?
                        <div className='campaign-tag-container'>
                            {
                                lead.campaignTags[index].map((campaignTag, index) => <Tag key={index} className='single-chip'>{campaignTag}</Tag>)
                            }
                        </div>
                        : null
                }
                {/* for banners */}
                <div className='application-card-banners'>
                    <div className="justify-content-between">
                        {
                            (lead.latestMilestone[index] === 'Incomplete Application')
                                ?
                                (lead.companies[index]?.toLowerCase().indexOf('rapido')) !== -1 ?
                                    <div className="d-flex flex-row">
                                        <Tag color="yellow" style={{ maxHeight: '25px', fontWeight: 'bold', color: '#FAAD14' }}>Action Required</Tag>
                                        <Text>{'\u2022'} Please complete the OTP verification by clicking on &quot;Complete Application&quot;</Text>
                                    </div>
                                    : <div className="d-flex flex-row"><Tag color="yellow" style={{ maxHeight: '25px', fontWeight: 'bold', color: '#FAAD14' }}>Action Required</Tag>
                                        <Text>{'\u2022'} Please complete the remaining steps by clicking on &quot;Complete Application&quot;</Text></div>
                                : null
                        }
                        {
                            !lead.companies[index]?.toLowerCase().includes('dominos') && (!lead.companies[index]?.toLowerCase().includes('3pl')) && !lead.mitraAppUserLoginAt && isBronzeML
                                ? (lead.latestMilestone[index] === 'Incomplete Application' ?
                                    <Text className='action-banner'>{'\u2022'} Vahan App Not Downloaded</Text>
                                    : <div className="d-flex flex-row">
                                        <Tag color="yellow" style={{ maxHeight: '25px', fontWeight: 'bold', color: '#FAAD14' }}>Action Required</Tag>
                                        <Text>{'\u2022'} Vahan App Not Downloaded</Text> </div>)
                                : null
                        }

                        {           
                            lead.companies[index]?.toLowerCase().includes('shadowfax')  && lead.siMetaData?.[index]?.incompleteApplication && lead.latestMilestone[index] !== 'Non Unique' && (lead.siMetaData?.[index]?.shadowFaxMilestone.value && lead.siMetaData?.[index]?.shadowFaxMilestone.value.find(e=>!e.includes('success')))
                                ? (lead.latestMilestone[index] === 'Incomplete Application' ?
                                    <Text className='action-banner'>{'\u2022'} Some of Uploaded documents got rejected, Please check</Text>
                                    : <div className="d-flex flex-row">
                                        <Tag color="yellow" style={{ maxHeight: '25px', fontWeight: 'bold', color: '#FAAD14' }}>Action Required</Tag>
                                        <Text>{'\u2022'} Some of Uploaded documents got rejected, Please check</Text> </div>)
                                : null
                        }
                        {
                            lead.companies[index]?.toLowerCase().includes('dominos') && <div className="d-flex flex-row">
                                <Tag color="yellow" style={{ maxHeight: '25px', fontWeight: 'bold', color: '#FAAD14' }}>Action Required</Tag>
                                <Text className='action-banner'>{'\u2022'} Please fill out the provided form if you {`haven't`} already.  <Tag color="blue" style={{ marginLeft: '5px' }}> <a style={{ color: 'blue' }} target='_blank' rel="noreferrer" href='https://360.idfy.com/signup/37646013-0184-42e9-8998-45ef9cf606c5/18e0cf22-81ea-40b3-88a1-b656086d5928'> Click here to fill</a></Tag></Text>
                            </div>
                        }

                        {
                            !(JSON.stringify(lead?.siMetaData?.[index]).includes('formTwoCompleted')) && lead.companies[index]?.toLowerCase().indexOf('zepto_3pl') !== -1 && lead.latestMilestone[index] !== 'Incomplete Application' && lead.latestMilestone[index] !== 'Non Unique' && !(JSON.stringify(lead?.siMetaData?.[index]).includes('formThreeCompleted')) && (lead?.scheduledInterviewId) ?
                                <Text className='action-banner'>{'\u2022'} Please complete the remaining steps by clicking on &quot;Complete Application&quot;</Text>
                                : null
                        }
                        {
                            (JSON.stringify(lead?.siMetaData?.[index]).includes('formTwoCompleted') && lead.companies[index]?.toLowerCase().indexOf('zepto_3pl') !== -1 && lead.latestMilestone[index] !== 'Incomplete Application' && lead.latestMilestone[index] !== 'Non Unique'
                                && JSON.stringify(lead?.siMetaData?.[index]).includes('googleFormFillErrorReason') && !(JSON.stringify(lead?.siMetaData?.[index]).includes('googleFormFilled'))) ? <Text className='action-banner'>{'\u2022'} Please Re-Upload the correct shift preference as per location using demands sheet: <u><b><a style={{ color: 'black' }} href='https://docs.google.com/spreadsheets/d/1tFVN1n8DLYrEZakIFdikcChghLdIE96YxbYfME9zOb4/edit#gid=1033278430' rel="noopener noreferrer" target="_blank">LINK</a></b></u>, selected shift is not avaiable with client! </Text> : null
                        }
                        {
                            (JSON.stringify(lead?.siMetaData?.[index]).includes('formTwoCompleted') && lead.companies[index]?.toLowerCase().indexOf('zepto_3pl') !== -1 && lead.latestMilestone[index] !== 'Incomplete Application' && lead.latestMilestone[index] !== 'Non Unique'
                                && JSON.stringify(lead?.siMetaData?.[index]).includes('zeptoRejectionReason')) ? <Tag className='action-banner' icon={<ArrowUpOutlined />} color="volcano">{'\u2022'} Client rejected the lead with Reason: ${lead?.siMetaData?.[index]?.zeptoRejectionReason}, please re-upload the required details by editing the application</Tag> : null
                        }
                        {
                            (lead.companies[index]?.toLowerCase().includes('3pl')) && !lead.mitraAppUserLoginAt && lead.latestMilestone[index] !== 'Non Unique' ?
                                <Text className='action-banner'>{'\u2022'} For Vahan activation, ask candidate to login to {`"Vahan App"`}</Text>
                                :
                                null
                        }
                        {
                            lead.companies[index]?.toLowerCase().includes('rapido_3pl') && lead.bankDetailsVerified && lead.jobseekerDocsConfirmation && lead.mitraAppUserLoginAt && !lead?.siMetaData?.[index]?.rapidoMilestone?.captainId ?
                                <Text className='action-banner'>{'\u2022'} Verify OTP to activate candidate.</Text>
                                :
                                null
                        }
                        {
                            lead.companies[index]?.toLowerCase().indexOf('uber') !== -1 && CONSTANTS.UBER_DL_CHECKER_CITIES.indexOf(lead.companyCity[index]?.toLowerCase()) === -1 ?
                                <Tag className='action-banner'>There is no uniqueness check for the city you have chosen. Please ask lead to proceed with next steps without uniqueness.</Tag>
                                : null
                        }
                        {
                            (!Array.isArray(lead?.siMetaData?.[index]?.rapidoMilestone?.value) && (lead?.siMetaData?.[index]?.rapidoMilestone?.value?.toString().includes('Please upload') || lead?.siMetaData?.[index]?.rapidoMilestone?.value?.toString().includes('Pending')))
                                ?
                                <Text className='action-banner'>{'\u2022'} Please complete the remaining steps by clicking on &quot;Upload Documents&quot;</Text>
                                : null
                        }
                        {
                            (Array.isArray(lead.siMetaData[index]?.rapidoMilestone?.value) && (lead.siMetaData[index]?.rapidoMilestone?.value.filter((ele) => (ele.includes('Review'))).length === lead.siMetaData[index]?.rapidoMilestone?.value.length)) ?
                                <Tag className='action-banner' icon={<ArrowUpOutlined />} color="volcano">Documents are being Reviewed please check after some time</Tag>
                                : null
                        }
                        {
                            (lead.companies[index]?.toLowerCase().indexOf('uber') !== -1 && lead.siMetaData[index] && lead.siMetaData[index].driverStatus)
                                ?
                                (lead.siMetaData[index].driverStatus === 'NOT_CONVERTED') ?
                                    <Tag className='action-banner' icon={<ArrowUpOutlined />}>Please ask the lead to create ID & Password using <a style={{ color: "blue", textDecoration: 'underline' }} href={lead.siMetaData[index]?.uberMilestone?.url} target="_blank" rel="noreferrer">Invite Link</a></Tag>
                                    :
                                    (lead.siMetaData[index].driverStatus === 'BLOCKED') ?
                                        <Tag className='action-banner' icon={<ArrowUpOutlined />} color="volcano">Lead Blocked By Uber </Tag>
                                        :
                                        (lead.siMetaData[index]?.reviewNotStartedUberDocArray?.length > 0) ?
                                            <Tag className='action-banner' icon={<ArrowUpOutlined />} color="volcano">Documents are being Reviewed please check after some time</Tag>
                                            :
                                            (lead.siMetaData[index]?.isApplicationCompleted !== true || lead.siMetaData[index]?.failedUberDocArray?.length > 0 || lead.siMetaData[index]?.notUploadedUberDocArray?.length > 0) ?
                                                <Text className='action-banner'>{'\u2022'} Please re-upload the Pending / Rejected Documents by clicking on &quot;Upload Documents&quot;</Text>
                                                :
                                                <Tag className='action-banner' icon={<InfoCircleOutlined />} color="green">Congrats! All the documents have been approved!</Tag>
                                : null
                        }
                        {
                            (Array.isArray(lead?.siMetaData?.[index]?.dunzoMilestone?.value) && lead?.siMetaData?.[index]?.dunzoMilestone?.value?.toString().includes('Under Review'))
                                ?
                                <Tag className='action-banner' icon={<ArrowUpOutlined />} color="gray">Documents are being reviewed please check after some time.</Tag>
                                : null
                        }

                        {
                            (Array.isArray(lead?.siMetaData?.[index]?.dunzoMilestone?.value) && !(lead?.siMetaData?.[index]?.dunzoMilestone?.value?.toString().includes('Under Review')))
                                ?
                                <Tag className='action-banner' icon={<InfoCircleOutlined />} color="green">Congrats! All the documents have been Approved!</Tag>
                                : null
                        }

                        {
                            (lead.siMetaData[index]?.rapidoMilestone?.value?.toString().includes('Please upload') || lead.siMetaData[index]?.rapidoMilestone?.value?.toString().includes('Rejected'))
                                ?
                                <Text className='action-banner'>{'\u2022'} Please re-upload the Pending / Rejected Documents by clicking on &quot;Upload Documents&quot;</Text>
                                : null
                        }
                        {
                            (Array.isArray(lead.siMetaData[index]?.rapidoMilestone?.value) && !(lead.siMetaData[index]?.rapidoMilestone?.value?.toString().includes('Please upload')) && !lead.siMetaData[index]?.rapidoMilestone?.value?.toString().includes('Pending') && lead.siMetaData[index]?.isApplicationCompleted)
                                ?
                                <Tag className='action-banner' icon={<InfoCircleOutlined />} color="green">Congrats! All the documents have been Approved!</Tag>
                                : null
                        }
                        {
                            lead.productSource[index] === 'CrossReferRapidoToZomato' || lead.productSource[index] === 'CrossReferShadowfaxToZomato'
                                ?
                                <Tag> Cross Referred by Vahan</Tag>
                                : null
                        }
                        {
                            lead.productSource[index] === 'CrossReferRapidoToZomato' || lead.productSource[index] === 'CrossReferShadowfaxToZomato' || lead.productSource[index] === 'CrossReferLSNToZomato' || lead.productSource[index] === 'CrossReferDunzoToZomato'
                                ?
                                <Text className='action-banner'>{'\u2022'} Refer with alternate contact number <strong> {lead.childPhoneNumber}</strong></Text>

                                // <Tag className='referredAgain' style={{display:'flex',alignItems:'center'}} icon={<ExclamationCircleOutlined />}>Refer with alternate contact number 
                                // <strong> {lead.childPhoneNumber}</strong>
                                // </Tag>
                                : null
                        }
                        {
                            lead?.siMetaData[index] && lead.siMetaData[index].rapidoMilestone && !Array.isArray(lead.siMetaData[index].rapidoMilestone.value) && lead.siMetaData[index].rapidoMilestone.value?.toLowerCase().indexOf('attributed') !== -1
                                ?
                                <div className='rapidoNotAttributed'> <InfoCircleOutlined style={{ marginRight: '10px', color: 'rgb(211, 70, 18)' }} />
                                    An error occurred {lead.siMetaData[index]?.rapidoMilestone?.value?.toString().includes('OTP') ? 'as OTP could not be verified' : ''}!! Vahan will share the lead details with the client for attribution. Please ask the candidate to download the client app using the referral code in the meantime.
                                </div>
                                : null
                        }
                        {
                            lead.siMetaData[index] && lead.siMetaData[index].rapidoMilestone && !Array.isArray(lead.siMetaData[index].rapidoMilestone.value) && lead.siMetaData[index].rapidoMilestone.value?.toLowerCase().includes('uploading')
                                ?
                                <Tag className='rapidoNotAttributed' color="orange"> <InfoCircleOutlined style={{ marginRight: '10px', color: 'rgb(211, 70, 18)' }} />
                                    Documents Uploading Please Check after some time
                                </Tag>
                                : null
                        }
                        {
                            (checkStatus({ scheduledInterviewId: lead.scheduledInterviewId[index], productSource: lead.productSource.length > 0 ? lead.productSource[index] : null, parentPhoneNumber: lead.parentPhoneNumber ? lead.parentPhoneNumber[index] : null, childPhoneNumber: lead.childPhoneNumber?.length > 0 ? lead.childPhoneNumber[index] : null, companyName: lead.companies[index], status: lead.latestMilestone[index] }))
                                ?
                                // <div style={{display: 'block'}}>
                                <Text className='action-banner'>{'\u2022'} Refer with alternate contact number <strong> {lead.childPhoneNumber}</strong></Text>
                                /* // <div className='referredAgain'>Refer with alternate contact number  */
                                // </div>
                                : null
                        }
                        {
                            (lead.parentPhoneNumber[index] && lead.scheduledInterviewId[index])
                                ?
                                <Tag className='referredAgain' icon={<ExclamationCircleOutlined />}> Referred again with alternate contact no.</Tag>
                                : null
                        }
                    </div>
                    <div className="hot-lead-main-container-right-side show-only-mobile">
                        {lead?.callLogsTagsArray?.[index]?.length && callLogsOpened ?
                            <div className='main-container-divider-right' style={callLogsOpened ? { height: '85px' } : { height: '50px' }}>
                                {callNotesArray()}
                            </div>
                            : null}
                    </div>
                    <div className='show-only-mobile-footer'>
                        {lead.mitraName ? <div className='added-mitra-name' style={{ width: '60%' }}><UserOutlined style={{ marginRight: '3px' }} />Added by&nbsp;<span className='display-mitra-name'>{[...new Set(lead.mitraName)].toString()}</span></div> : null}
                        {lead?.callLogsTagsArray?.[index]?.length ?
                            <div className='added-mitra-name' style={{ width: '40%', justifyContent: 'right' }} onClick={(e) => onClickUpDown(e)}>
                                {callLogsOpened ?
                                    <UpOutlined className='call-logs-opened-arrow-icon' />
                                    : <DownOutlined className='call-logs-opened-arrow-icon' />
                                }
                            </div>
                            : null}
                    </div>
                    <Divider></Divider>
                    <div className='d-flex justify-content-between show-only-desktop'>
                        {lead.mitraName ? <div className='added-mitra-name'><UserOutlined style={{ marginRight: '3px' }} />Added by&nbsp;<span className='display-mitra-name'>{lead.mitraName[index]}</span></div> : null}
                        <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'center' }}>
                            <div className="contact-buttons">
                                <Button onClick={(e) => copyPhoneNumberFunc(e, lead.parentPhoneNumber[0] ? `${lead.parentPhoneNumber[0]}(edited)` : lead.phoneNumber)}
                                    icon={<CopyOutlined />} type="primary" ghost>
                                    {lead.parentPhoneNumber[0] ? `${lead.parentPhoneNumber[0]}(edited)` : lead.phoneNumber}
                                </Button>
                                <Button onClick={(e) => openWhatsAppPop(e, index)} icon={<WhatsAppOutlined />} type="primary" ghost>Message</Button>
                                {
                                    (
                                        (
                                            lead.latestMilestone[index] === 'Incomplete Application' &&
                                            lead.companies[index].toLowerCase() !== 'swiggy' &&
                                            lead.companies[index].toLowerCase() !== 'zomato' // Excluding zomato out of this check as we donot allow non-unique to see additional info form
                                        )
                                        ||
                                        (
                                            lead.latestMilestone[index] === 'Lead Referred' &&
                                            lead.companies[index].toLowerCase() === 'zepto'
                                        )
                                        ||
                                        (
                                            // for cohort based handling, excluded swiggy from above condition as we will show the edit application button
                                            // to only unique swiggy leads that too to the ones who are in our list
                                            lead.companies[index].toLowerCase() === 'swiggy' &&
                                            lead?.latestMilestone?.[index]?.toLowerCase() === 'unique'
                                        )
                                        ||
                                        (
                                            lead.companies[index].toLowerCase() === 'blinkit' &&
                                            lead.latestMilestone?.[index]?.toLowerCase() === 'unique'
                                        )
                                        ||
                                        (lead.companies[index].toLowerCase() === 'zomato' && lead.latestMilestone?.[index]?.toLowerCase() === 'unique')
                                        ||
                                        (
                                            lead.companies[index].toLowerCase() === 'zomato' && realtimeUniquenessStatusData &&
                                            realtimeUniquenessStatusData[lead.scheduledInterviewId[index]]?.isNonUnique === false
                                        )
                                    ) ?
                                        <>
                                            {
                                                AdditionalFormsURLEnum[lead.companies[index]?.toLowerCase()] ?
                                                    <div onClick={onClickCompleteApplication} className='d-flex' style={{ justifyContent: 'flex-end' }}>
                                                        <Button icon={<UploadOutlined />} type="primary">Complete Application</Button>
                                                        {
                                                            ['zomato','swiggy', 'blinkit','zepto'].includes(lead.companies[index].toLowerCase()) ? <img src="/image/new-icon.gif" style={{ height: '25px', width: 'auto' }} /> : null
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>

                    <div className='d-flex justify-content-between show-only-mobile'>
                        <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'center', marginTop: '-10px', width: '100%' }}>
                            <div className="contact-buttons" style={{ width: '30%' }}>
                                <a
                                    href={`tel:+91-${lead.parentPhoneNumber[0] ? lead.parentPhoneNumber[0] : lead.phoneNumber}`}
                                    onClick={(e) => {
                                        GAEventsTracker("CallCandidate"); e.stopPropagation();
                                        trackEvent('call_now_option_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
                                    }}>
                                    <Button className='button-phone-number'
                                        icon={<PhoneOutlined />} type="primary" ghost>
                                    </Button>
                                </a>
                                <Button onClick={(e) => openWhatsAppPop(e, index)} className='button-phone-number' icon={<WhatsAppOutlined />} type="primary" ghost></Button>

                            </div>
                            <div style={{ width: '70%' }}>
                                {
                                    (
                                        (
                                            lead.latestMilestone[index] === 'Incomplete Application' &&
                                            lead.companies[index].toLowerCase() !== 'swiggy' &&
                                            lead.companies[index] !== 'Zomato' // Excluding zomato out of this check as we donot allow non-unique to see additional info form
                                        )
                                        ||
                                        (
                                            lead.latestMilestone[index] === 'Lead Referred' &&
                                            lead.companies[index].toLowerCase() === 'zepto'
                                        )
                                        ||
                                        (
                                            // for cohort based handling, excluded swiggy from above condition as we will show the edit application button
                                            // to only unique swiggy leads that too to the ones who are in our list
                                            lead.companies[index].toLowerCase() === 'swiggy' &&
                                            lead.latestMilestone[index] === 'Unique'
                                        )
                                        ||
                                        (
                                            lead.latestMilestone[index] === 'Unique' &&
                                            lead.companies[index].toLowerCase() === 'blinkit'
                                        )
                                        ||
                                        (lead.companies[index].toLowerCase() === 'zomato' && lead.latestMilestone[index] === 'Unique')
                                        ||
                                        (
                                            lead.companies[index].toLowerCase() === 'zomato' && realtimeUniquenessStatusData &&
                                            realtimeUniquenessStatusData[lead.scheduledInterviewId[index]]?.isNonUnique === false
                                        )
                                    ) ?
                                        <>
                                            {
                                                AdditionalFormsURLEnum[lead.companies[index]?.toLowerCase()] ?
                                                    <div onClick={onClickCompleteApplication} className='d-flex' style={{ justifyContent: 'flex-end' }}>
                                                        <Button icon={<UploadOutlined />} type="primary">Complete Application</Button>
                                                        {
                                                            (['zomato', 'swiggy', 'zepto', 'blinkit'].includes(lead.companies[index].toLowerCase())) ? <img src="/image/new-icon.gif" style={{ height: '25px', width: 'auto' }} /> : null
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SingleApplicationContainer;
