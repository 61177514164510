import React, { useState, useEffect, useRef } from 'react'
import '../../style/BulkReferralStyle.scss'
import SideSelector from "../../../common/siderSelector";
import { DeleteOutlined, ArrowRightOutlined, CheckCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { getpopularCities, getClientsForBulkReferral } from './../../../../service/bulkActionService'
import * as homeService from './../../../../service/homeService';
import { Divider, Button } from 'antd';

const BulkReferralStepOne = ({
    selectedCityFromParent,
    selectedClientDataFromParent,
    clearSelectedClientData,
    selectedJobCategoryFromParent,
    selectedClientFromParent,
    selectedJobIdFromParent,
    selectedJobDemandIdFromParent,
    selectedJobLocationFromParent,
    returnStepOneCity,
    returnStepOneJobCategory,
    returnStepOneClient,
    returnStepOneClientData,
    returnStepOneJobId,
    returnStepOneJobDemandId,
    returnStepOneJobLocation,
    returnStepOneAvailableJobs,
    goToNextStep,
    isAiFilteringEnabled, // this is the variable used to tweak FE views
    setIsAiFilteringEnabled,
    enableAIFiltering, // this is the query param
    setWithoutReferral,
    clearedStep }) => {
    const siderSelectRef = useRef();
    const [clients, setClients] = useState([{}]);
    const [cityList, setCityList] = useState([]);
    const [selectedCity, setSelectedCity] = useState(selectedCityFromParent)
    const [cityError, setCityError] = useState(false)
    const [selectedClients, setSelectedClients] = useState([]);// Store selected clients for each dropdown
    const [selectedJobLocations, setSelectedJobLocations] = useState([]); // Store selected job locations for each dropdown
    const [selectedJobIds, setSelectedJobIds] = useState([]);
    const [selectedJobDemandIds, setSelectedJobDemandIds] = useState([selectedJobDemandIdFromParent || null]);

    const [allClientListAcrossCategories, setAllClientListAcrossCategories] = useState({});
    const [clientList, setClientList] = useState([]);
    const [clientError, setClientError] = useState(false)
    const [selectedClient, setSelectedClient] = useState(selectedClientFromParent)

    const [jobCategoryList, setJobCategoryList] = useState([])
    const [jobCategoryError, setJobCategoryError] = useState(false)
    const [selectedJobCategory, setSelectedJobCategory] = useState(selectedJobCategoryFromParent)


    const [jobLocationList, setJobLocationList] = useState([])
    const [jobLocationError, setJobLocationError] = useState(false)
    const [selectedJobLocation, setSelectedJobLocation] = useState(selectedJobLocationFromParent)

    const [localClientData, setLocalClientData] = useState([])
    const [selectedClientExcludedFromAIFiltering, setSelectedClientExcludedFromAIFiltering] = useState(false);

    // Add this state to store client name as key and location as value
    const [clientLocationMap, setClientLocationMap] = useState({});

    const [isNextDisabled, setIsNextDisabled] = useState(true);

    const [allClientData, setAllClientData] = useState([
        {
            companyName: "",
            selectedJobLocation: "",
            jobLocationList: [],
            jobIDList: [],
            jobDemandIDList: []
        }
    ])

    // const storedClientData = localStorage.getItem("storedClientData");
    //     console.log("storedClientDataVal: ",storedClientData)
    //     if (storedClientData) {
    //         setLocalClientData(JSON.parse(storedClientData)); // Populate the state with stored data
    //     }

    useEffect(() => {
        const storedClientData = localStorage.getItem("storedClientData");
        console.log("storedClientDataVal: ", storedClientData);
        if (selectedCityFromParent && storedClientData) {
            setLocalClientData(JSON.parse(storedClientData)); // Populate the state with stored data
        }
    }, []); // Empty dependency array ensures this runs only once when the component mounts.
    

    // useEffect(() => {
    //     // Fetch stored client data from local storage when the component mounts
    //     const storedClientData = localStorage.getItem("storedClientData");
    //     console.log("storedClientDataVal: ",storedClientData)
    //     if (storedClientData) {
    //         setLocalClientData(JSON.parse(storedClientData)); // Populate the state with stored data
    //     }
    // });

    useEffect(() => {
    
        // Fetch popular cities on mount
        getpopularCities().then((response) => {
            setCityList(response.data.premiumCities);
        });
    
        // Prefill city and client data only when selectedCityFromParent is available
        if (selectedCityFromParent) {
            fetchClientsAndCategoriesForSelectedCity(selectedCityFromParent, true);
        }
    
        // Prefill client data only if selectedClientDataFromParent has data
        if (localClientData && localClientData.length > 0) {
            preFillClientAndLocationFromParent();
        }
    }, [selectedCityFromParent, localClientData]);
    
    // Remove or conditionally trigger this based on the user's interaction.
useEffect(() => {
    if (selectedCityFromParent) {
        clearSelectedClientData(); // Prevent this from being called unnecessarily
    }
}, [selectedCityFromParent]);


    useEffect(() => {
        const storedClientData = localStorage.getItem("storedClientData");
        if (selectedCityFromParent && storedClientData) {
            setLocalClientData(JSON.parse(storedClientData)); 
        }
    }, []); 

    useEffect(() => {
            getpopularCities().then((response) => {
            setCityList(response.data.premiumCities);
        });
    
        // Prefill city and client data only when selectedCityFromParent is available
        if (selectedCityFromParent) {
            fetchClientsAndCategoriesForSelectedCity(selectedCityFromParent, true);
        }
    
        // Prefill client data only if selectedClientDataFromParent has data
        if (localClientData && localClientData.length > 0) {
            preFillClientAndLocationFromParent();
        }
    }, [selectedCityFromParent, localClientData]);
    
    useEffect(() => {
        if (selectedCityFromParent) {
            clearSelectedClientData(); 
        }
    }, [selectedCityFromParent]);


    useEffect(() => {
        const checkIfNextDisabled = () => {
            // Check if city is selected
            if (!selectedCity) {
                setCityError(true);
                return true;
            }
    
            // Check if the first client has name and location
            if (!allClientData[0]?.companyName || !allClientData[0]?.selectedJobLocation) {
                setClientError(true);
                setJobLocationError(true);
                return true;
            }
    
            // Ensure all clients have both name and location
            if (!isCurrentClientDataComplete()) {
                return true;
            }
    
            // All checks passed, enable the button
            return false;
        };
    
        setIsNextDisabled(checkIfNextDisabled()); 
    }, [selectedCity, allClientData]); 
    
    
    useEffect(() => {
        getpopularCities().then((response) => {
            setCityList(response.data.premiumCities)
        })

        if (selectedCityFromParent) {
            fetchClientsAndCategoriesForSelectedCity(selectedCityFromParent, true)
        }

    }, [])

    const setAndReturnVal = (valFor = "", setVal) => {
        switch (valFor) {
            case "city":
                setSelectedCity(setVal);
                returnStepOneCity(setVal);
                break;
            case "category":
                setSelectedJobCategory(setVal);
                returnStepOneJobCategory(setVal);
                break;
            case "client":
                setSelectedClient(setVal);
                returnStepOneClient(setVal);
                break;
            case "location":
                setSelectedJobLocation(setVal);
                returnStepOneJobLocation(setVal);
                break;
            // case "clientData":
            //     console.log("allClientDataVal: ",allClientData)
            //     returnStepOneClientData(allClientData)
            //     break;
        }
    }

    const handleCityChange = (city) => {
        setCityError(false);
        setAndReturnVal("city", city);

        localStorage.setItem("storedClientData", JSON.stringify([]));
        setLocalClientData([])
        // preFillClientAndLocationFromParent()

        const storedClientData = localStorage.getItem("storedClientData");
        console.log("ftghjkl: ",storedClientData)

        
        setAndReturnVal("category", null);
        setJobCategoryList([]);
        setAndReturnVal("client", null);
        setClientList([]);
        setSelectedClients([]);
        setSelectedJobLocations([]);
        setSelectedJobIds([]);
        setSelectedJobDemandIds([]);
        setAndReturnVal("location", null);
        setJobLocationList([]);
    
        // Initialize allClientData with an empty client data object
        setAllClientData([
          {
            companyName: "",
            selectedJobLocation: "",
            jobLocationList: [],
            jobIDList: [],
            jobDemandIDList: []
          }
        ]);
    
        fetchClientsAndCategoriesForSelectedCity(city, false);
        returnStepOneCity(city);
      };
    
      // useEffect to clear selected client data when city changes
      useEffect(() => {
        clearSelectedClientData();
      }, [selectedCityFromParent]);
    
    

    const fetchClientsAndCategoriesForSelectedCity = (city) => {
        if (city) {
            getClientsForBulkReferral(city,isAiFilteringEnabled).then((result) => {
                if (result && result.data.viewData.companies) {
                    const companyAndCategories = result.data.viewData.companies;

                    // Combine Delivery and Driver lists
                    const mergedClientList = [
                        ...(companyAndCategories.Delivery || []),
                        ...(companyAndCategories.Driver || [])
                    ];

                    // Set the combined client list
                    setClientList(mergedClientList);

                    if (localClientData?.length) {
                        // preFillClientAndLocationFromParent(mergedClientList)
                        preFillClientAndLocationFromParent()
                    }
                }
            });
        }
    };

    // const preFillClientAndLocationFromParent = (allClientsListFromAPI) => {
    const preFillClientAndLocationFromParent = () => {
        if (localClientData) {
            // fetchClientWiseJobLocations(selectedClientDataFromParent)
            setAllClientData(localClientData)
        }

    }

    const handleClientChange = (client, index) => {
        // Update the company name for the current index
        const updatedClientLocationList = allClientData;
        updatedClientLocationList[index].companyName = client;
        // Clear the job location for this client
        updatedClientLocationList[index].selectedJobLocation = "";
        setAllClientData(updatedClientLocationList);
    
        // if (enableAIFiltering === 'true') {
        //     if (['rapido', 'zepto', 'uber'].includes(client?.toLowerCase())) {
        //         setSelectedClientExcludedFromAIFiltering(true);
        //         setIsAiFilteringEnabled(false);
        //     } else {
        //         setSelectedClientExcludedFromAIFiltering(false);
        //         setIsAiFilteringEnabled(true);
        //     }
        // }
    
        setClientError(false);
        getJobLocations(selectedCity, client, index);
    };
    
    

    const getFilteredClientList = (arr1, arr2) => {
        // Get a list of all company names from master array
        const companyNamesFromArray1 = allClientData.map(item => item.companyName);
      
        // Filter out items from clientList that are not present in master array's companyName
        return clientList.filter(company => !companyNamesFromArray1.includes(company));
      };

    const handleJobCategoryChange = (category, isFromParent = false, clientData = {}) => {
        setJobCategoryError(false);
        setAndReturnVal("category", category)
        if (!isFromParent) {
            setAndReturnVal("client", null)
            setAndReturnVal("location", null)
            setJobLocationList([])
        }
        if (isFromParent) {
            setClientList(clientData[category])
        }
        else {
            setClientList(allClientListAcrossCategories[category])
        }
    }

    const getJobLocations = (city, company, index) => {
        if (city === 'Work from Home') {
            setJobLocationList(['Work from Home']);
        } else {
            if (city && company) {
                homeService.getJobLocations(city, company).then((result) => {
                    if (result && result.data.viewData.jobLocations) {
                        let jobLocations = result.data.viewData.jobLocations;
                        jobLocations = jobLocations.map((c) => c.name);
                        if (allClientData?.length) {
                            let tempLocationArr = allClientData
                            tempLocationArr[index].jobLocationList = jobLocations
                            setAllClientData(JSON.parse(JSON.stringify(tempLocationArr)))
                        }
                    }
                });
            }
        }
    };

    const handleJobLocationChange = (jobLocation, index) => {
        const tempLocationArr = allClientData
        tempLocationArr[index].selectedJobLocation = jobLocation
        setAllClientData(JSON.parse(JSON.stringify(tempLocationArr)))
        getJobs(selectedJobCategory, selectedCity, allClientData[index].companyName, jobLocation, index);
    };


    const getJobs = (category, city, companyName, jobLocation, index) => {
        const tempIDArr = allClientData
        if (city && companyName && jobLocation) {
            homeService.getJobs(category, city, companyName, jobLocation).then((result) => {
                if (result && result.data.viewData.jobs) {
                    tempIDArr[index].jobIDList = result.data.viewData.jobs?.[0]?.jobId;
                    tempIDArr[index].jobDemandIDList = result.data.viewData.jobs?.[0]?.jobDemandId;
                    setAllClientData(JSON.parse(JSON.stringify(tempIDArr)))
                    setAndReturnVal("clientData", allClientData);
                }
            });
        }
    };

    const continueWOReferral = () => {
        setWithoutReferral(true);
        goToNextStep()
    }
    
    

    const hasEmptyField = () => {
        if (!selectedCity) {
            setCityError(true);
            return true;
        }

        if (!selectedClients) {
            setClientError(true)
            return true
        }

        if (!selectedJobLocations) {
            setJobLocationError(true)
            return true
        }
    }

    const onNextClick = () => {

        // Map selected clients to their corresponding job locations
        const clientLocationMap = selectedClients.map((client, index) => ({
            clientName: client,
            jobLocation: selectedJobLocations[index]
        }));
        returnStepOneClientData(allClientData)
        returnStepOneClient(selectedClients)
        returnStepOneJobLocation(selectedJobLocations)
        returnStepOneJobId(selectedJobIds)
        returnStepOneJobDemandId(selectedJobDemandIds)
        if (!hasEmptyField()) {
            goToNextStep()
        }

        // Save allClientData to local storage on "Next" button click
        localStorage.setItem("storedClientData", JSON.stringify(allClientData));
    }

    const handleAddClient = () => {
        let newTempArrItem = {
            companyName: "",
            selectedJobLocation: "",
            jobLocationList: [],
            jobId: "",
            jobDemandId: ""
        }
        let tempArrItem = allClientData;
        tempArrItem.push(newTempArrItem);
    
        setAllClientData(tempArrItem);
        setClients([...clients, {}]);
        setSelectedClients([...selectedClients, null]);
        setSelectedJobLocations([...selectedJobLocations, null]); // Add a new entry for job locations
        setSelectedJobIds([...selectedJobIds, null]);
        setSelectedJobDemandIds([...selectedJobDemandIds, null])
    };

    const handleRemoveClient = (index) => {
        // Remove the client data at the specified index from all related state arrays
        setClients(prevClients => prevClients.filter((_, i) => i !== index)); // Remove client at index
        setSelectedClients(prevSelectedClients => prevSelectedClients.filter((_, i) => i !== index)); // Remove client name
        setSelectedJobLocations(prevSelectedJobLocations => prevSelectedJobLocations.filter((_, i) => i !== index)); // Remove job location
        setSelectedJobIds(prevSelectedJobIds => prevSelectedJobIds.filter((_, i) => i !== index)); // Remove job ID
        setSelectedJobDemandIds(prevSelectedJobDemandIds => prevSelectedJobDemandIds.filter((_, i) => i !== index)); // Remove job demand ID
    
        // Update allClientData to reflect removal of the client at the given index
        setAllClientData(prevAllClientData => prevAllClientData.filter((_, i) => i !== index));
    };
    
    const isCurrentClientDataComplete = () => {
        // Check if every client has both name and location filled
        return allClientData.every(clientData => clientData.companyName && clientData.selectedJobLocation);
    };
    

    return (
        <div className='br-step-parent br-step-one-dropdowns-parent d-flex'>
            <div className='br-step-dropdown-fields justify-content-center'>
            
                <div className='br-step-one-section justify-content-center'>
                
                    <div className="br-step-one-dropdown">
                    <div className="br-step-one-city-name">Select City</div>
                        <SideSelector
                            ref={siderSelectRef}
                            selectorData={cityList}
                            placeholder="Select City"
                            required
                            onChange={(city) => { handleCityChange(city) }}
                            autoFocus
                            selectedValue={selectedCity}
                        />
                    </div>
                </div>

                <div className='br-step-one-section justify-content-center'>

                    <div className='parent-container'>
                        {allClientData.map((singleClientData, singleClientInd) => (
                            <div key={singleClientInd} className="parent-client-div">
                                <div className="remove-client-container">

                                    {!enableAIFiltering ? (
                                        <div className='client_index_text'>
                                            <span style={{ color: 'red' }}>*</span>
                                            Select Client
                                            {singleClientData.companyName && singleClientData.selectedJobLocation && (
                                                <CheckCircleOutlined style={{ color: '#31C824D9', fontSize: '14px', marginRight: '8px', alignSelf: 'center', marginLeft: '8px' }} />
                                            )}
                                        </div>
                                    ) : singleClientInd >= 0 ? (
                                        <div className='client_index_text'>
                                            <span style={{ color: 'red' }}>{singleClientInd === 0 ? '*' : ''}</span>
                                            Client {singleClientInd + 1}
                                            {singleClientData.companyName && singleClientData.selectedJobLocation && (
                                                <CheckCircleOutlined style={{ color: '#31C824D9', fontSize: '14px', marginRight: '8px',  alignSelf: 'center',  marginLeft: '8px' }} />
                                            )}
                                        </div>
                                    ) : null}
                                    {singleClientInd !== 0 && (
                                        <div className='remove-client-btn' onClick={() => handleRemoveClient(singleClientInd)}>
                                            <img src='/image/deleteOutlined.webp' alt='remove-image' className='remove-image-ai-filtering' style={{ objectFit: 'contain' }} />
                                            <div className='remove-client-text'>Remove</div>
                                        </div>
                                    )}
                                </div>

                                <div className='d-flex flex-column br-step-one-dropdown'>
                                    <div>
                                        <SideSelector
                                            className="side-selector-container"
                                            selectorData={getFilteredClientList(singleClientInd)}  // Filtered client list
                                            placeholder="Select Client"
                                            required={singleClientInd === 0}
                                            onChange={(e) => handleClientChange(e, singleClientInd)} // Pass index here
                                            disabled={clientList && clientList.length === 0 && !selectedClients[singleClientInd]}
                                            selectedValue={singleClientData.companyName || undefined}
                                            modifiedClassName={enableAIFiltering === 'true' && selectedClientExcludedFromAIFiltering ? 'error-border' : ''}
                                        />
                                    </div>
                                    {
                                        enableAIFiltering === 'true' && selectedClientExcludedFromAIFiltering ? (
                                            <span className='regular14-22 red-negative txt-left' style={{ marginTop: '-12px', marginBottom: '16px' }}>
                                                AI Filtering is currently not available for selected client
                                            </span>
                                        ) : null
                                    }
                                </div>
                                <div className="br-step-one-dropdown">
                                    <SideSelector
                                        selectorData={singleClientData.jobLocationList}
                                        placeholder="Select Job Location"
                                        required
                                        onChange={(e) => handleJobLocationChange(e, singleClientInd)} // Pass index here
                                        disabled={!singleClientData.companyName} // Disable if no client is selected
                                        selectedValue={singleClientData.selectedJobLocation || undefined} // Get the selected value from the array
                                    />
                                </div>

                            </div>
                        ))}

                        {enableAIFiltering && allClientData.length < clientList.length && isCurrentClientDataComplete() && (
                            <div className='add-client-btn' onClick={handleAddClient}>
                                <PlusOutlined className='br-step-btn-img-orange' />
                                <div className='add-client-text'>Add Client</div>
                            </div>
                        )}

                    </div>
                </div>
            </div>

            <div className='br-step-action-fields d-flex mobile-bottom-fixed'>
                <Divider style={{ margin: '4px 0px 16px' }}></Divider>
                <div className='br-step-btns d-flex'>

                    {
                        enableAIFiltering ? null : (
                            <Button className='br-btn-container br-clear-all-btn d-flex align-items-center justify-content-center' onClick={() => continueWOReferral()}>
                                Continue Without Referral
                                <ArrowRightOutlined className='br-step-btn-img-orange' />
                            </Button>
                        )
                    }

                    <Button className='br-btn-container br-next-btn d-flex align-items-center justify-content-center text-ellipsis' onClick={() => onNextClick()} disabled={isNextDisabled}>
                        {
                            enableAIFiltering === 'true' && selectedClientExcludedFromAIFiltering ? <span className='text-ellipsis'>Continue Without AI Filtering</span> : 'Next'
                        }
                        <ArrowRightOutlined className='br-step-btn-img-white' />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default BulkReferralStepOne; 