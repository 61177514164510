import React, { useRef, useState, useEffect, useContext } from 'react';
import { PaperClipOutlined, FireOutlined, SmileOutlined, CameraOutlined, SendOutlined, FilterOutlined, InboxOutlined, InfoCircleOutlined, EyeTwoTone,EyeInvisibleOutlined } from '@ant-design/icons';
import {
  Popover,
  Card,
  Typography,
  Empty,
  Row,
  Col,
  Pagination,
  message,
  Space,
  Button,
  notification,
  Modal,
  Input,
  Image
} from 'antd';
import axios from 'axios';
import PageHeaderComponent from '../common/PageHeader';
import { getMessages, getMessagesUserId, postMessagesUserId } from '../../service/inboxComms';
import { AppContext } from '../../App';
import SideSheet from '../common/SideSheet';
import '../../css/notification.scss';
import {useHistory} from 'react-router-dom';
import Picker from "emoji-picker-react";
import { loginForPayoutDashboard, sendPayoutDashboardPasswordResetUrl, updateLastSeenStatusForAFeature } from '../../service/commonService';
import '../../css/SelfServeChat.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment";

const { Title, Text } = Typography;

const SelfServe = ({ unreadInboxMessages }) => {
  const { mitraReducer, mitraDispatch, setAlert, setSpin } = useContext(AppContext);
  const [responselevel, setResponselevel1] = useState(null);
  const [responselevelEachElm, setResponselevelEachElm] = useState(null);
  const [breadcrumb] = useState([{ link: '/selfserve', label: 'Rider Messages' }]);
  const [showSideSheet, setShowSideSheet] = useState(false);
  const [visibleAuth, setVisibleAuth] = useState(false);
  const [accessPasswordUsr, setAccessPasswordUsr] = useState('');
  const history = useHistory();
  const [message, setMessage] = useState('');
  const [userDetails, setUserDetails] = useState();
  const [showPicker, setShowPicker] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [attachment, setAttachment] = useState(null);
  const [count, setCount] = useState(0);
    const inputFile = useRef(null);

  // useEffect(() => {
  //   if(mitraReducer?.mitraInfo?.id) {
  //     fetchListOfUsers(true);
  //     const interval = setInterval(() => {
  //       fetchListOfUsers(false);
  //     }, 10000);
  //   }
    
  // }, [mitraReducer?.mitraInfo]);

  const fetchListOfUsers = (firstTimeUser) => {
    setCount(0);
    if(firstTimeUser) {
      setSpin({
        loading: true,
        delay: null,
        tip: 'Loading...',
      });
    }
    getMessages({
      mitraLeaderId: mitraReducer?.mitraInfo.id, // mitraReducer?.mitraInfo.id
      fetchRecentUsers: true
    })
      .then((response) => {
        let data = [...response.data.data];

        setResponselevel1([...data]);
        data.map((item, index) => {
          if(item.totalUnreadMessages) {
            setCount(count+1);
          }
        });
        setSpin({
          loading: false,
          delay: null,
          tip: 'Loading...',
        });
      })
      .catch((e) => {
        // message.error('No data');
        console.error(e);
        setSpin({
          loading: false,
          delay: null,
          tip: 'Loading...',
        });
      });
  };

  const onFileUpload = (selectedFile) => {
    const formData = new FormData();
    formData.append(
        "file",
        selectedFile,
        selectedFile.name
    );
    let url = process.env.REACT_APP_BACKEND_URL + '/fileUploader';
    axios.post(url, formData).then(function (response) {
        if (response.data.imageUrl) {
          setAttachment(response.data.imageUrl);
        }
    })
    .catch(function (response) {
        console.log(response.data);
    });
};

const onChangeFile = event => {
  setSelectedFile(event.target.files[0]);
  if (event.target.files[0]) {
      onFileUpload(event.target.files[0]);
  }
};

  const sendButton = () => {
    postMessagesUserId(
    {
      userId: userDetails.messageSenderId,
      mitraLeaderId: mitraReducer?.mitraInfo.id, // mitraReducer?.mitraInfo.id
      attachment: attachment,
      type: "OUTGOING",
      message: message  
    })
    .then((response) => {
      getUserMessages();
      setMessage('');
      setAttachment(null);
      setShowPicker(false);
    })
  };

  const getUserMessages = () => {
    getMessagesUserId({
      userId: userDetails?.messageSenderId,
      mitraLeaderId: mitraReducer?.mitraInfo.id, // mitraReducer?.mitraInfo.id
      limit: 100,
      // lastModifiedDate: responselevel[0].max
    }).then((res) => {
      setResponselevelEachElm(res.data.data);
    });
  };

  const updateLastSeenStatusForCurrentConversation = (data) => {
    const riderId = data.messageSenderId;
    const mitraLeaderId = mitraReducer?.mitraInfo.id;

    const payload = {
      userId: mitraLeaderId,
      featureName: 'SelfServe',
      subModule: 'chat',
      metaData:  JSON.stringify({riderId}) 
    };

    updateLastSeenStatusForAFeature(payload)
    .then(res => {
      console.log(res, 'updated last seen');
    }).catch(err=> {
      console.log(err);
    })
  }

  const onClickItem = (item) => {
    setUserDetails(item);
    setSpin({
      loading: true,
      delay: null,
      tip: 'Loading...',
    });
    getMessagesUserId({
      userId: item?.messageSenderId,
      mitraLeaderId: mitraReducer?.mitraInfo.id, // mitraReducer?.mitraInfo.id
      limit: 100,
      // lastModifiedDate: responselevel[0].max
    }).then((res) => {
      setSpin({
        loading: false,
        delay: null,
        tip: 'Loading...',
      });
      setResponselevelEachElm(res.data.data);
      updateLastSeenStatusForCurrentConversation(item);
      
      // removing notification count once user sees the messages
      const newListOfMembers = [...responselevel];

      for (let index = 0; index < newListOfMembers.length; index++) {
        if(newListOfMembers[index].messageSenderId === item.messageSenderId){
          newListOfMembers[index].totalUnreadMessages = 0;
          break;
        }
      }

      setResponselevel1([...newListOfMembers]);
    });
    setShowSideSheet(true);
  };

  const handleChange = event => {
    setMessage(event.target.value);
  };

  const fetchMoreMessages = () => {
    console.log('Calling for more messages');
  }
  
  const subjectContent = () => {
    return (
      <>
        <Card bodyStyle={{ padding: '0' }}>
          <div className="conversation-header">
            <img src="/image/prof.png" style={{ height: '20px', width: '20px' }} />
            <span><b> 
              {userDetails?.firstName + ' ' +
                (userDetails?.lastName && userDetails?.lastName !== 'Unknown'
                  ? ` ${userDetails?.lastName}`
                  : '')}</b>
            </span>
            <span> {userDetails?.phoneNumber}</span>
          </div>
          <div className="conversation-header">
            <span style={{marginLeft: '30px'}}><b>Referred date:</b> {moment(userDetails?.referral_date).format("DD/MM/YYYY")} </span>
            <span><b>Onboarding Status:</b> {userDetails?.latestMilestone === 'mitra_app_download' ? 'Mitra App Downloaded' : userDetails?.latestMilestone}</span>
          </div>
          <div style={{ padding: '0 4px 12px' }}>
            {/* <Space className="chat-section-space-container" direction="vertical" size={16}>
              {responselevelEachElm?.map((item, index) =>
                item?.messageSenderId !== userDetails?.messageSenderId ? (
                  <div
                    className="inbox-detail-content"
                    style={{
                      justifyContent: 'flex-end',
                      width: '100%',
                      display: 'flex',
                      position: 'relative',
                    }}
                  >
                    <div
                      className="chat-message-style"
                      style={{
                        backgroundColor: '#ecc2b6',
                        borderTopRightRadius: '10px',
                        borderTopLeftRadius: '24px',
                        marginRight: '10px',
                      }}
                    >
                      <span
                        className="tag-information message-sender-name"
                        style={{ paddingLeft: '12px' }}
                      >
                        {'You'}
                      </span>

                      <span className="message-text">{item.message}</span>

                      {item.attachment ? (
                        <div className="image-styles">
                          <Image width={100} src={item.attachment} />
                        </div>
                      ) : null}

                      <div className="arrow-right"></div>
                    </div>
                  </div>
                ) : (
                  <div className="inbox-detail-content">
                    <div
                      className="chat-message-style"
                      style={{
                        borderTopRightRadius: '24px',
                        borderTopLeftRadius: '10px',
                        marginLeft: '10px',
                      }}
                    >
                      <span className="tag-information message-sender-name">
                        {userDetails?.firstName}
                      </span>

                      <span className="message-text">{item.message}</span>

                      {item.attachment ? (
                        <div className="image-styles">
                          <Image width={100} src={item.attachment} />
                        </div>
                      ) : null}

                      <div className="arrow-left"></div>
                    </div>
                  </div>
                )
              )}
            </Space> */}

              {responselevelEachElm?.length ? (
                <div
                  id="scrollableDiv"
                  style={{
                    height: '72vh',
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    marginTop: '16px'
                  }}
                >
                  <InfiniteScroll
                    dataLength={responselevelEachElm.length}
                    next={fetchMoreMessages}
                    hasMore={true}
                    inverse={true}
                    scrollableTarget="scrollableDiv"
                  >
                    {responselevelEachElm?.map((item, index) =>
                      (item.message.includes('You will get a reply within 3-4 hours') || item.message.includes('Aapko 3-4 ghante ke andar jawab mil jaega') ? null :
                      (item?.messageSenderId !== userDetails?.messageSenderId ? (
                        <div
                          className="inbox-detail-content"
                          style={{
                            justifyContent: 'flex-end',
                            width: '100%',
                            display: 'flex',
                            position: 'relative',
                            marginBottom: '16px'
                          }}
                        >
                          <div
                            className="chat-message-style"
                            style={{
                              backgroundColor: '#ecc2b6',
                              borderTopRightRadius: '10px',
                              borderTopLeftRadius: '24px',
                              marginRight: '10px',
                            }}
                          >
                            <span
                              className="tag-information message-sender-name"
                              style={{ paddingLeft: '12px' }}
                            >
                              {'You'}
                            </span>

                            <span className="message-text">{item.message}</span>
                            <span className="time-frame">{moment(item.createdAt).format('DD MMM \u2022 hh:mm A')}</span>

                            {item.attachment ? (
                              <div className="image-styles">
                                <Image width={100} src={item.attachment} />
                              </div>
                            ) : null}

                            <div className="arrow-right"></div>
                          </div>
                        </div>
                      ) : (
                        <div className="inbox-detail-content" style={{marginBottom: '16px'}}>
                          <div
                            className="chat-message-style"
                            style={{
                              borderTopRightRadius: '24px',
                              borderTopLeftRadius: '10px',
                              marginLeft: '10px',
                            }}
                          >
                            <span className="tag-information message-sender-name">
                            {userDetails?.firstName}
                            </span>

                            <span className="message-text">{item.message}</span>
                            <span className="time-frame">{moment(item.createdAt).format('DD MMM \u2022 hh:mm A')}</span>
                            {item.attachment ? (
                              <div className="image-styles">
                                <Image width={100} src={item.attachment} />
                              </div>
                            ) : null}

                            <div className="arrow-left"></div>
                          </div>
                        </div>
                      )))
                    )}
                  </InfiniteScroll>
                </div>
              ) : null}

            {showPicker && (
              <Picker
                onEmojiClick={(emojiObject) => setMessage((message) => message + emojiObject.emoji)}
              />
            )}
            <div className="inputContainer">
              <div className="chatTypeContainer">
                {/* <button>
                    <SmileOutlined onClick={() => setShowPicker((val) => !val)}/>
                  </button> */}
                <input
                  className="input"
                  placeholder="Type here....."
                  onChange={handleChange}
                  value={message}
                />

              <div class="image-upload">
                <label for="file-input">
                <PaperClipOutlined className="paperClipButton" />
                </label>
                <input type="file" id="file-input" ref={inputFile} accept="image/*"  size="20" onChange={onChangeFile}/></div>
              </div>
              <div className="imgContainer">
                <button onClick={() => sendButton()}>
                  <SendOutlined className="sendButton" />
                </button>
              </div>
            </div>
          </div>
        </Card>
      </>
    );
  };

  const onResetPasswordClick = () => {
    notification.open({
      message: `A password ${mitraReducer?.mitraInfo?.password ? 'reset' : 'creation'} SMS has been sent to your phone number ${mitraReducer?.mitraInfo?.phoneNumber}`,
      duration: 15,
      icon: <InfoCircleOutlined style={{ color: '#52C41A' }} />,
      description: <Button type="primary" size="small" onClick={() => history.push(`/notification`)}>Go to Inbox</Button>
    });
    let isNewPassword = mitraReducer?.mitraInfo?.password ? false : true
    sendPayoutDashboardPasswordResetUrl(isNewPassword);
    history.push('/home')
  }

  const handleOkClick = () => {
    loginForPayoutDashboard({ password: accessPasswordUsr }).then(res => {
      if (res.data.success) {
        localStorage.setItem("payoutAccessToken", res.headers.payoutaccesstoken)
        setVisibleAuth(false);
      } else {
        message.error("Invalid credentials!")
      }
    }).catch((e) => {
      message.error("Invalid credentials!")
    })
  }

  const getButtons = () => {
    let buttonsArr = [];
    if (mitraReducer?.mitraInfo?.password) {
      buttonsArr.push(
        <Button
          onClick={onResetPasswordClick}
          type="secondary">
          <Text >Reset Password</Text>
        </Button>
      );
      buttonsArr.push(
        <Button
          onClick={handleOkClick}
          type="primary">
          <Text style={{ color: '#fff' }}> OK </Text>
        </Button>
      )
    }
    return buttonsArr;
  }

  return (
    <>
      <div className="Notification mainContentZ">
        <Modal
          title={<Text style={{ display: 'flex', alignItems: 'center' }} strong>Authentication</Text>}
          visible={visibleAuth && !localStorage.getItem("payoutAccessToken")}
          onOk={() => {
          }}
          onCancel={() => {
            history.goBack();
          }}
          footer={getButtons()}
        >
          {
            mitraReducer?.mitraInfo?.password ?
              <Input.Password
                placeholder="Input password to access Inbox"
                iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                onChange={(e) => {
                  setAccessPasswordUsr(e.target.value)
                }}
                defaultValue={accessPasswordUsr}
              /> :
              <Button
                onClick={onResetPasswordClick}
                type="secondary">
                <Text > Create New Password</Text>
              </Button>
          }
        </Modal>
        {/* <> */}
        <div
          className="header"
          style={{ marginBottom: 4, display: 'block', justifyContent: 'space-between' }}
        >
					<div style={{marginLeft: '20px', marginTop: '10px'}}><b>Rider Messages </b>( {count} unread chats )</div>
          {responselevel?.length > 0 ? <div style={{marginLeft: '20px', paddingTop: '10px'}}>These are the messages sent to you by your referred Zomato candidate. Reply back to them, or Call them back and help them get Onboarded</div> : null}
        </div>
        {responselevel?.length > 0 ? 
        <Row>
          <Col className="level-1 new-custom-height">
            {responselevel?.map((item, index) => {
              return (
                <Card
                  bodyStyle={{ padding: '10px', background: '#F5F5F5', display: 'flex', gap: '0.9rem',height:'100%',alignItems:'center'}}
                  onClick={() => onClickItem(item)}
                  key={`indox-level-1_${index}`}
                  hoverable
                  style={{ minWidth: '300px', marginBottom: 12,height:'60px' }}
                >
                  <img src='/image/prof.png' style={{height:'25px',width:'25px'}}/>
                  <Space style={{ width: '100%' }} direction="vertical" size={16}>
                    <Space
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: item.firstName ? 'space-between' : 'flex-end',
                      }}
                      direction="horizontal"
                    >
                      {item?.firstName && (
                        <span className="tag-information-zomato"
                          style={{ display: 'flex', alignItems: 'center', gap: '2px',fontSize:'14px' }}>
                          {item.firstName + ' ' + (item?.lastName?.toLowerCase() !== 'unknown' ? item.lastName:'')}
                        </span>
                      )}
                       <Text type="secondary" style={{fontSize: '12px'}}>{moment(item.max).format('DD MMM \u2022 hh:mm A')}</Text>
                    </Space>
                  </Space>
                  {
                    item.totalUnreadMessages ? (
                      <div className='notification-indicating-container'>
                        {item.totalUnreadMessages}
                      </div>
                    ) : null
                  }
                  
                </Card>
              );
            })}
          </Col>
          {responselevel?.length > 0 && userDetails?.firstName && (
            <Col className="level-2" flex="auto">
              {subjectContent()}
            </Col>
          )}

            {
            responselevel?.length > 0 && !userDetails?.firstName && (
              <Col className="level-2" flex="auto">
                <div style={{height:'80%',display:'flex',alignItems:'center',justifyContent:'center',fontSize:'16px'}}>
                  Select a rider to open the conversation
                </div>
              </Col>
            )
          }

        </Row>
        :
        <div style={{marginTop: '20%', display:'flex', alignItems: 'center', textAlign:'center',justifyContent:'center',fontSize:'16px'}}>
            Currently you don't have any chat. As soon as rider wants any assistant.<br></br>You will see a notification in Navigation bar
        </div>
        }
      </div>
      <span className="show-only-mobile">
        {showSideSheet && (
          <SideSheet
            hideDismissIcon={true}
            sidername={userDetails?.firstName + ((userDetails?.lastName && userDetails?.lastName!=='Unknown') ? ` ${userDetails?.lastName}`:null) + ' (' + userDetails?.phoneNumber+ ')'}
            onDismiss={() => {
              setShowSideSheet(false);
            }}
          >
            <div style={{ padding: 10 }}>{subjectContent()}</div>
          </SideSheet>
        )}
      </span>
    </>
  );
};

export default SelfServe;
