import { Button, Input, Radio, Upload, notification } from 'antd';
import React, { useContext, useState, useEffect } from 'react'
import { postAdditionalInfo, uploadImageToS3 } from '../../../service/additionalInfoService';
import { AppContext } from '../../../App';
import {UploadOutlined} from '@ant-design/icons'
import { useHistory } from "react-router-dom";
import { getZomatoCorrectionFormFields, updateZomataCorrectionDetails } from '../../../service/ZomatoRejectionFlowAPIService';

const LAYOUTS = {
    REJECTION_FLOW: 'REJECTION_FLOW',
    PARTIALLY_APPROVED_FLOW: 'PARTIALLY_APPROVED_FLOW'
}

function DocumentCorrection({lead}) {
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [currentLayout, setCurrentLayout] = useState();
    const [fields, setFields] = useState([]);
    const [errorCount, setErrorCount] = useState(null);
    const [addressProofError, setAddressProofError] = useState(null);
    const [selectedAddressProofType, setSelectedAddressProofType] = useState();
    const history = useHistory()

    const [submissionValues, setSubmissionValues] = useState({
        name: 'Sagar',
        panCard: 'https://uploads-a.vahan.co/Lmmzfk-pan.jpeg',
        userSelfie: 'https://uploads-a.vahan.co/on6rTS-passport-picture-businesswoman-brown-hair-260nw-250775908.webp'
    });

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Updating Information...'  //Loading copy here
        })
    }

    const goToCandidatesPage = () => {
        history.replace({ pathname: "/recruiter-candidates" });
    }

    const checkErrors = (res) => {
        const newErrors = [];
        for (let key in res.data) {
            if (res.data[key] !== "200") {
                newErrors.push(`${key}: ${res.data[key]}`)
            }
        }
    
        if(newErrors.length) {
            // setErrors([...newErrors]);
            alert(newErrors.join("\n"))
            return true;
        } 
        return false;
    }

    const handleBeforeUpload = (file) => {
        // Return false to prevent upload
        return false;
      };

    const handleUpload =(info, type) => { // type is one of the property of documents object
        showLoader(true);
    
        uploadImageToS3(info.file)
            .then(async(res)=> {
    
                if(res?.data?.imageUrl) {
    
                    const submissionData = {
                        [type]: {
                            url: res.data.imageUrl,
                            originalName: info?.file?.name,
                            size: info?.file?.size
                        }
                    }
    
                    const response = await postAdditionalInfo(submissionData, mitraReducer?.mitraInfo, lead);
    
                    if(!checkErrors(response)) {
                        setFieldValue(type, res?.data?.imageUrl)
                    }
    
                    showLoader(false);
                } else {
                    showLoader(false);
                }
            }).catch(e=> {
                showLoader(false);
    
                notification['error']({
                    message: 'Image upload failed, please try again later.',
                  });
    
                console.log(e);
            })
      };

    const setFieldValue = (fieldKey, value) => {
        setSubmissionValues({...submissionValues, [fieldKey]:value})
    }

    const onSubmit = () => {
        if (submissionValues && Object.keys(submissionValues).length >= errorCount) {

            if(currentLayout === LAYOUTS.PARTIALLY_APPROVED_FLOW) {
                if(!(/^[A-Z]{4}0[A-Z0-9]{6}$/).test(submissionValues?.bankIfscCode)) {

                    notification['error'] ({
                      message: 'Please enter complete IFSC code.'
                    });
                    return;
                  }
            }

            // call the api 
            updateZomataCorrectionDetails(submissionValues)
            .then(res=> {
                if(res?.data?.success) {
                    notification['success'] ({
                        message: 'Form submitted successfully!'
                    });
                    goToCandidatesPage();
                } else {
                    notification['error']({
                        message: 'Error submitting details!'
                    })
                }
            }).catch(err=> {
                notification['error']({
                    message: 'Something went wrong, please try again later!'
                })
            })
        } else {
            // all fields are required
            notification['error']({
                message: 'Add all the details to continue!'
            })
        }
    }

    const getFormFields = () => {
        const payload = {
            phoneNumber: lead?.phoneNumber
        };

        getZomatoCorrectionFormFields(payload)
        .then((res)=> {
            if(res?.data?.errorFields) {
                setFields([...res?.data?.errorFields]);
            }

            if(res?.data?.errorCount) {
                setErrorCount(res?.data?.errorCount);
            }

            if(res?.data?.addressProofError) {
                setAddressProofError(res?.data?.addressProofError);
            }
        }).catch(err=>{
            notification['error']({
                message: 'Error getting form fields'
            })
        });
    }

    useEffect(() => {
      if(lead.documentStatus) {
        if(lead.documentStatus?.toLowerCase() === 'partially_approved') setCurrentLayout(LAYOUTS.PARTIALLY_APPROVED_FLOW);
        if(lead.documentStatus?.toLowerCase() === 'rejected') {
            setCurrentLayout(LAYOUTS.REJECTION_FLOW);
            getFormFields();
        }

      }
    }, [lead])
    
    const handleIfscChange = (e) => {    
        let inputValue = e.target.value.toUpperCase();  // Convert to uppercase
        // Construct the input based on the current value length
        if (inputValue.length <= 4) {
            // First four characters, must be alphabets
            inputValue = inputValue.replace(/[^A-Z]/g, '');
        } else if (inputValue.length === 5) {
            // Fifth character, must be '0'
            inputValue = inputValue.slice(0, 4) + inputValue.charAt(4).replace(/[^0]/g, '');
        } else if (inputValue.length > 5 && inputValue.length <= 11) {
            // Characters after '0' up to six characters, must be alphabets
            inputValue = inputValue.slice(0, 5) + inputValue.slice(5).replace(/[^A-Z0-9]/g, '');
        }

        setFieldValue('bankIfscCode', inputValue)
    };

    const handleAccountNumberChange = (event) => {
        const inputValue = event.target.value;
        // Check if the input value is numeric using a simple regex
        if (/^\d*$/.test(inputValue)) {
          setFieldValue('bankAccountNumber', inputValue)
        }
      }
    

    const rejectionFlowLayout = () => {
        return (
            <>
                <div className='vehicle-type-container'>
                    <div className='container-header'>Document Correction</div>

                    <div style={{margin: '16px'}}>
                    {
                        fields.map((field, idx) => {
                            return (
                                <>
                                    <div className='label mt-24'>{field?.label}<sup>*</sup></div>
                                    {
                                        field.type === 'text' ? (
                                            <>
                                                <Input
                                                    className='input'
                                                    placeholder={`Enter your name`}
                                                    value={submissionValues[field.key]}
                                                    onChange={(e) => setFieldValue(field.key, e.target.value)}
                                                />
                                            </>
                                        ):null
                                    }

                                    {
                                        field.type === 'document' ? (
                                            <>

                                                <div className='doc-section d-flex' style={{gap: '32px', flexWrap: 'wrap'}}>
                                                    {
                                                        field.currentValue ? <div style={{display: 'flex', gap: '8px'}}>
                                                        <img src={field.currentValue} width={150} height={100}/>
                                                    </div>: null
                                                    }

                                                    {
                                                        submissionValues[field.key] ? <div style={{display: 'flex', gap: '8px'}}>
                                                        <img src={field.newValue} width={150} height={100}/>
                                                        <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setFieldValue(field.key, null)}>x</p>
                                                    </div>: null
                                                    }
                                                </div>
                                                
                                                
                                                {
                                                    submissionValues[field.key] ? null : (
                                                        <Upload
                                                            name="avatar"
                                                            listType="picture-card"
                                                            showUploadList={false}
                                                            accept="image/*"
                                                            onChange={(info)=>handleUpload(info, field.key)}
                                                            beforeUpload={handleBeforeUpload}
                                                            >
                                                            <div>
                                                                <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                                                <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                                            </div>
                                                        </Upload>
                                                    )
                                                }
                                            </>
                                        ):null
                                    }
                            

                            </>
                        )})
                    }

                    {
                        addressProofError ? (
                        <>
                            <div className='label mt-24'>{addressProofError?.label}<sup>*</sup></div>
                            <div className='doc-section d-flex' style={{gap: '32px', flexWrap: 'wrap'}}>
                                {
                                    addressProofError.currentValue ? <div style={{display: 'flex', gap: '8px'}}>
                                    <img src={addressProofError.currentValue} width={150} height={100}/>
                                </div>: null
                                }

                                {
                                    submissionValues['aadhaarCard'] || submissionValues['drivingLicense'] ? <div style={{display: 'flex', gap: '8px'}}>
                                    <img src={submissionValues['aadhaarCard'] || submissionValues['drivingLicense']} width={150} height={100}/>
                                    <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setFieldValue(selectedAddressProofType === 'DL' ? 'drivingLicense':'aadhaarCard' , null)}>x</p>
                                </div>: null
                                }

                                {
                                    submissionValues['aadhaarCardBack'] || submissionValues['drivingLicenseBack'] ? <div style={{display: 'flex', gap: '8px'}}>
                                    <img src={submissionValues['aadhaarCardBack'] || submissionValues['drivingLicenseBack']} width={150} height={100}/>
                                    <p style={{fontSize: '22px', fontWeight: '700', cursor: 'pointer'}} onClick={()=>setFieldValue(selectedAddressProofType === 'DL' ? 'drivingLicenseBack':'aadhaarCardBack' , null)}>x</p>
                                </div>: null
                                }
                            </div>

                            <div className='label mt-24'>Upload DL or Aadhar Card<sup>*</sup></div>
                            <Radio.Group onChange={(e)=>setSelectedAddressProofType(e.target.value)} value={selectedAddressProofType}>
                                <Radio value={'DL'}>Driving License</Radio>
                                <Radio value={'Aadhar'}>Aadhar Card</Radio>
                            </Radio.Group>
            
                            {
                                selectedAddressProofType ? (
                                    <>
                                        
                                        {

                                            submissionValues['aadhaarCard'] || submissionValues['drivingLicense'] ? null : (
                                                <>
                                                <div className='label mt-24'>{selectedAddressProofType} Front<sup>*</sup></div>
                                                <Upload
                                                    name="avatar"
                                                    listType="picture-card"
                                                    showUploadList={false}
                                                    accept="image/*"
                                                    onChange={(info)=>handleUpload(info, selectedAddressProofType === 'DL' ? 'drivingLicense':'aadhaarCard')}
                                                    beforeUpload={handleBeforeUpload}
                                                    >
                                                    <div>
                                                        <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                                        <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                                    </div>
                                                </Upload>
                                                </>
                                            )
                                        }
            
                                        {
                                        submissionValues['aadhaarCardBack'] || submissionValues['drivingLicenseBack'] ? null : (
                                            <>
                                            <div className='label mt-24'>{selectedAddressProofType} Back<sup>*</sup></div>
                                            <Upload
                                                name="avatar"
                                                listType="picture-card"
                                                showUploadList={false}
                                                accept="image/*"
                                                onChange={(info)=>handleUpload(info, selectedAddressProofType === 'DL' ? 'drivingLicenseBack':'aadhaarCardBack')}
                                                beforeUpload={handleBeforeUpload}
                                                >
                                                <div>
                                                    <Input type="file" accept="image/*" style={{ display: 'none' }} />
                                                    <Button icon={<UploadOutlined />} style={{background:'none',border: 'none'}}/> Select a file to upload 
                                                </div>
                                            </Upload>
                                            </>
                                        )
                                        }
                                    </>
                                ): null
                            }
                        </>): null
                    }
                    </div>
                </div>
                <div className='button-container'>
                    <Button className='submit-button' onClick={onSubmit}>Submit</Button>
                </div>
            </>
        )
    }

    const partiallyApprovedLayout = () => {
        return (
            <>
                <div className='vehicle-type-container'>
                    <div className='container-header'>Account Details</div>

                    <div style={{margin: '16px'}}>
                        <div className='label mt-24'>Bank Account Number<sup>*</sup></div>
                        <Input
                            className='input'
                            placeholder={`Enter your Account Number`}
                            value={submissionValues['bankAccountNumber'] || null}
                            onChange={handleAccountNumberChange}
                        />

                        <div className='label mt-24'>Bank IFSC<sup>*</sup></div>
                        <Input
                            className='input'
                            placeholder={`Enter your Bank IFSC`}
                            value={submissionValues['bankIfscCode'] || null}
                            onChange={handleIfscChange}
                        />
                        <div className="d-flex" style={{color:'#0000005c', marginTop: '2px', textAlign: 'start'}}>First 4 alphabets & fifth character is 0 (zero) & last 6 characters</div>
                    </div>
                </div>
                <div className='button-container'>
                    <Button className='submit-button' onClick={onSubmit}>Submit</Button>
                </div>
            </>
        )
    }
  return (
    <>

        {
            currentLayout === LAYOUTS.REJECTION_FLOW ? rejectionFlowLayout(): null
        }

    {
        currentLayout === LAYOUTS.PARTIALLY_APPROVED_FLOW ? partiallyApprovedLayout(): null
    }
    
    </>
  )
}

export default DocumentCorrection