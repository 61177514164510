import axios from 'axios';
import axiosInstance from './interceptorService';

export const getCandidatesData = async (data, cancelToken = null) => {
  console.log(data);
  let url = `${process.env.REACT_APP_BACKEND_URL}/getAllCandidatesList`;
  return axiosInstance.get(url, { params: data, withCredentials: true, cancelToken });
}

export const getHotLeadData = async (data, cancelToken = null) => {
  console.log(data);
  let url = `${process.env.REACT_APP_BACKEND_URL}/getHotLeadCandidateData`;
  return axiosInstance.get(url, { params: data, withCredentials: true, cancelToken });
}
    
export const getPreReferredLeadData = async (data, cancelToken = null) => {
  console.log(data);
  let url = `${process.env.REACT_APP_BACKEND_URL}/candidate/pre-referral`;
    return axiosInstance.get(url, {params: data, withCredentials: true, cancelToken});
} 
export const getUniquenessStatusForScheduledInterviewIds = async (data) => {
  // const config = {
  //   method: 'post',
  //   url: `${process.env.REACT_APP_BACKEND_URL}/getUniquenessStatusForScheduledInterviewIds`,
  //   data,
  // };
  // return axios(config);
  return axiosInstance.post(process.env.REACT_APP_BACKEND_URL + '/getUniquenessStatusForScheduledInterviewIds', data, { withCredentials: true });
}

export const downloadCandidatesPageData = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/download/geAllCandidatesList`;
  return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const getViewDetailsData = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/getViewDetailsPopupData`;
  return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const getAtRiskApplications = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/getAtRiskApplications`;
  return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const downloadAtRiskApplications = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/download/AtRiskApplications`;
  return axiosInstance.get(url, { params: data, withCredentials: true });
}

export const recruiterDashboardCandidateListDownload = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/download/recruiterDashboardCandidateList`;
  return axiosInstance.get(url, { params: data, withCredentials: true });
}
export const recruiterDashboardCandidateList = async (data, cancelToken = null) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/recruiterDashboardCandidateList`;
  return axiosInstance.get(url, { params: data, withCredentials: true, cancelToken });
}

export const displayexpiryDateChangedBanner = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/displayexpiryDateChangedBanner`;
  return axiosInstance.get(url, { params: data, withCredentials: true });
}
export const showHotLeadsPopUpBackend = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/showHotLeadsPopUp`;
  return axiosInstance.get(url, { params: data, withCredentials: true });
}
export const updateLastSeenStatus = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/updateUserFeatureLastSeenStatus`
  return axiosInstance.post(url, data, { withCredentials: true });
}

export const reassignLeads = async (data) => {
  let url = `${process.env.REACT_APP_BACKEND_URL}/reassignLeads`
  return axiosInstance.post(url, data, { withCredentials: true });
}
