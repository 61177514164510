import React, { useState, useEffect, useRef, useContext } from 'react'
import PageHeaderComponent from '../../../common/PageHeader';
import { Divider, message } from 'antd';
import { InfoCircleOutlined, RightOutlined ,CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import '../../style/BulkReferralStyle.scss'
import { uploadReferralList, uploadBulkListWithoutReferral, bulkListAndSummaryAPI, bulkUploadUniquenessLeadEntriesApi } from './../../../../service/bulkActionService'
import { useHistory, useLocation } from 'react-router-dom';
import * as loginService from '../../../../service/loginService';
import { getMitraTeamMembersList } from './../../../../service/commonService'
import StepGetJobDetails from "./BulkReferralStepOne";
import StepSelectMitrasToDistribute from "./BulkReferralStepTwo";
import StepUploadFile from "./BulkReferralStepThree";
import BulkReferralSummary from "./BulkReferralSummary";
import BulkUploadSummary from "./BulkUploadSummary";
import AIFilteringStep from './AIFilteringStep';
import { AppContext } from '../../../../App';
import CONSTANTS from '../../../../constants/constants';

const BulkReferralContainer = () => {
    const history = useHistory();
    const { mitraReducer, mitraDispatch, setAlert, setSpin } = useContext(AppContext);
    const [currentTab, setCurrentTab] = useState('bulk-referrals')
    const [breadcrumb, setBreadcrumb] = useState([{ link: '/', label: 'Home' }, { link: '/bulk-actions', label: "Bulk Options" }, { link: '/', label: "Bulk Upload & Referral" }]);
    const [stepsList, setStepsList] = useState([]);
    const [currentStep, setCurrentStep] = useState("step1")
    const [stepOneDataCity, setStepOneDataCity] = useState(null)
    const [stepOneDataForClient, setStepOneDataForClient] = useState([]);
    const [stepOneDataJobCategory, setStepOneDataJobCategory] = useState(null)
    const [stepOneDataClient, setStepOneDataClient] = useState([])
    const [stepOneDataJobId, setStepOneDataJobId] = useState([])
    const [stepOneDataJobDemandId, setStepOneDataJobDemandId] = useState([])
    const [stepOneDataJobLocation, setStepOneDataJobLocation] = useState([])
    const [stepOneDataAvailableJob, setStepOneDataAvailableJob] = useState(null)
    const [stepTwoMitraTeamMembersList, setStepTwoMitraTeamMembersList] = useState(undefined)
    const [stepThreeFile, setStepThreeFile] = useState([])
    const [stepThreeUploading, setStepThreeUploading] = useState(false)
    const [isAiFilteringEnabled, setIsAiFilteringEnabled] = useState(false);
    const [withoutReferral, setWithoutReferral] = useState(false);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const enableAIFiltering = params.get('enableAiFiltering');

    const [mitra, setMitra] = useState({})
    const [mitraTeamList, setMitraTeamList] = useState([])
    const [bulkUploadSummary, setBulkUploadSummary] = useState()
    const [bulkReferralSummary, setBulkReferralSummary] = useState([
        {
                "totalLeads": 0,
                "processedLeads": 0,
                "failedCount": 0
            
        }
    ])
    const scrollStepsRef = useRef(null);
    const [jobLocationsArray, setJobLocationsArray] = useState([]);
    const [companyNamesArray, setCompanyNamesArray] = useState([]);
    const [jobIDArray, setJobIDArray] = useState([]);
    const [jobDemandIDArray, setJobDemandIDArray] = useState([]);


    const getTeamMemberInfo = () => {
        getMitraTeamMembersList(true).then(res => {
            let mitraNameNumberArray = res.data.data.map(ele => {
                return {
                    value: ele.id,
                    label: `${ele.name} - ${ele.phoneNumber}`
                }
            })
            mitraNameNumberArray = [{ value: mitraReducer?.mitraInfo?.id, label: `${mitraReducer?.mitraInfo?.name} - ${mitraReducer?.mitraInfo?.phoneNumber}` }, ...mitraNameNumberArray]
            setMitraTeamList(mitraNameNumberArray)
        });
    };

    const addAIFilteringStep = () => {
        setStepsList([
            {
                key: "step1",
                label: "1: AI Filtering",
                isSelected: true,
                isCompleted: false,
                showNewTag: true
            },
            {
                key: "step2",
                label: "2: Upload File",
                isSelected: false,
                isCompleted: false
            },
            {
                key: "step3",
                label: "3: Select Job Details",
                isSelected: false,
                isCompleted: false
            },
            {
                key: "step4",
                label: "4: Select Team Members",
                isSelected: false,
                isCompleted: false
            },
            {
                key: "summary",
                label: "Summary",
                isSelected: false,
                isCompleted: false
            }
        ]);
    }

    const skipAIFiltering = () => {
        setStepsList([
            {
                key: "step1",
                label: "1: Upload File",
                isSelected: true,
                isCompleted: false
            },
            {
                key: "step2",
                label: "2: Referral Details",
                isSelected: false,
                isCompleted: false
            },
            {
                key: "step3",
                label: "3: Add Team Member(s)",
                isSelected: false,
                isCompleted: false
            },
            {
                key: "summary",
                label: "Summary",
                isSelected: false,
                isCompleted: false
            }
        ]);
    }

    useEffect(() => {
        if(mitraReducer?.mitraInfo?.id) {
            getTeamMemberInfo();
            setMitra(mitraReducer?.mitraInfo);

            if(enableAIFiltering === 'true' && CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.id)) {
                // to check if AI Filtering step is to be included or not
                addAIFilteringStep();
            } else {
                skipAIFiltering();
            }
        }
    }, [mitraReducer?.mitraInfo?.id]) //ensuring that useeffect only retriggers if the id is changed.
    
    useEffect(() => {
      if(enableAIFiltering === 'true') {
        setIsAiFilteringEnabled(true);
      } else {
        setIsAiFilteringEnabled(false);
      }
    }, [enableAIFiltering])
    

    const proceedToNext = (stepKey) => {
        switch (stepKey) {
            case "step1":
                updateCurrentStep("step1", "step2")
                break;
            case "step2":
                updateCurrentStep("step2", "step3")
                break;
            case "step3":
                updateCurrentStep("step3", "step4")
                break;
            case "step4":
                updateCurrentStep("step4", "step5")
                break;
            default:
                return;
        }
    }

    const goToStep = (step, stepInd) => {
        const newStepList = [...stepsList];
        for (let i = 0; i < newStepList.length; i++) {
            if (newStepList[i].key == step.key && (step.isCompleted || (newStepList[stepInd - 1] && newStepList[stepInd - 1].isCompleted))) {
                newStepList[i].isSelected = true;
                setCurrentStep(step.key)
            }
            else {
                newStepList[i].isSelected = false;
            }
        }

        setStepsList([...newStepList]);
    }

    const updateCurrentStep = (currentStepKey, nextStepKey) => {
        const newStepList = [...stepsList];
        for (let i = 0; i < newStepList.length; i++) {
            if (newStepList[i].key === currentStepKey) {
                newStepList[i].isCompleted = true;
            }
            if (newStepList[i].key === nextStepKey) {
                newStepList[i].isSelected = true;
            }
            else {
                newStepList[i].isSelected = false;
            }
        }

        setStepsList([...newStepList]);
        setCurrentStep(nextStepKey);

        scrollToStep(nextStepKey);

        // if (nextStepKey == "step3" || nextStepKey == "step4") {
        //     if (scrollStepsRef && scrollStepsRef.current) {
        //         scrollStepsRef.current.scrollLeft += 400
        //     }
        // }
    }

    const scrollToStep = (stepId) => {
        // Determinig if the stepId is summary
        if (stepId === 'step5' || (stepId === 'step4' && enableAIFiltering != 'true') ) stepId = 'summary';
        
        const stepElement = document.getElementById(stepId);
        if (stepElement) {
          stepElement.scrollIntoView({ behavior: 'smooth', inline: 'center' });
        }
      };

    const markStepAsIncomplete = (prevStepKey) => {
        const newStepList = [...stepsList];

        let postCurrentStep = false; // using this variable to mark steps incomplete post current step
        for (let i = 0; i < newStepList.length; i++) {
            if (newStepList[i].key == prevStepKey && prevStepKey !== "step2") {
                newStepList[i].isCompleted = true;
                postCurrentStep = true;
            }
            else {
                if(postCurrentStep) newStepList[i].isCompleted = false;
            }
        }

        setStepsList([...newStepList]);
    }

    const markStepAsComplete = (stepKey) => {
        for (let i = 0; i < stepsList.length; i++) {
            if (stepsList[i].key == stepKey) {
                stepsList[i].isCompleted = true;
                break;
            }
        }
    }

    const uploadBulkDataAPI = (calledFromStep) => {
        setStepThreeUploading(true);
        const bulkAPIData = new FormData();
        bulkAPIData.append('files', stepThreeFile);
        bulkAPIData.append('enableAIFiltering', isAiFilteringEnabled);
        bulkAPIData.append('city', stepOneDataCity);
        bulkAPIData.append('companyName', JSON.stringify(companyNamesArray));
        bulkAPIData.append('jobId', JSON.stringify(jobIDArray));
        bulkAPIData.append('jobLocation', JSON.stringify(jobLocationsArray));
        bulkAPIData.append('jobDemandId', JSON.stringify(jobDemandIDArray));
        bulkAPIData.append('mitraManagerId', mitra.id);
        bulkAPIData.append('mitraListSelected', stepTwoMitraTeamMembersList && stepTwoMitraTeamMembersList.length > 0 ? JSON.stringify(stepTwoMitraTeamMembersList) : JSON.stringify([mitra.id]));
        uploadReferralList(bulkAPIData).then(response => {
            if (response && response.data) {
                if (response?.data?.data && response.data?.data?.bulkUploadUniquenessLeadIds) {
                    getReferredLeadsSummary(response.data.data.bulkUploadUniquenessLeadIds, calledFromStep)
                }
                else {
                    message.error(`Something went wrong, please try again in sometime.`, 5);
                }
            }
            else {
                message.error(`Something went wrong, please try again in sometime.`, 5);
            }
        })
            .catch(err => {
                setStepThreeUploading(false)
                if (err.response?.data?.candidateResponse) {
                    message.error(`Something went wrong, please check the downloaded error sheet`, 8);
                    downloadErrorResponseSheet(err.response?.data?.candidateResponse)
                }
                else if (err.response?.data?.error) {
                    message.error(`Something went wrong: ${err.response.data.error}`, 5);
                }
                else {
                    message.error(`Something went wrong: ${err}`, 5);
                }
            })
    }

    const uploadBulkDataWOReferralAPI = (calledFromStep) => {
        setStepThreeUploading(true);
        const bulkAPIData = new FormData();
        bulkAPIData.append('files', stepThreeFile);
        bulkAPIData.append('mitraListSelected', stepTwoMitraTeamMembersList && stepTwoMitraTeamMembersList.length > 0 ? JSON.stringify(stepTwoMitraTeamMembersList) : JSON.stringify([mitra.id]));
        uploadBulkListWithoutReferral(bulkAPIData).then(response => {
            if (response?.data?.status) {
                if (response?.data?.uploadedCount) {
                    getUploadedLeadsSummary(response.data, calledFromStep)
                }
                else {
                    message.error(`Something went wrong, please try again in sometime.`, 5);
                }
            }
            else {
                message.error(`Something went wrong, please try again in sometime.`, 5);
            }
        })
            .catch(err => {
                setStepThreeUploading(false)
                if (err.response?.data?.message) {
                    message.error(`Something went wrong: ${err.response.data.message}`, 5);
                }
                else if (err.response?.data?.error) {
                    message.error(`Something went wrong: ${err.response.data.error}`, 5);
                }
                else {
                    message.error(`Something went wrong: ${err}`, 5);
                }
            })
    }

    const downloadErrorResponseSheet = (errorResponseUrl) => {
        const link = document.createElement('a');
        link.href = errorResponseUrl;
        link.download = 'upload-errors.xlsx'; // Specify the desired filename

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    const getReferredLeadsSummary = (leadEntryIds = "", calledFromStep) => {
        bulkUploadUniquenessLeadEntriesApi(leadEntryIds).then((summaryRes) => {
            if (summaryRes && summaryRes?.data && summaryRes?.data?.data) {
                setStepThreeUploading(false);
                proceedToNext(calledFromStep)
                setCurrentStep("summary")
                setBulkReferralSummary(summaryRes.data.data)
            }
        }).catch((err) => {
            setStepThreeUploading(false);
            message.error(`Something went wrong: ${err}`, 5);
        })
    }

    const getUploadedLeadsSummary = (data="", calledFromStep) => {
        setStepThreeUploading(false);
        proceedToNext(calledFromStep)
        setCurrentStep("summary")
        if(data != "") {
            setBulkUploadSummary(data)
        }
    }

    const handleStepOneDataChange = (dataKey = "", dataVal, stepKey = "") => {
        if (stepKey) {

            switch (dataKey) {
                case "jobId":
                    setStepOneDataJobId(dataVal);
                    break;
                case "jobDemandId":
                    setStepOneDataJobDemandId(dataVal);
                    break;
                case "city":
                    if (stepOneDataCity !== dataVal) {
                        // markStepAsIncomplete(stepKey)
                    }
                    // else {
                    //     markStepAsComplete(stepKey)
                    // }
                    setStepOneDataCity(dataVal);
                    break;
                case "clientData":
                    if (Array.isArray(dataVal)) {
                        setStepOneDataForClient(dataVal);

                        const companyNames = dataVal.map(item => item.companyName);
                        setCompanyNamesArray(companyNames);

                        // Store jobLocation in a separate array
                        const jobLocations = dataVal.map(item => item.selectedJobLocation);
                        setJobLocationsArray(jobLocations);

                        // Extract and store jobIDList index-wise
                        const jobIDs = dataVal.map(item => item.jobIDList);  // Directly push the jobIDList for each client
                        setJobIDArray(jobIDs);

                        // Extract and store jobDemandIDList index-wise
                        const jobDemandIDs = dataVal.map(item => item.jobDemandIDList);  // Directly push the jobDemandIDList for each client
                        setJobDemandIDArray(jobDemandIDs);
                    } else {
                        console.error("Invalid client data format", dataVal);
                    }
                    break;
                        
                case "category":
                    if (stepOneDataJobCategory !== dataVal) {
                        // markStepAsIncomplete(stepKey)
                    }
                    // else {
                    //     markStepAsComplete(stepKey)
                    // }
                    setStepOneDataJobCategory(dataVal);
                    break;
                case "client":
                    setStepOneDataClient(dataVal);
                    break;
                case "location":
                    setStepOneDataJobLocation(dataVal);
                    break;
            }
        }
    }

    const clearSelectedClientData = () => {
        setStepOneDataForClient([]);
      };

    const executionConditionalLadderWithoutAIFiltering = () => {
        return (
                currentStep == 'step2' ?
                    <StepGetJobDetails
                        selectedCityFromParent={stepOneDataCity}
                        selectedClientDataFromParent={stepOneDataForClient}
                        clearSelectedClientData={clearSelectedClientData}
                        selectedJobCategoryFromParent={stepOneDataJobCategory}
                        selectedClientFromParent={stepOneDataClient}
                        selectedJobLocationFromParent={stepOneDataJobLocation}
                        isAiFilteringEnabled={isAiFilteringEnabled}
                        setIsAiFilteringEnabled={setIsAiFilteringEnabled}
                        enableAIFiltering={enableAIFiltering}
                        returnStepOneCity={(city) => {
                            handleStepOneDataChange("city", city, "step2");
                            // setStepOneDataCity(city);
                        }}
                        returnStepOneJobCategory={(category) => {
                            handleStepOneDataChange("category", category, "step2");
                            // handleStepOneJobCategoryChange(category)
                        }}
                        returnStepOneClientData={(clientData) => {
                            handleStepOneDataChange("clientData", clientData, "step3")
                        }}
                        returnStepOneClient={(client) => {
                            handleStepOneDataChange("client", client, "step2");
                            // setStepOneDataClient(client); 
                        }}
                        returnStepOneJobId={(jobId) => {
                            handleStepOneDataChange("jobId", jobId, "step3");
                        }}
                        returnStepOneJobDemandId={(jobDemandId) => {
                            handleStepOneDataChange("jobDemandId", jobDemandId, "step3");
                        }}
                        returnStepOneJobLocation={(location) => {
                            handleStepOneDataChange("location", location, "step2");
                            //  setStepOneDataJobLocation(location); 
                        }}
                        returnStepOneAvailableJobs={(availableJob) =>
                            setStepOneDataAvailableJob(availableJob)
                        }
                        setWithoutReferral={setWithoutReferral}
                        goToNextStep={() => proceedToNext("step2")}
                        clearedStep={() => markStepAsIncomplete("step2")} /> :
                    currentStep == "step3" ?
                        <StepSelectMitrasToDistribute
                            mitra={mitra}
                            mitraTeamList={mitraTeamList}
                            selectedMitraTeamMembersFromParent={stepTwoMitraTeamMembersList}
                            returnStepTwoMitraTeamMemberList={(data) => setStepTwoMitraTeamMembersList(data)}
                            goToNextStep={() => withoutReferral ? uploadBulkDataWOReferralAPI('step3') : uploadBulkDataAPI('step3')}
                            isUploading={stepThreeUploading}
                            clearedStep={() => markStepAsIncomplete("step3")} /> :
                        currentStep == "step1" ?
                            <StepUploadFile
                                selectedFileFromParent={stepThreeFile}
                                returnStepThreeUploadedFile={(data) => setStepThreeFile(data)}
                                onUploadClicked={() => proceedToNext("step1")}
                                isUploading={stepThreeUploading} /> : 
                                (withoutReferral ?
                                <BulkUploadSummary summaryData={bulkUploadSummary}/> :
                                <BulkReferralSummary summaryData={bulkReferralSummary} isAiFilteringEnabled={isAiFilteringEnabled}/>
                                )
        )
    };

    const executionConditionalLadderWithAIFiltering = () => {
        return (
            currentStep == "step1" ?
            <AIFilteringStep 
            isAiFilteringEnabled={isAiFilteringEnabled}
            setIsAiFilteringEnabled={setIsAiFilteringEnabled}
            isUploading={stepThreeUploading}
            goToNextStep={() => proceedToNext("step1")}
            mitraReducer={mitraReducer}
            /> :
                currentStep == "step3" ?
                    <StepGetJobDetails
                        selectedCityFromParent={stepOneDataCity}
                        selectedClientDataFromParent={stepOneDataForClient}
                        clearSelectedClientData={clearSelectedClientData}
                        selectedJobCategoryFromParent={stepOneDataJobCategory}
                        selectedClientFromParent={stepOneDataClient}
                        selectedJobLocationFromParent={stepOneDataJobLocation}
                        isAiFilteringEnabled={isAiFilteringEnabled}
                        setIsAiFilteringEnabled={setIsAiFilteringEnabled}
                        enableAIFiltering={enableAIFiltering}
                        returnStepOneCity={(city) => {
                            handleStepOneDataChange("city", city, "step3");
                            // setStepOneDataCity(city);
                        }}
                        returnStepOneClientData={(clientData) => {
                            handleStepOneDataChange("clientData", clientData, "step3")
                        }}
                        returnStepOneJobCategory={(category) => {
                            handleStepOneDataChange("category", category, "step3");
                            // handleStepOneJobCategoryChange(category)
                        }}
                        returnStepOneClient={(client) => {
                            handleStepOneDataChange("client", client, "step3");
                            // setStepOneDataClient(client); 
                        }}
                        returnStepOneJobId={(jobId) => {
                            handleStepOneDataChange("jobId", jobId, "step3");
                        }}
                        returnStepOneJobDemandId={(jobDemandId) => {
                            handleStepOneDataChange("jobDemandId", jobDemandId, "step3");
                        }}
                        returnStepOneJobLocation={(location) => {
                            handleStepOneDataChange("location", location, "step3");
                            //  setStepOneDataJobLocation(location); 
                        }}
                        returnStepOneAvailableJobs={(availableJob) =>
                            setStepOneDataAvailableJob(availableJob)
                        }
                        goToNextStep={() => proceedToNext("step3")}
                        clearedStep={() => markStepAsIncomplete("step3")} /> :
                    currentStep == "step4" ?
                        <StepSelectMitrasToDistribute
                            mitra={mitra}
                            mitraTeamList={mitraTeamList}
                            selectedMitraTeamMembersFromParent={stepTwoMitraTeamMembersList}
                            returnStepTwoMitraTeamMemberList={(data) => setStepTwoMitraTeamMembersList(data)}
                            goToNextStep={() => uploadBulkDataAPI("step4")}
                            isUploading={stepThreeUploading}
                            clearedStep={() => markStepAsIncomplete("step4")} /> :
                        currentStep == "step2" ?
                            <StepUploadFile
                                selectedFileFromParent={stepThreeFile}
                                returnStepThreeUploadedFile={(data) => setStepThreeFile(data)}
                                onUploadClicked={() => proceedToNext('step2')}
                                isUploading={stepThreeUploading} /> : 
                                <BulkReferralSummary summaryData={bulkReferralSummary} isAiFilteringEnabled={isAiFilteringEnabled}/>
        )
    }

    return (
        <div className='bulk-referral-new-parent'>
            <div className="containerTop">
                <div className="header">
                    <>
                        <PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb} />
                    </>
                </div>

            </div>

            <Divider style={{margin: '0px 0px 16px'}}></Divider>

            <div className='br-below-divider'>
                {
                    isAiFilteringEnabled && currentStep === 'step1' ? (
                        <div className='d-flex align-items-center'>
                            <InfoCircleOutlined className='br-info-icon' />
                            <div className='br-info-txt'>
                                In the next steps, you will need to upload a file of candidate names and their phone numbers. You can download the template of the file <u><em><a href="https://uploads-a.vahan.co/uBUqb8-bulk-action-template.xlsx">here</a></em></u>.
                            </div>
                        </div>
                    ): null
                }

                <div className='show-only-desktop'>
                    <div className='br-steps-parent d-flex align-items-center w-100'>
                        {
                            stepsList.map((step, stepInd) => {
                                return (
                                    <div key={stepInd} className={`br-step br-cp d-flex align-items-center justify-content-center ${stepInd !== stepsList.length - 1 ? 'br-step-w30' : 'br-step-w20'}`} id={`${stepInd == 2 ? "br-scroll-into-view" : ""}`} onClick={() => goToStep(step, stepInd)}>
                                        <div className={`d-flex align-items-center br-step-txt ${currentStep == step.key ? "br-active-step" : ""}`}>
                                            {
                                                step.isCompleted ? <CheckCircleOutlined style={{color:'#31C824D9',fontSize:'14px',marginRight:'8px'}}/>: null
                                            }
                                            {step.label}
                                            {
                                                step?.showNewTag ? <img style={{ width: 'auto', height: '25px', marginLeft: '6px' }} src="/image/new-icon.gif" />: null
                                            }
                                        </div>
                                        <div className='br-step-arrow-img d-flex'>
                                            {stepInd + 1 < stepsList.length ? <RightOutlined /> : null}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='show-only-mobile'>
                    <div className='br-steps-parent d-flex align-items-center' ref={scrollStepsRef}>
                        {
                            stepsList.map((step, stepInd) => {
                                return (
                                    <div id={step?.key} key={stepInd} className={`br-step-mobile br-cp d-flex align-items-center justify-content-center ${stepInd !== stepsList.length - 1 ? 'br-step-mobile-w75' : 'br-step-mobile-w65'}`} onClick={() => goToStep(step, stepInd)}>
                                        <div className={`br-step-txt ${currentStep == step.key ? "br-active-step" : ""}`}>
                                            {
                                                step.isCompleted ? <CheckCircleOutlined style={{color:'#31C824D9',fontSize:'14px',marginRight:'8px'}}/>: null
                                            }
                                            {step.label}
                                            {
                                                step?.showNewTag ? <img style={{ width: 'auto', height: '25px', marginLeft: '6px' }} src="/image/new-icon.gif" />: null
                                            }
                                        </div>
                                        <div className='br-step-arrow-img d-flex'>
                                            {stepInd + 1 < stepsList.length ? <RightOutlined /> : null}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                        {/* Add conditional code */}
                {
                    !mitraReducer?.mitraInfo?.id ? null: 
                    (
                        CONSTANTS.samvadiniCallingEligibleIds.includes(mitraReducer?.mitraInfo?.id) && 
                        enableAIFiltering === 'true'
                    ) ? executionConditionalLadderWithAIFiltering():executionConditionalLadderWithoutAIFiltering()
                }
            </div>
        </div>
    )
}

export default BulkReferralContainer; 