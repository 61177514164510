import React, { useContext, useEffect, useState } from 'react';
import {CheckOutlined} from '@ant-design/icons';
import { notification } from 'antd';
import OTPScreen from './OTPScreen';
import DocumentCorrection from './DocumentCorrection';
import { AppContext } from '../../../App';
import { postObOtpStatus } from '../../../service/ZomatoRejectionFlowAPIService';
import commonFunction from '../../../utils/commonFunction';
import { useHistory } from "react-router-dom";

function ZomatoRejectionFlowContainer() {
    const [currentTab, setCurrentTab] = useState('correction');
    const {mitraReducer,mitraDispatch, setAlert,setSpin } = useContext(AppContext);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const history = useHistory();
    const [lead] = useState(commonFunction.getAllsearchParams(history.location.search))
    const [tabInfo, setTabInfo] = useState([
        {
            id: 'otpVerification',
            label: 'OTP Verification',
            status: 'pending',
            isClickable: false // This will be used when otp expires
        },
        {
            id: 'vehicleType',
            label: 'Vehicle Type',
            status: 'pending',
            isClickable: false
        },
        {
            id: 'city',
            label: 'City',
            status: 'pending',
            isClickable: false
        },
        {
            id: 'zone',
            label: 'Zone',
            status: 'pending',
            isClickable: false
        },
        {
            id: 'verification',
            label: 'Document Upload',
            status: 'pending',
            isClickable: false
        },
        {
            id: 'selfieUpload',
            label: 'Selfie Upload',
            status: 'pending',
            isClickable: false
        },
        {
            id: 'appDownload',
            label: 'App Download',
            status: 'pending',
            isClickable: false
        },
        {
            id: 'newOtp',
            label: 'OTP Screen',
            status: 'done',
            isClickable: true
        },
        {
            id: 'correction',
            label: 'Document Correction',
            status: 'inProgress',
            isClickable: true
        }
    ]);

    const onTabClick = (item) => {
        if(item.status === 'pending') return;

        if(!item.isClickable) {
            notification['error'] ({
                message: 'Please verify the OTP to continue'
            });
            return;
        }

        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].id === item.id) {
                newTabInfo[i].status = 'inProgress';
                setCurrentTab(newTabInfo[i].id);
            } else {
                if(newTabInfo[i].status === 'inProgress') {
                    newTabInfo[i].status = 'done';
                }
            }
        }

        setTabInfo([...newTabInfo]);
    };

    const otpVerificationFailedProcess = () => {
        setIsOtpVerified(false);

        // make current step as otp step, disable all the tab clicks
        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].id === 'newOtp') {
                newTabInfo[i].status = 'inProgress';
                newTabInfo[i].isClickable = true;
                setCurrentTab(newTabInfo[i].id);
            } else {
                newTabInfo[i].status = 'pending';
                newTabInfo[i].isClickable = false;
            }
        }

        setTabInfo([...newTabInfo]);
    }

    const showLoader = (value) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: 'Loading...'  //Loading copy here
        })
    }

    const goToDocCorrectionScreen = () => {
        // make current step as otp step, disable all the tab clicks
        const newTabInfo = [...tabInfo];

        for (let i = 0; i < newTabInfo.length; i++) {
            if(newTabInfo[i].id === 'newOtp') {
                newTabInfo[i].status = 'done';
                newTabInfo[i].isClickable = true;
            } else if(newTabInfo[i].id === 'correction') {
                newTabInfo[i].status = 'inProgress';
                newTabInfo[i].isClickable = true;
                setCurrentTab(newTabInfo[i].id);
            }else {
                newTabInfo[i].status = 'pending';
                newTabInfo[i].isClickable = false;
            }
        }

        setTabInfo([...newTabInfo]);
    }

    const checkPostObSessionStatus = async() => {
        if(lead?.phoneNumber) {
            try {
                const payload = {
                    phoneNumber: lead?.phoneNumber
                }

                const response = await postObOtpStatus(payload);
                if(response.data?.success) {
                    setIsOtpVerified(true);
                    goToDocCorrectionScreen();
                } else {
                    otpVerificationFailedProcess();
                }

                showLoader(false);
            } catch(e) {
                otpVerificationFailedProcess();
            }
        }
    }

    useEffect(() => {
      if(lead?.phoneNumber) {
        checkPostObSessionStatus();
      }
    }, [lead])
    
  return (
    <div className="AdditionalInfoScreen mainContentZ">
        <div className='header-text'>Zomato Application Correction Form</div>
        <ul>
            <li style={{display: 'flex', textAlign: 'start', gap: '6px'}}>
                <b>Note:</b>
                <p>Please upload your candidates details and documents in this section. These will be directly submitted to Zomato. You can also upload the rider selfie image through this flow</p>
            </li>
        </ul>

        <div className="tab-container">
            {
                tabInfo.map((item, index) => {
                    return (
                        <div 
                        key={item.id} 
                        className={
                            `tab-item${item.status === 'inProgress' ? ' active': item.status === 'pending'? ' pending': ' done'}`
                        }
                        onClick={()=>onTabClick(item)}
                        >
                            {item.label} {item.status === 'done' ? <CheckOutlined style={{color: '#4BB543'}}/>: null}
                        </div>
                    )
                })
            }
        </div>

        <div className='form-content-container'>
            {/* Tabs content */}
            {
                currentTab === 'newOtp' ? (
                <OTPScreen
                determineCurrentStep={goToDocCorrectionScreen}
                isOtpVerified={isOtpVerified}
                lead={lead}
                goToNextScreen={goToDocCorrectionScreen}
                setIsOtpVerified={setIsOtpVerified}
                />) : null
            }

            {
                currentTab === 'correction' ? (
                <DocumentCorrection lead={lead}/>
                ) : null
            }
        </div>
    </div>
  )
}

export default ZomatoRejectionFlowContainer