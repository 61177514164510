import axios from 'axios';
import axiosInstance from './interceptorService';

export const getNotificationsContent = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/comms/getNotificationsContent`;
    return axiosInstance.get(url, { params: data, withCredentials: true });
};
export const getNotificationsForMitra = async (data) => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/comms/getNotificationsForMitra`;
    return axiosInstance.get(url, { withCredentials: true,params: data ,headers:{
        payoutAccessToken:localStorage.getItem('payoutAccessToken'),
        isAdmin: data.isAdmin
    }});
};

export const getMessages = async (data) => {
    let url = `${process.env.REACT_APP_SELF_SERVE_FLOW_CHAT_API_BASE_URL}/getMessages`;
    return axios.post(url, data, {headers: {
        'X-api-key': process.env.REACT_APP_JOBSEEKER_ENGAGEMENT_ACCESS_TOKEN_SECRET_KEY}}
    );
};

export const getMessagesUserId = async (data) => {
    let url = `${process.env.REACT_APP_SELF_SERVE_FLOW_CHAT_API_BASE_URL}/getMessages`;
    return axios.post(url, data, {headers: {
        'X-api-key': process.env.REACT_APP_JOBSEEKER_ENGAGEMENT_ACCESS_TOKEN_SECRET_KEY}}
    );
};

export const postMessagesUserId = async (data) => {
    let url = `${process.env.REACT_APP_SELF_SERVE_FLOW_CHAT_API_BASE_URL}/message`;
    return axios.post(url, data, {headers: {
        'X-api-key': process.env.REACT_APP_JOBSEEKER_ENGAGEMENT_ACCESS_TOKEN_SECRET_KEY}}
    );
};

export const getUnreadNotificationsCount = async () => {
    let url = `${process.env.REACT_APP_BACKEND_URL}/comms/getUnreadNotificationsCountForMitra`;
    return axiosInstance.get(url, { withCredentials: true });
}


