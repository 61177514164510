import React, { useState, useEffect, useContext, useReducer } from 'react';
import LeadCard from './LeadCard.component';
import Contact from '../leads/Contact.component';
import CandidateProfilePopup from '../leads/candidateProfilePopup.component'
import '../../css/report.scss'
import SideSheet from "../common/SideSheet"
import { useHistory } from 'react-router-dom';
import moment from "moment";
import { getCandidatesData, downloadLeadStatusSheet, getPerformanceDashboardCandidateList, DownloadPerformanceDashboardCandidatePageData, downloadPayoutLeads, getReportPayoutLeadsList } from './../../service/reportService'
import { useLocation } from "react-router-dom";
import useGAEventsTracker from '../../service/useGSEventsTracker';
import { DatePicker, Button, Input, Select, Skeleton, Tag, Tabs, Empty, Space, Typography, Pagination, Anchor, Table } from 'antd';
import { AppContext } from '../../App';
import { DownloadOutlined, SearchOutlined, CloseOutlined, ArrowLeftOutlined, ArrowRightOutlined, FilterOutlined, WarningOutlined } from '@ant-design/icons';
import { getActiveClientsForFilter, getMitraTeamMembersList, postReferAgain, getStatusListForFilters, getLocationForFilter } from './../../service/commonService'
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import PageHeaderComponent from '../common/PageHeader';
import SearchComponent from '../common/search.component';
import Lead from '../common/candidateApplication/CandidateApplicationCard.component';
import SideInput from "../common/SiderInput"
import commonFunc from "../../utils/commonFunction";
import CandidateProfilePopupV2 from '../leads/candidateProfilePopupV2Components/candidateProfilePopupV2';
import RaiseIssueSider from '../help/RaiseIssueSider.component';
import LocationFilterComponent from '../common/LocationFilter.component';
import VerifyEmailPopup from './../common/VerifyEmailPopup.component';
import { getDisplayableClientName } from './../../utils/commonFunction'


const { Search } = Input;
const { Option } = Select;
const { Text } = Typography;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';
const { TabPane } = Tabs;

const ReportLeadPage = () => {
	const { mitraReducer, mitraDispatch, setAlert, setSpin } = useContext(AppContext);
	const history = useHistory();
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	const [startDate, setStartDate] = useState(mitraReducer?.reportParams?.startDate || moment().startOf('month').format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(mitraReducer?.reportParams?.endDate || moment().format('YYYY-MM-DD'));
	const [mitra, setMitra] = useState({});
	const [isAdmin, setIsAdmin] = useState(false)
	const [clientFilterList, setClientFilterList] = useState(mitraReducer?.reportParams?.clientList || [])
	const [teamList, setTeamList] = useState(mitraReducer?.reportParams?.teamList || []);
	const [selectedTeamMember, setSelectedTeamMember] = useState(mitraReducer?.reportParams?.selectedTeamMember ? mitraReducer?.reportParams?.selectedTeamMember : 0);
	const location = useLocation();
	const [leads, setLeads] = useState([]);
	const [showDetail, setShowDetail] = useState(false);
	const [selectedLead, setSelectedLead] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [statusesListForFilters, setStatusesListForFilters] = useState([])
	const [currentJob, setCurrentJob] = useState({});
	const ReportGAEventsTracker = useGAEventsTracker("Reports")
	// const [tabParam, setTabParam] = useState(decodeURIComponent(location.search.substr(1, location.search.indexOf("=") > -1 ? location.search.indexOf("=") - 1 : "")));
	const [tabParam, setTabParam] = useState(decodeURIComponent(location?.search?.split("&")[0]?.substr(1)?.replace(/=/g, '')));
	const [secondarySearchKey, setSecondarySearchKey] = useState(decodeURIComponent(location?.search?.split("&")[1]));
	const [breadcrumb, setBreadcrumb] = useState([{ link: '/report/performance-dashboard', label: 'Report' }, { link: '/report/performance-dashboard', label: 'Performance Dashboard' }, { link: '', label: tabParam }]);
	// const [createMoengageUser, trackEvent] = useMoengage();
	const [filterSider, setfilterSider] = useState(false);
	const [triggerLoad, ontriggerLoad] = useState(false);
	const [referAgain, setReferAgain] = useState(false);
	const [alternateNumber, setAlternateNumber] = useState();
	const [submitdisable, setSubmitdisable] = useState(true);
	const [totalApplicationCount, setTotalApplicationCount] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageLimit, setPageLimit] = useState(50);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const [selectedScheduledInterviewId, setSelectedScheduledInterviewId] = useState([]);
	const [selectedRecommendationHistoryId, setSelectedRecommendationHistoryId] = useState([]);
	const [viewDetailLead, setViewDetailLead] = useState({});
	const [raiseIssueSider, setRaiseIssueSider] = useState(false);
	const [locationList, setLocationList] = useState(mitraReducer?.reportParams?.locationList || []);
	const [typeOfLead, setTypeOfLead] = useState(mitraReducer?.reportParams?.typeOfLead || location.search.split("&").find(pair => pair.includes('typeOfLeads'))?.split('=')[1] || "Eligible Leads");
	const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
	const [changeEmail, setChangeEmail] = useState(false);
	const isMitraAppDownloadTab = ['Within 3 days of Onboarding', 'After 3 days of Onboarding', 'Activated but App Not Downloaded', 'Pre Onboarding', 'Total Vahan App Downloads'].includes(tabParam);
	const [disableDatesBefore, setDisableDatesBefore] = useState(moment());
	const [arrStatus] = useState(
		[
			'Action Needed Referrals',
			'Completed referrals',
			...(isMitraAppDownloadTab ? [tabParam] : []),
			'Unique Candidates',
			["Action Needed Referrals", "Completed referrals", "Unique Candidates"].includes(tabParam) ? '' : tabParam,
			// ...(mitraReducer?.reportParams?.selectedMilestoneList?.length ?
			// 	mitraReducer?.reportParams?.selectedMilestoneList :
			// 	[!isMitraAppDownloadTab ? !["Action Needed Referrals", "Completed referrals", "Activations", "Unique Candidates"].includes(tabParam) : ''])
		]
	);
	const [leadTableColumn, setLeadTableColumn] = useState([])
	const leadsTableColumns = history.location?.state?.payoutname === 'Base Pay' ? [
		{
			title: 'Name',
			dataIndex: 'firstName',
			key: 'fname',
			fixed: 'left',
			className: 'fixed-column',
			render(text, record) {
				return {
					children: <div>{record.lastName !== 'Unknown' ? record.firstName + ' ' + record.lastName : record.firstName}</div>
				}
			}
		},
		{
			title: 'Mobile Number',
			dataIndex: 'phoneNumber',
			fixed: 'left',
			className: 'fixed-column',
			key: 'phoneNumber'
		},
		{
			title: 'Client',
			dataIndex: 'companyName',
			key: 'cName',
			fixed: 'left',
			className: 'fixed-column',
			render(text, record) {
				return {
					children: <div>{text}</div>
				}
			}
		},
		// {
		// 	title: `Transition Arrear `,
		// 	dataIndex: 'arrear',
		// 	key: 'arrear',
		// 	render(text,record) {
		// 		return {
		// 			children: record.arrear[0] ? <div >{'Rs '+ record.arrear[0].newAmount}<br/>{moment(record.arrear[0].payoutDate).format('DD MMM YYYY')}</div>:'-'
		// 		}
		// 	}
		// },
		...(history.location?.state?.payload?.label == 'A. Unit Payouts' ? [{
			title: 'Client Name',
			dataIndex: 'companies',
			key: 'client',
			render(text, record) {
				return {
					// props: {
					// style: { background: parseInt(text) > 50 ? "red" : "green" }
					// },
					children: <div>{text}</div>
				};
			}
		}] : []),
		{
			title: 'M1',
			dataIndex: 'milestoneAchieved',
			key: 'campaign',
			render(text, record) {
				if (!Array.isArray(text)) {
					text = [text];
				}
				// iterate text array and check if it does not contains 'flash', if yes then return text[index] else return '-'
				for (let i = 0; i < record.campaignDisplayName.length; i++) {
					if (record.campaignDisplayName[i] && !record.campaignDisplayName[i].toLowerCase().includes('flash')) {
						return {
							children: <div>{text[i]}</div>
						}
					}
				}
				return {
					children: <div>-</div>
				}
			}
		},
		{
			title: 'M1 Payout',
			dataIndex: 'amount',
			key: 'amount',
			// set minimum width
			width: 150,
			render(text, record) {
				if (!Array.isArray(text) || !Array.isArray(record.campaignDisplayName)) {
					text = [text];
					record.campaignDisplayName = [record.campaignDisplayName];
				}
				// iterate text array and check if it does not contains 'flash', if yes then return text[index] else return '-'
				for (let i = 0; i < record.campaignDisplayName.length; i++) {
					if (record.campaignDisplayName[i] && !record.campaignDisplayName[i].toLowerCase().includes('flash')) {
						return {
							children: <div>₹{text[i]}<br></br>{moment(record.payoutDate[i]).format('DD MMM YYYY')}</div>
						}
					}
				}
				return {
					children: <div>-</div>
				}
			}
		},

		{
			title: 'M2',
			dataIndex: 'milestoneAchieved',
			key: 'milestoneAchieved',
			render(text, record) {
				if (!Array.isArray(text)) {
					text = [text];
				}
				let occurence = 0;
				// iterate text array and check if it does not contains 'flash', if yes then return text[index] else return '-'
				for (let i = 0; i < record.campaignDisplayName.length; i++) {
					if (record.campaignDisplayName[i] && !record.campaignDisplayName[i].toLowerCase().includes('flash')) {
						if (occurence == 1) {
							return {
								children: <div>{text[i]}</div>
							}
						}
						else occurence++;
					}
				}
				return {
					children: <div>-</div>
				}
			}
		},
		{
			title: 'M2 Payout',
			dataIndex: 'amount',
			key: 'amount',
			render(text, record) {
				if (!Array.isArray(text) || !Array.isArray(record.campaignDisplayName)) {
					text = [text];
					record.campaignDisplayName = [record.campaignDisplayName];
				}
				let occurence = 0;
				// iterate text array and check if it does not contains 'flash', if yes then return text[index] else return '-'
				for (let i = 0; i < record.campaignDisplayName.length; i++) {
					if (record.campaignDisplayName[i] && !record.campaignDisplayName[i].toLowerCase().includes('flash')) {
						if (occurence == 1) {
							return {
								children: <div>₹{text[i]}<br></br>{moment(record.payoutDate[i]).format('DD MMM YYYY')}</div>
							}
						}
						else occurence++;
					}
				}
				return {
					children: <div>-</div>
				}
			}
		},
		{
			title: 'Flash Milestone',
			dataIndex: 'milestoneAchieved',
			key: 'milestoneAchieved',
			render(text, record) {
				let flashIndex = record.campaignDisplayName.findIndex(item => item.toLowerCase().includes('flash'));
				return {
					children: flashIndex != -1 ? <div>{text[flashIndex]}</div> : <div>--</div>

				}
			}
		},
		{
			title: 'Flash Payout ',
			dataIndex: 'amount',
			key: 'amount',
			render(text, record) {
				let flashIndex = record.campaignDisplayName.findIndex(item => item.toLowerCase().includes('flash'));
				return {
					children: flashIndex != -1 ? <div>₹{text[flashIndex]}<br></br>{moment(record.payoutDate[flashIndex]).format('DD MMM YYYY')}</div> : <div>--</div>

				}
			}
		},
		// {
		// 	title: 'Milestone Achieved',
		// 	dataIndex: 'milestoneAchieved',
		// 	key: 'milestone',
		// 	render(text,record) {
		// 		console.log("text: from milestone Achieved>>",text)
		// 		let textToDisplay = '';
		// 		if(text && Array.isArray(text)){
		// 		// unique values in text to textToDisplay
		// 		text = [...new Set(text)];
		// 		text.map((item, index)=>{
		// 			if(index === text.length-1){
		// 				textToDisplay = textToDisplay + item
		// 			}else{
		// 				textToDisplay = textToDisplay + item + ', '
		// 			}
		// 		})
		// 		}
		// 		return {
		// 			children: <div 	>{textToDisplay}</div>
		// 		}
		// 	}
		// },
		// {
		// 	title: 'Total Trips',
		// 	dataIndex: 'totalTrips',
		// 	key: 'totalTrips',
		// 	render(text,record) {
		// 		return {
		// 			children: <div >{text}</div>
		// 		}
		// 	}
		// },
		{
			title: 'Total Payout ',
			dataIndex: 'amount',
			key: 'amount',
			render(text, record) {
				let total = 0;
				if (text && Array.isArray(text)) {
					text.map((item) => {
						total = total + item
					})
					record.arrear.map((item) => {
						total = total + (item.newAmount ? item.newAmount : 0)
					})
				}
				return {
					children: <div><b>₹{total}</b></div>
				}
			}
		},
		((history?.location?.state?.payload?.tag) ? {
			title: 'Expiry Date',
			dataIndex: 'expiryDate',
			key: 'expiryDate',
			render(text, record) {
				return {
					children: record.expiryDate ? <div >{moment(text).format('DD MMM YYYY')}</div> : '-'
				}
			}
		} : {}
			// {
			// 	title: 'Credited On',
			// 	dataIndex: 'payoutDate',
			// 	key: 'payoutDate',
			// 	render(text,record) {
			// 		// check if text is array or not
			// 		if(Array.isArray(text)){

			// 		console.log("text:>>",text)
			// 		let textToDisplay = '';
			// 		// unique values in text to textToDisplay
			// 		//text = [...new Set(text)];
			// 		text.map((item, index)=>{
			// 			if(index === text.length-1){
			// 				textToDisplay = textToDisplay + moment(item).format('DD MMM YYYY')
			// 			}else{
			// 				textToDisplay = textToDisplay + moment(item).format('DD MMM YYYY') + ', '
			// 			}
			// 		})
			// 		return {
			// 			children: <div 	>{textToDisplay}</div>
			// 		}
			// 		}
			// 		else{
			// 		return {
			// 			children: record.payoutDate ? <div >{moment(text).format('DD MMM YYYY')}</div>:'-'
			// 		}
			// 	}
			// 	}
			// }
		),

	] :
		[
			{
				title: 'Name',
				dataIndex: 'firstName',
				key: 'fname',
				render(text, record) {
					return {
						children: <div>{record.lastName !== 'Unknown' ? record.firstName + ' ' + record.lastName : record.firstName}</div>
					}
				}
			},
			{
				title: 'Mobile Number',
				dataIndex: 'phoneNumber',
				key: 'phoneNumber'
			},
			...(history.location?.state?.payload?.label == 'A. Unit Payouts' ? [{
				title: 'Client Name',
				dataIndex: 'companies',
				key: 'client',
				render(text, record) {
					return {
						// props: {
						// style: { background: parseInt(text) > 50 ? "red" : "green" }
						// },
						children: <div>{text}</div>
					};
				}
			}] : []),
			{
				title: 'Campaign',
				dataIndex: 'campaignDisplayName',
				key: 'campaign',
				render(text, record) {
					return {
						// props: {
						// style: { background: parseInt(text) > 50 ? "red" : "green" }
						// },
						children: <div>{text}</div>
					};
				}
			},
			{
				title: 'Milestone Achieved',
				dataIndex: 'milestoneAchieved',
				key: 'milestone',
				render(text, record) {
					return {
						children: <div 	>{text}</div>
					}
				}
			},
			{
				title: 'Total Trips',
				dataIndex: 'totalTrips',
				key: 'totalTrips',
				render(text, record) {
					return {
						children: <div >{text}</div>
					}
				}
			},
			{
				title: 'Payout',
				dataIndex: 'amount',
				key: 'amount',
				render(text, record) {
					return {
						children: <div>₹ {record.amount} <br />{record.payoutDate ? moment(record.payoutDate).format('DD MMM YYYY') : ''}</div>
					}
				}
			},
			{
				title: 'Transition Arrear',
				dataIndex: 'arrear',
				key: 'arrear',
				render(text, record) {
					return {
						children: record.arrear ? <div >{'₹ ' + JSON.parse(text).newAmount}<br />{moment(JSON.parse(text).payoutDate).format('DD MMM YYYY')}</div> : '-'
					}
				}
			},
			...((history?.location?.state?.payload?.tag) ? [{
				title: 'Expiry Date',
				dataIndex: 'expiryDate',
				key: 'expiryDate',
				render(text, record) {
					return {
						children: record.expiryDate ? <div >{moment(text).format('DD MMM YYYY')}</div> : '-'
					}
				}
			}] : [
				// 	{
				// 	title: 'Credited On',
				// 	dataIndex: 'payoutDate',
				// 	key: 'payoutDate',
				// 	render(text,record) {
				// 		return {
				// 			children: record.payoutDate ? <div >{moment(text).format('DD MMM YYYY')}</div>:'-'
				// 		}
				// 	}
				// }
			]),
			{
				title: 'Total Payout',
				dataIndex: 'amount',
				key: 'amount',
				render(text, record) {
					let total = text;
					total = total + (record.arrear ? JSON.parse(record.arrear).newAmount : 0)
					return {
						children: <div><b>₹{total}</b></div>
					}
				}
			},

		];


	useEffect(() => {
		if (mitraReducer && mitraReducer.allowedFeatures) {
			let viewAccessRestricted = mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.isEnable
			if (viewAccessRestricted) {
				let temp_var = moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days')
				console.log("DD", temp_var.format("YYYY-MM-DD"));
				setDisableDatesBefore(moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days'))
			}
		}
	}, [mitraReducer])

	const getRequestPayload = (searchKey = '') => {
		if (locationList.length > 0 && locationList.findIndex((item) => item.value) > -1) {
			trackEvent('clicked_city_filter_performance_leads_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		}
		const queryParams = new URLSearchParams(location.search);
		const startDateParam = queryParams.get('startDate') ? queryParams.get('startDate') : startDate;
		const endDateParam = queryParams.get('endDate') ? queryParams.get('endDate') : endDate;

		let dataReq = {
			startDate: moment(startDateParam).format("YYYY-MM-DD"),
			endDate: moment(endDateParam).format("YYYY-MM-DD"),
			clients: JSON.stringify(commonFunc.getFilteredValue(clientFilterList, "label")),
			statuses: JSON.stringify(commonFunc.getFilteredValue(statusesListForFilters, 'key')),
			offset: currentPage * pageLimit - pageLimit,
			limit: pageLimit,
			search: searchKey,
			latestMilestonesFilter: JSON.stringify([mappingStatus(tabParam)]),
			locationFilter: JSON.stringify(commonFunc.getFilteredValue(locationList, 'label')),
			teamMemberIds: JSON.stringify(commonFunc.getFilteredValue(teamList, 'id')),
			typeOfLead: typeOfLead,
			secondarySearchKey: secondarySearchKey === 'false' ? null : secondarySearchKey ,
			mitraAppDownloadCategory: ['Pre Onboarding', 'Within 3 days of Onboarding', 'After 3 days of Onboarding', 'Activated but App Not Downloaded', 'Total Vahan App Downloads'].indexOf(tabParam) > -1 ? mappingStatus(tabParam) : null,
		}
		return dataReq
	}

	useEffect(() => {
		let zomatoRequiredMilestoneKeys = ["p3AppDownloadedButNoSectionFilled", "p2Only1or2SectionsFilled", "p1Upto3or4SectionsFilled", "p0Upto5SectionsFilled", "obFeesPaid"]
		if(mitraReducer?.mitraInfo){
            setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
            setMitra(mitraReducer?.mitraInfo ? mitraReducer?.mitraInfo : {})
            trackEvent('reports_section_visited',{},CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
        }
		if(mitra?.id){
			if(!history.location?.state?.payoutname){
				if(!mitraReducer?.reportParams?.teamList){
					getMitraTeamMembersList().then(response => {
						let list = []
						// list.push({ name: 'Entire Team', id: mitra.id })
						list.push(mitra)
						for (let user in response.data.data) {
							list.push(response.data.data[user]);
						}

						if (selectedTeamMember != 0) {
							list[selectedTeamMember - 1].value = true;
						}
						setTeamList(list);
					})
				}
				if (!mitraReducer?.reportParams?.locationList) {
					getLocationForFilter().then(response => {
						setLocationList(response.data.locationList)
					})
				}

				if (!mitraReducer?.reportParams?.clientList) {
					getActiveClientsForFilter().then(data => {
						setClientFilterList(data.data.activeClients)
					})
				}
				getStatusListForFilters().then(data => {
					let filteredStatuses = data.data.statuses.filter(status => {
						return !zomatoRequiredMilestoneKeys.includes(status.key);
					});
					setStatusesListForFilters(filteredStatuses)
				})
			}
		}
		setLeadTableColumn(leadsTableColumns);
	}, [mitraReducer, mitra]);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const startDateParam = queryParams.get('startDate');
		const endDateParam = queryParams.get('endDate');
		if (startDateParam) {
			// If the 'startDate' parameter exists in the URL, assign its value to the state variable
			setStartDate(moment(startDateParam, 'YYYY-MM-DD'));
		}
		if (endDateParam) {
			// If the 'startDate' parameter exists in the URL, assign its value to the state variable
			setEndDate(moment(endDateParam, 'YYYY-MM-DD'));
		}

		pageLoadFunc();
		console.log("TAB_PARAM", tabParam)
	}, [location.search, currentPage, triggerLoad]);


	const pageLoadFunc = (searchText = '') => {
		setLoaded(false);
		if (!history?.location?.state?.payoutname) {
			getPerformanceDashboardCandidateList({ ...getRequestPayload(searchText) }).then((resp) => {
				setLoaded(true)
				setLeads(resp.data.result);
				if (currentPage === 1) setTotalApplicationCount(Number(resp.data.count));
			})
		} else {
			setBreadcrumb(
				[
					{ link: '/report/payout-dashboard', label: 'Report' },
					{ link: '/report/payout-dashboard', label: 'Payout Dashboard' },
					{ link: '', label: (history.location?.state?.expiringLeadsLabel) ? history.location?.state?.expiringLeadsLabel : history.location?.state?.payoutname }
				]);
			getReportPayoutLeadsList({ ...history.location?.state?.payload, offset: (currentPage - 1) * 50 }).then(response => {
				try {

					if (history.location?.state?.payoutname === 'Base Pay') {
						const adjustColumnPushed = []

						for (const application of response.data.leads) {
							for (let [ind, iterator] of application.arrear.entries()) {
								application.arrear[ind] = application.arrear[ind] ? JSON.parse(iterator) : '';
								if (!adjustColumnPushed.includes(ind) && iterator) {
									leadsTableColumns.push({
										title: `Transition Arrear ${ind + 1}`,
										dataIndex: 'arrear',
										key: 'arrear',
										render(text, record) {
											return {
												children: record.arrear[ind] ? <div >{'₹ ' + record.arrear[ind].newAmount}<br />{moment(record.arrear[ind].payoutDate).format('DD MMM YYYY')}</div> : '-'
											}
										}
									})
									adjustColumnPushed.push(ind);
								}
							}


						}
						const totalPayoutIndex = leadsTableColumns.findIndex((e) => { return e.title == 'Total Payout (In ₹)' })

						if (totalPayoutIndex > -1) {
							leadsTableColumns.push(leadsTableColumns[totalPayoutIndex]);
							leadsTableColumns.splice(totalPayoutIndex, 1)
						}
						setLeadTableColumn(leadsTableColumns)

					}
				} catch (error) {
					console.log('error :>> ', error);
				}

				setLeads(response.data.leads)
				if (currentPage === 1) setTotalApplicationCount(Number(response.data.count));
				setLoaded(true);
			}).catch(e => {
				history.push({
					pathname: '/report/payout-dashboard', state: {
						visibleAuth: true
					}
				})
			});
		}
	}

	useEffect(() => {
		console.log("open true", filterSider);

	}, [filterSider]);

	const deleteChip = (type, value) => {
		if (type === 'client') {
			const currenArray = [...clientFilterList]
			currenArray.map(ele => {
				if (ele.label === value) {
					ele.value = false;
				}
			})
			setClientFilterList(currenArray)
		}
		if (type === 'team') {
			const currenArray = [...teamList]
			currenArray.map(ele => {
				if (ele.name === value) {
					ele.value = false;
				}
			})
			setTeamList(currenArray)
		}
		if (type === 'status') {
			const currenArray = [...statusesListForFilters]
			currenArray.map(ele => {
				if (ele.key === value) {
					ele.value = false;
				}
			})
			setStatusesListForFilters(currenArray)
		}
		if (type === 'allStatus') {
			const currenArray = [...statusesListForFilters]
			currenArray.map(ele => {
				ele.value = false;
			})
			setStatusesListForFilters(currenArray)
		}
		else if (type === 'location') {
			let newCategory = [...locationList]
			newCategory.map((single_category, index) => {
				if (single_category.label === value.label) {
					single_category.value = false
				}
			})
			setLocationList(newCategory)
		}
		ontriggerLoad(!triggerLoad)
	}
	const searchCandidate = (e) => {

		if (history.location.state) {
			history.location.state.payload.search = e;
		}
		pageLoadFunc(e);
	}
	const applyFilterCondition = () => {
		let result = false;
		let arr = [clientFilterList, teamList, statusesListForFilters];
		arr.map((item) => {
			if (item) {
				for (let i = 0; i < item.length; i++) {
					if (item[i].value === true) {
						result = true;
						break;
					}
				}
			}
		})
		return result;
	}

	const submitLeadFilter = (lead) => {
		const data = new FormData();

		data.append('userId', selectedLead.id);
		data.append('oldPhoneNumber', currentJob.phoneNumber);
		data.append('userPhoneNumber', currentJob.phoneNumber);
		data.append('phoneNumber', alternateNumber);
		data.append('desiredRole', currentJob.jobType);
		data.append('jobId', currentJob.jobId);
		data.append('sourceName', 'mitra-leader');
		data.append('jobDemandId', currentJob.jobDemandId);
		data.append('sourceId', currentJob.mitraId);
		data.append('name', currentJob.name);
		data.append('clientPreference', currentJob.companyName);
		data.append('cityPreference', currentJob.companyCity);
		data.append('role', currentJob.role);
		data.append('companyCity', currentJob.companyCity);
		data.append('managerMitraID', mitra.managerMitraID ? mitra.managerMitraID : currentJob.mitraId);
		setAlert({
			display: true,
			text: 'Trying to refer with other number....'
		});
		postReferAgain(data).then((response) => {
			if (!response.data.status) {
				let message = response.data.message ? response.data.message : 'Sorry, try another number.';
				setAlert({
					display: true,
					text: message,
					type: 'error'
				});
			} else {
				setAlert({
					display: true,
					text: 'successfully referred!!',
					type: 'success'
				});

				setReferAgain(false);
			}
		});
		setReferAgain(false);
	}

	const onsubmit = () => {
		setLoaded(false)
		getPerformanceDashboardCandidateList(getRequestPayload()).then((resp) => {
			setLoaded(true)
			setLeads(resp.data.result);
			setTotalApplicationCount(Number(resp.data.count));
		})
		setfilterSider(false)
	}

	function handleSheet(email) {
		setDownloadLoading(true)
		if (!history.location?.state?.payoutname) {
			let payload = getRequestPayload();
			payload = {
				...payload,
				email: email,
				changeEmail: changeEmail
			}
			DownloadPerformanceDashboardCandidatePageData(payload).then(response => {
				if (response.data.responseCandidatesUrl) {
					window.open(response.data.responseCandidatesUrl, '_blank')
				}
				else {
					setAlert({
						display: true,
						text: response.data.message,
						type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
					});
				}
				setDownloadLoading(false)
			})
		}
		else {
			trackEvent('downloaded_sheet_candidates_list_payout_dash', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			setSpin({
				loading: true, //false or true when actived
				delay: null,
				tip: 'Downloading...'  //Loading copy here
			});
			downloadPayoutLeads(history.location?.state?.payload).then(response => {
				setSpin({
					loading: false, //false or true when actived
					delay: null,
				});
				if (response?.data?.data?.responseCandidatesUrl) {
					window.open(response?.data?.data?.responseCandidatesUrl, '_blank')
				}
				else {
					setAlert({
						display: true,
						text: 'No data Present',
						type: 'error'
					});
				}
				setDownloadLoading(false)
			}).catch(e => {
				history.push({
					pathname: '/report/payout-dashboard', state: {
						visibleAuth: true
					}
				})
				setDownloadLoading(false)
			})
		}
	}

	const handleDateRange = (dates, dateStrings) => {
		if (dates === null) {
			setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
			setEndDate(moment());
		}
		else {
			setStartDate(dates[0]);
			mitraDispatch({
				type: 'reportParams', value: {
					startDate: dates[0], endDate: dates[1], selectedClient: clientFilterList, selectedTeamMember, teamList, typeOfLead, locationList
				}
			})
			setEndDate(dates[1]);
			const { pathname, search } = history.location;
			const newSearch = new URLSearchParams(search);
			newSearch.set('startDate', moment(dates[0]).format("YYYY-MM-DD"));
			newSearch.set('endDate', moment(dates[1]).format("YYYY-MM-DD"));
			console.log("NEW_SEARCH", newSearch.toString())
			history.push(`${pathname}?${newSearch.toString().replaceAll("+", "%20")}`);
		}
	}

	const onTabClick = async (e) => {
		const { pathname, search } = history.location;
		const delimiterIndex = search.indexOf("&");
		// Extract the params after the first & in the URL to get the additional params apart from the tab name
		const substringAfter = delimiterIndex !== -1 ? search.substring(delimiterIndex + 1) : '';
		history.push({
			pathname: '/leads-page',
			search: `${arrStatus[e]}&${substringAfter}`
		});
		setLoaded(false)
		setBreadcrumb([{ link: '/report/performance-dashboard', label: 'Report' }, { link: '/report/performance-dashboard', label: 'Performance Dashboard' }, { link: '', label: arrStatus[e] }])
		setTotalApplicationCount(0)
		setTabParam(arrStatus[e])
		deleteChip("allStatus")
		ontriggerLoad(!triggerLoad)
	}

	const onClickTags = (label, index) => {
		if (label === "Select Client") {
			clientFilterList[index].value = !clientFilterList[index].value;
		}
		if (label === "Select Team Member") {
			teamList[index].value = !teamList[index].value;
		}
		if (label === "Select Current Status") {
			statusesListForFilters[index].value = !statusesListForFilters[index].value;
		}
		if (label === 'Location') {
			locationList[index].value = !locationList[index].value;
		}
		forceUpdate()
	}

	const clearAll = async () => {
		await clientFilterList.map(item => {
			item.value = false;
		})
		await teamList.map(item => {
			item.value = false;
		})
		ontriggerLoad(!triggerLoad)
		setfilterSider(false)
	}

	const mappingStatus = (status) => {
		status = status.replace('FOOD', '').replace('INSTAMART', '').trim();
		status = status.replace('MKT', '').replace('ECOM', '').trim();
		let mappingObj = {
			'Action Needed Referrals': 'incomplete',
			'Completed referrals': 'leadReferred',
			'Unique Candidates': 'unique',
			'F10 ~ First 10 Trips': 'completed10thTrip',
			'F1 ~ First Trip': 'completed1stTrip',
			'F5 ~ First 5 Trips': 'completed5thTrip',
			'F15 ~ First 15 Trips': 'completed15thTrip',
			'F20 ~ First 20 Trips': 'completed20thTrip',
			'F25 ~ First 25 Trips': 'completed25thTrip',
			'F30 ~ First 30 Trips': 'completed30thTrip',
			'F35 ~ First 35 Trips': 'completed35thTrip',
			'F40 ~ First 40 Trips': 'completed40thTrip',
			'F45 ~ First 45 Trips': 'completed45thTrip',
			'F50 ~ First 50 Trips': 'completed50thTrip',
			'F60 ~ First 60 Trips': 'completed60thTrip',
			'F75 ~ First 75 Trips': 'completed75thTrip',
			'F80 ~ First 80 Trips': 'completed80thTrip',
			'F100 ~ First 100 Trips': 'completed100thTrip',
			'F200 ~ First 200 Trips': 'completed200thTrip',
			'Activations': 'activated',
			'Pre Onboarding': 'mitra_app_downloads_before_onboarding',
			'Within 3 days of Onboarding': 'mitra_app_downloads_within_3_days_of_onboarding',
			'After 3 days of Onboarding': 'mitra_app_downloads_after_3_days_of_onboarding',
			'Activated but App Not Downloaded': 'activated_but_not_downloaded_mitra_app',
			'Total Vahan App Downloads': 'total_mitra_app_downloads',
			"Partially Approved": "Partially Approved",
			"Rejected": "Rejected",
			"Approved within 3 days from FT": "Approved at FT Count",
			"Approved at FT Count": 'Approved at FT Count'
		}
		return mappingObj[status];
	}

	const disableMappingStatus = () => {
		let indexArr = [];
		switch (mappingStatus(tabParam)) {
			case 'incomplete':
				indexArr = statusesListForFilters.filter((item, index) => {
					item.disabledFilter = true
					return item
				})
				break;

			case 'leadReferred':
				indexArr = statusesListForFilters.map((item) => {
					item.disabledFilter = false
					return item
				})
				break;

			case 'unique':
				indexArr = statusesListForFilters.filter((item, index) => {
					if (index <= 1) {
						item.disabledFilter = true
					} else {
						item.disabledFilter = false
					}
					return item
				})
				break;

			case 'completed25thTrip':
				indexArr = statusesListForFilters.map((item, index) => {
					if (index <= 6) {
						item.disabledFilter = true
					} else {
						item.disabledFilter = false
					}
					return item
				})
				break;

			case 'completed1stTrip':
				indexArr = statusesListForFilters.map((item, index) => {
					if (index <= 3) {
						item.disabledFilter = true
					} else {
						item.disabledFilter = false
					}
					return item
				})
				break;

			case 'completed10thTrip':
				indexArr = statusesListForFilters.map((item, index) => {
					if (index <= 4) {
						item.disabledFilter = true
					} else {
						item.disabledFilter = false
					}
					return item
				})
				break;

			case 'activated':
				indexArr = statusesListForFilters.map((item, index) => {
					if (index <= 2) {
						item.disabledFilter = true
					} else {
						item.disabledFilter = false
					}
					return item
				})
				break;
			default:
				break;
		}
		setStatusesListForFilters(indexArr);
		forceUpdate()
	}

	const preventDefault = (e, label, item) => {
		e.preventDefault()
		deleteChip(label, item)
	}

	const fetchDisabledDates = (current) => {
		//Disable all dates before allowed number of days mentioned in View Data Access 
		return current && current < disableDatesBefore.endOf("day");
	}

	const commonTag = (label, arrObj) => {
		return <div className='tag-containers'>
			<div>{label}</div>
			{label === "Date Range" ?
				<div>
					<RangePicker
						defaultValue={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
						value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
						format={dateFormat}
						onChange={handleDateRange}
						ranges={{
							Today: [moment().startOf('day'), moment().endOf('day')],
							'This Month': [moment().startOf('month'), moment().endOf('month')],
						}}
						allowClear={false}
						className="range-picker-leads"
						disabledDate={fetchDisabledDates}
					/>
				</div> :
				label === "Location" ?
					<LocationFilterComponent
						locationList={locationList}
						onClickTags={onClickTags}
						label={label}
						setLocationList={setLocationList}
					/> :
					<div className='tag-wrapper-container'>
						{arrObj.map((item, index) => {
							return <Tag className={!item.value ? 'tags' : 'tags tag-active'}
								key={index}
								title={item.disabledFilter ? `Field is disabled for this status` : null}
								onClick={!item.disabledFilter ? () => { onClickTags(label, index) } : null}
								style={item.disabledFilter ? { backgroundColor: '#F0F0F0' } : null}
							>
								<Text delete={item.disabledFilter ? true : false}>	{item.label ? label === "Select Client" ? getDisplayableClientName(item.label) : item.label : item.name ? label === "Select Client" ? getDisplayableClientName(item.text) : item.name : null} </Text>
							</Tag>
						})}
					</div>
			}

		</div>
	}

	const onPhoneNumberChange = async (e) => {
		if (e.target.value.length > 10) {
			setAlert({
				display: true,
				text: 'Please enter only the 10 digit mobile no. without any prefix (+91 or 0)',
				type: 'error'
			});
			setSubmitdisable(true);
			return;
		}
		else {
			const alternatePhone = e.target.value;
			const regex = new RegExp("^[0-9]{10}$");
			if (regex.test(alternatePhone)) {
				setAlternateNumber(alternatePhone);
				setSubmitdisable(false);
			} else {
				setSubmitdisable(true);
			}

		}

	}

	const handleDownload = async () => {
		setShowVerifyEmailPopup(true);
	}

	const leadMapping = () => {
		return <div className="leads-wrapper">
			<Space className="date-download">

				<Text>
					{!history.location?.state?.payoutname && `on ${moment(startDate).format("DD MMM YYYY")} to ${moment(endDate).format("DD MMM YYYY")}`}

					{history.location?.state?.expiringLeadsLabel ?
						`${history.location?.state?.expiringLeadsLabel} - ${history.location?.state?.payload?.month} ${history.location?.state?.payload?.year}` :
						history.location?.state?.payoutname && `${history.location?.state?.payoutname} - ${history.location?.state?.payload?.month && history.location?.state?.payload?.year ? moment(`${history.location?.state?.payload?.month}-${history.location?.state?.payload?.year}`, 'MM-YYYY').format('MMM-YYYY') : ''}`}
				</Text>
				{isAdmin && <Button danger loading={downloadLoading} className="download-btn" onClick={() => { handleDownload() }}>
					Download Sheet
				</Button>}
			</Space>
			{downloadLoading ? <div className='tagBarClass' style={{ fontSize: '15px' }} color="default">
				<WarningOutlined /><span style={{ marginLeft: '5px' }}> Please do not hit back or refresh. Your file is being prepared.
				</span></div> : null}

			{loaded ?
				!leads.length ? (
					<Empty
						description={
							<span>
								Current selection does not have any data.
								<button
									onClick={() => history.push('/add-single-candidate')}
									style={{ textDecoration: 'underline', color: 'red', fontWeight: '600' }}>
									Refer Candidate</button>
							</span>
						} />
				) : history?.location?.state?.payload?.dashboard === 'payout-dashboard' ? (
					<div className="all-leads-payout-table-wrapper">
						<Table
							columns={leadTableColumn}
							dataSource={leads}
							pagination={false}
							scroll={{ x: true }}
						/></div>
				) : (
					leads.map((lead, index) => (
						<Lead
							key={index}
							lead={lead}
							setShowDetail={setShowDetail}
							setSelectedLead={setSelectedLead}
							setSpin={setSpin}
							mitra={mitra}
							setReferAgain={setReferAgain}
							setCurrentJob={setCurrentJob}
							setSelectedScheduledInterviewId={setSelectedScheduledInterviewId}
							setSelectedRecommendationHistoryId={setSelectedRecommendationHistoryId}
						/>
					))




				) : <Skeleton active />}
		</div>
	}

	return (
		<>
			<div className="ReportPage mainContentZ">
				<div className="containerTop">
					<div className="header">
						<>
							<PageHeaderComponent backBtnBool={true} breadcrumb={breadcrumb} />
							<div className="searchPage"><Search className='desktop-version' placeholder={'Search Candidate '} allowClear onSearch={searchCandidate} enterButton /> </div>
							{/* <SearchComponent placeholder={"Search by Name, Phone Number"} searchList={searchCandidate}/> */}
							{/* <Button onClick={()=>{
								history.push("/search-page")
							}} style={{color:'#D34612',display:'flex',alignItems:'center'}} icon={<SearchOutlined />}  danger ghost size={'large'} >Search</Button> */}
						</>
					</div>

				</div>
				<div className="more-filters">
					<div className="filter-set-container">
						<Space style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
							{!history.location?.state?.payoutname &&
								<Button style={applyFilterCondition() ? { color: '#D34612', backgroundColor: '#FFEAE0', border: '1px solid #D34612' } : null} onClick={() => { disableMappingStatus(); setfilterSider(true) }} icon={<FilterOutlined />}>Apply Filters</Button>
							}
							<Anchor>
								{totalApplicationCount > 0 &&
									<Pagination
										total={totalApplicationCount > 0 && totalApplicationCount}
										showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
										defaultPageSize={50}
										defaultCurrent={currentPage}
										responsive={true}
										showLessItems={true}
										onChange={(page, pagesize) => { setCurrentPage(page) }}
										// hideOnSinglePage={true}
										showSizeChanger={false}
									/>}
							</Anchor>

						</Space>

					</div>
				</div>
				<div className="Chips-container" style={{ padding: "0 20px" }}>
					{
						clientFilterList.map((ele, index) => {
							if (ele.value) {
								return <Tag key={index} className="single-chip" closable onClose={(e) => preventDefault(e, 'client', ele.label)}>{getDisplayableClientName(ele.label)} </Tag>
							}
						})
					}

					{
						isAdmin && teamList.map((ele, index) => {
							if (ele.value) {
								return <Tag key={index} className="single-chip" closable onClose={(e) => preventDefault(e, 'team', ele.label)}>{ele.label} </Tag>
							}
						})
					}
					{
						statusesListForFilters.map((ele, index) => {
							if (ele.value) {
								return <Tag key={index} className="single-chip" closable onClose={(e) => preventDefault(e, 'status', ele.key)}>{ele.label} </Tag>
							}
						})
					}
					{
						[...new Set(locationList)]?.map((single_status, index) => {
							if (single_status.value) {
								return <Tag key={index} className="single-chip" closable onClose={(e) => preventDefault(e, 'location', single_status)}>{single_status.label} </Tag>
							}
						})
					}
				</div>
				<div style={{ paddingRight: '24px' }}>
					{!history.location?.state?.payoutname && <Tabs onTabClick={(e) => {
						onTabClick(e);
					}} className='tab-view-report' defaultActiveKey={arrStatus.findIndex((status) => status === tabParam).toString()}>
						{arrStatus.map((item, i) => (
							<TabPane tab={item} key={i}>
								{leadMapping()}
							</TabPane>
						))}
					</Tabs>}
				</div>
				{history.location?.state?.payoutname && leadMapping()}
				<div className="bottom-detail-wrapper">
					<CandidateProfilePopupV2
						selectedScheduledInterviewId={selectedScheduledInterviewId}
						selectedRecommendationHistoryId={selectedRecommendationHistoryId}
						visible={showDetail}
						setShowDetail={setShowDetail}
						lead={selectedLead}
						setRaiseIssueSider={setRaiseIssueSider}
						setViewDetailLead={setViewDetailLead}
					></CandidateProfilePopupV2>
				</div>
			</div>
			{filterSider &&
				<SideSheet submit={{
					disabled: false
					, onClick: () => { onsubmit() }
				}}
					clearAll={{ disabled: false, onClick: () => { clearAll() } }}
					sidername={"Filter Leads"}
					onDismiss={() => { setfilterSider(false) }}>
					<div className='filter-home-wrapper'>
						{commonTag("Date Range", null)}
						{commonTag("Select Client", clientFilterList)}
						{isAdmin && commonTag("Select Team Member", teamList)}
						{commonTag("Select Current Status", statusesListForFilters)}
						{commonTag("Location", locationList)}
					</div>
				</SideSheet>
			}
			{referAgain &&
				<SideSheet submit={{ disabled: submitdisable, onClick: submitLeadFilter }} sidername='Add New Number' onDismiss={() => setReferAgain(false)}>
					<div className="siderInnerContainer1">
						<div className="outerContainer">
							<SideInput onChange={onPhoneNumberChange} placeholder={'Example : 8884228005'}
								imgsrc={'phone.svg'} labelname={"* Add Alternate Number"} />
							<SideInput readOnly={true} value={selectedLead.phoneNumber}
								imgsrc={'phone.svg'} labelname={"Contact Number"} />
						</div>
					</div>
				</SideSheet>
			}
			{raiseIssueSider &&
				<RaiseIssueSider
					closeSider={() => setRaiseIssueSider(false)}
					mitraReducer={mitraReducer}
					selectedLead={viewDetailLead}
				/>
			}
			{
				showVerifyEmailPopup &&
				(
					<VerifyEmailPopup mitra={mitra} visible={showVerifyEmailPopup} closePopup={setShowVerifyEmailPopup} downloadFunction={handleSheet} changeEmail={changeEmail} setChangeEmail={setChangeEmail}></VerifyEmailPopup>
				)
			}
		</>
	);
};

export default ReportLeadPage;
