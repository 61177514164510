import React, { useEffect, useState, useContext } from 'react';
import { logEvent } from '../../../service/tracking';
import trackingConstants from '../../../constants/trackingConstants';
import '../../../css/lead.scss';
import useGAEventsTracker from '../../../service/useGSEventsTracker';
import moment from 'moment';
import { AdditionalFormsURLEnum } from '../../../utils/utility';
import { useHistory } from 'react-router-dom';
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../../service/engagementMonitoringService';
import CONSTANTS from '../../../constants/constants';
import { getViewDetailsData } from './../../../service/leadService';
import {
  CloseCircleOutlined,
  PhoneOutlined,
  UploadOutlined,
  CheckCircleOutlined,
  WhatsAppOutlined,
  QuestionOutlined,
  FileSyncOutlined,
  InfoCircleOutlined,
  ArrowLeftOutlined
} from '@ant-design/icons';
import { Modal, Button, Timeline, Typography, Card, Tabs, Empty, Space, Divider, notification, Steps } from 'antd';
import { PlusOutlined, SyncOutlined, DownOutlined, UpOutlined, UserOutlined, EditOutlined } from '@ant-design/icons';
import { AppContext } from '../../../App';
import { getMitraPhoneNumberFromId, getRapidoDocumentStatuses } from '../../../service/commonService';
import WhatsappTemplateModal from '../../whatsappTemplateModal/WhatsappTemplateModal';
import { rollOutCallHistory, rollOutNewSupportForm, rollOutRecruitmentMilestoneVisibility } from '../../../constants/utils';
import CallHistoryCard from './../candidateProfilePopupV2Components/callHistory';
import { getCallHistory, getLeadFollowUpStatus, getRecentCallingNumbers } from '../../../service/kaleyraCallService';
import CallerNumberSelectionModal from '../../kaleyraCallComponents/callerNumberSelectionModal';
import CustomCallNotes from '../../customCallNotes';
import ReassignLeadsDialog from '../ReassignLeads/ReassignLeadsDialog';
import '../../../css/globalStyles.scss'

const { Text } = Typography;
const { TabPane } = Tabs;

const CandidateProfilePopupV2 = ({ visible, setShowDetail, lead, selectedScheduledInterviewId, source, selectedRecommendationHistoryId, setRaiseIssueSider, setViewDetailLead, reassignAccess = false, leadReassignedViaPopup = () => { }, page = "" }) => {
  const history = useHistory();
  // const [createMoengageUser, trackEvent] = useMoengage();
  const [leadDetails, setLeadDetails] = useState([]);
  const { mitraReducer, mitraDispatch } = useContext(AppContext);
  const [managerMitraPhoneNumber, setManagetMitraPhoneNumber] = useState();
  const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);
  const [callHistory, setCallHistory] = useState([]);
  const [showNotificationToKaleyraUser, setShowNotificationToKaleyraUser] = useState(false);
  const [showCallerSelectionModal, setShowCallerSelectionModal] = useState(false);
  const [callerNumberList, setCallerNumberList] = useState([]);
  const [isRefreshDocumentStatusButtonEnabled, setIsRefreshDocumentStatusButtonEnabled] = useState(true);
  const [isItemExpanded, setIsItemExpanded] = useState([
    { label: 'Vahan App Status', expanded: false, cursorStyle: 'default' },
    { label: 'Onboarding Form Filled (Form 2)', expanded: false, cursorStyle: 'default' },
    { label: 'Document Status', expanded: false, cursorStyle: 'default' },
    { label: 'Documents Status', expanded: false, cursorStyle: 'default' },
    { label: 'Form 2 Status', expanded: false, cursorStyle: 'default' },
    { label: 'BGV Check', expanded: false, cursorStyle: 'default' }

  ]);
  const [mitraAppStatusMessage, setMitraAppStatusMessage] = useState();
  const [latestMilestone, setLatestMilestone] = useState({})
  const [isBronzeML, setIsBronzeML] = useState(localStorage.getItem('isBronzeML'))
  const [showCallNoteSection, setShowCallNoteSection] = useState(false);
  const [currentRhId, setCurrentRhId] = useState();
  const [showReAssignmentSection, setShowReAssignmentSection] = useState(false);
  const [showReAssignmentSectionFooter, setShowReAssignmentSectionFooter] = useState(false);
  const [isLeadReassigned, setIsLeadReassigned] = useState(false);

  useEffect(() => {
    if (showCallNoteSection) {
      setShowReAssignmentSectionFooter(false)
    }
    else {
      setShowReAssignmentSectionFooter(true)
    }
  }, [showCallNoteSection])

  function onExpandItem(item) {
    const index = isItemExpanded.findIndex((el) => el.label === item);
    if (index !== -1) {
      const updatedExpanded = [...isItemExpanded];
      updatedExpanded[index].expanded = !updatedExpanded[index].expanded;
      setIsItemExpanded(updatedExpanded);
    }
  }

  const checkIfMitraAppDownloadIsRequired = (item) => {
    if (item?.companyName?.toLowerCase().includes('zepto') || item?.companyName?.toLowerCase().includes('zomato')
      || item?.companyName?.toLowerCase().includes('uber') || item?.companyName?.toLowerCase().includes('rapido')) {
      return true;
    } else {
      return false;
    }
  }

  function getMitraAppDownloadMessage(item) {
    if (!latestMilestone) return null;
    if (!checkIfMitraAppDownloadIsRequired(item)) return null;
    else if (item?.mitraAppDownloadCategory?.includes("before_onboarding") || item?.mitraAppDownloadCategory?.includes("within_3_days_of_activation")) {
      return `Great! App has been downloaded. You will now earn ₹${item.companyName.toLowerCase().includes("zepto") ? "200" : "100"} additional bonus`
    }
    else if (item?.mitraAppDownloadCategory?.includes("not_downloaded_after_3_days_of_activation")) {
      return `You will miss ₹${item.companyName?.toLowerCase().includes("zepto") ? "200" : "100"} bonus as app was not downloaded`
    }
    else if (item?.mitraAppDownloadCategory?.includes("after_3_days_of_activation")) {
      return `You will miss ₹${item.companyName?.toLowerCase().includes("zepto") ? "200" : "100"} bonus as app was downloaded after 3 days of Onboarding`
    }
    else if (!item?.mitraAppDownloadCategory) {
      return `You will miss ₹${item.companyName?.toLowerCase().includes("zepto") ? "200" : "100"} bonus if app is not downloaded before Onboarding`
    }
    else return null;
  }

  function getLatestTripMilestoneMessage(item) {
    if (item.companyName?.toLowerCase().includes('zepto')) {
      return null;
    }
    if (checkIfMitraAppDownloadIsRequired(item) && (item?.mitraAppDownloadCategory && (item?.mitraAppDownloadCategory?.includes('activated_but_not_downloaded') || item?.mitraAppDownloadCategory?.includes("not_downloaded_after_3_days_of_activation")) && latestMilestone?.toLowerCase().includes('trip'))) {
      return "30% deduction as app is not downloaded"
    }
    else if (checkIfMitraAppDownloadIsRequired(item) && (item?.mitraAppDownloadCategory?.includes("after_3_days_of_activation") && latestMilestone?.toLowerCase().includes('trip') && !item?.mitraAppDownloadCategory?.includes("not_downloaded_after_3_days_of_activation"))) {
      return "30% deduction as app is downloaded more than 3 days after Onboarding"
    }
    else return null;
  }

  function checkIsItemExpanded(item) {
    const foundItem = isItemExpanded.find((el) => el.label === item);
    return foundItem ? foundItem.expanded : false;
  }

  function checkCursorStyle(item) {
    const foundItem = isItemExpanded.find((el) => el.label === item);
    return foundItem ? foundItem.cursorStyle : 'pointer';
  }

  function setCursorStyle(item, cursorStyle) {
    const index = isItemExpanded.findIndex((el) => el.label === item);
    if (index !== -1) {
      const updatedExpanded = [...isItemExpanded];
      updatedExpanded[index].cursorStyle = cursorStyle;
      setIsItemExpanded(updatedExpanded);
    }
  }

  const closeCallerSelectionModal = () => {
    setShowCallerSelectionModal(false);
  }

  const fetchCallHistory = () => {
    let apiParams = {
      id: lead.id,
      recommendationHistoryId: currentRhId,
      mitraId: mitraReducer?.mitraInfo?.id
    };
    getCallHistory(apiParams).then((response) => {
      setCallHistory([...response.data]);
    }).catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    const leadIndex = lead?.["recommendationHistoriesId"]?.findIndex((id) => id === selectedRecommendationHistoryId?.[0]);
    let defaultLatestMilestone = lead?.["latestMilestone"]?.[leadIndex];

    if (lead?.companies?.[leadIndex]?.toLowerCase().includes("zepto") && (defaultLatestMilestone?.toLowerCase().includes("lead referred") || defaultLatestMilestone?.toLowerCase().includes("activated"))) // handling zepto
      defaultLatestMilestone = "Onboarding Form Filled (Form 2)"
    else if (lead?.["latestMilestone"]?.[leadIndex]?.toLowerCase().indexOf('lead referred') == -1)
      defaultLatestMilestone = lead?.["latestMilestone"]?.[leadIndex]?.toLowerCase().indexOf('unique') == -1 ? lead?.["latestMilestone"]?.[leadIndex] : (lead?.["latestMilestone"]?.[leadIndex]?.toLowerCase().indexOf('non unique') == -1 ? "Vahan App Status" : null);
    setLatestMilestone(defaultLatestMilestone);
    if (lead?.recommendationHistoriesId || lead?.scheduledInterviewId) {
      let data;
      if (selectedScheduledInterviewId?.length && selectedScheduledInterviewId[0]) {
        data = {
          // recommendationHistoryIds: lead.recommendationHistoriesId,
          scheduledInterviewId: selectedScheduledInterviewId,
        };
      } else if ((selectedRecommendationHistoryId?.length && selectedRecommendationHistoryId[0])) {
        data = {
          recommendationHistoryIds: selectedRecommendationHistoryId,
          // scheduledInterviewId: lead?.scheduledInterviewId,
        };
      } else {
        data = {
          recommendationHistoryIds: lead.recommendationHistoriesId,
          scheduledInterviewId: lead?.scheduledInterviewId,
        };
      }
      getViewDetailsData(data).then((result) => {
        setLeadDetails(result.data);
      });
    }

    let apiParams = {
      id: lead.id,
      recommendationHistoryId: selectedRecommendationHistoryId,
      mitraId: mitraReducer?.mitraInfo?.id
    };
    getCallHistory(apiParams).then((response) => {
      setCallHistory([...response.data]);
    }).catch((error) => {
      console.log(error);
    });

    if (mitraReducer?.mitraInfo && rollOutCallHistory(mitraReducer.mitraInfo) && source !== 'followUp') {
      getLeadFollowUpStatus(selectedScheduledInterviewId).then(res => {
        setShowNotificationToKaleyraUser(res.data.isFollowUp)
      }).catch(err => {
        console.log(err);
      })
    }
  }, [lead, !Object.keys(leadDetails).length]);

  useEffect(() => {
    if (mitraReducer?.mitraInfo?.managerMitraID) {
      getMitraPhoneNumberFromId(mitraReducer?.mitraInfo?.managerMitraID).then((res) => {
        setManagetMitraPhoneNumber(res.data.mitraPhoneNumber);
      });
    } else {
      setManagetMitraPhoneNumber(mitraReducer?.mitraInfo?.phoneNumber);
    }
  }, [mitraReducer?.mitraInfo?.phoneNumber]);

  const GAEventsTracker = useGAEventsTracker('Action buttons clicked');

  const checkAtRisk = (lead) => {
    let data = false;
    if (lead && lead.jobs) {
      lead.jobs.map((single_job) => {
        const DateObj = new Date();
        const differnceInDates =
          (DateObj.getTime() - Date.parse(single_job.status[0].StatusTimeStamp)) /
          (1000 * 3600 * 24);
        if (
          differnceInDates >= 7 &&
          differnceInDates <= 14 &&
          single_job &&
          single_job.displayInUI &&
          single_job.status &&
          single_job.status[0].title.toLowerCase() === 'activated' &&
          single_job.companyName &&
          (single_job.companyName.toLowerCase() === 'shadowfax' ||
            single_job.companyName.toLowerCase() === 'shadowfax_3pl')
        ) {
          data = true;
        }
      });
    }
    return data;
  };
  const editViewApplicationClick = (item, eventName) => {
    const paramObject = {
      name: item.lastName.includes('Unknown')
        ? item.firstName
        : item.firstName + ' ' + item.lastName,
      phoneNumber: item.phoneNumber,
      userId: item?.userId,
      scheduledInterviewId: item.id,
      companyName: item.companyName,
      jobId: item.jobId,
      jobDemandId: item.jobDemandId,
      companyCity: item.companyCity,
      ...(item.companyName.toLowerCase().indexOf('rapido') !== -1
        ? {
          candidateId: item.siMetaData
            ? item.siMetaData.rapidoMilestone
              ? item.siMetaData.rapidoMilestone.captainId
              : null
            : null,
        }
        : {}),
      meta: JSON.stringify(item.siMetaData),
    };
    viewAdditionalForm(paramObject, eventName, { candidate_phone_number: lead.phoneNumber });
  };
  const viewAdditionalForm = (lead, event, obj) => {
    trackEvent(event, obj, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    // localStorage.setItem('lead', JSON.stringify(lead))

    if (lead?.companyName?.toLowerCase().indexOf('zomato') !== -1) {
      history.push({
        pathname: '/zomato-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search: new URLSearchParams(lead).toString(),
      });
    } else if (lead?.companyName?.toLowerCase().indexOf('blinkit') !== -1) {
      history.push({
        pathname: '/blinkit-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search: new URLSearchParams(lead).toString(),
      });
    } else if (lead?.companyName?.toLowerCase().indexOf('swiggy soc') !== -1) {
      history.push({
        pathname: '/swiggy-soc-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search: new URLSearchParams(lead).toString(),
      });
    } else if (lead?.companyName?.toLowerCase() === 'zepto') {
      history.push({
        pathname: '/zepto-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search: new URLSearchParams(lead).toString(),
      });
    } else if (lead?.companyName?.toLowerCase().indexOf('swiggy') !== -1) {
      history.push({
        pathname: '/swiggy-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search: new URLSearchParams(lead).toString(),
      });
    } else {
      history.push({
        pathname: '/moreInfo',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search: new URLSearchParams(lead).toString(),
      });
    }

  };

  const uploadOtherDocumentsClick = (item, eventName) => {
    const paramObject = {
      name: item.lastName.includes('Unknown')
        ? item.firstName
        : item.firstName + ' ' + item.lastName,
      phoneNumber: item.phoneNumber,
      scheduledInterviewId: item.id,
      companyName: item.companyName,
      jobId: item.jobId,
      jobDemandId: item.jobDemandId,
      companyCity: item.companyCity,
      meta: JSON.stringify(item.siMetaData),
    };
    viewOtherDocumentsForm(paramObject, eventName, { candidate_phone_number: lead.phoneNumber });
  };
  const viewOtherDocumentsForm = (lead, event, obj) => {
    trackEvent(event, obj, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    history.push({
      pathname: '/other-docs',
      search: new URLSearchParams(lead).toString(),
    });
  };

  const getLatestRapidoDocumentStatuses = (item) => {

    trackEvent(`refresh_doc_status_${item.companyName.toLowerCase()}_one_candidate`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);

    const paramObject = {
      phoneNumber: item.phoneNumber,
      scheduledInterviewId: item.id,
      captainId: item.siMetaData
        ? item.siMetaData.rapidoMilestone
          ? item.siMetaData.rapidoMilestone.captainId
          : null
        : null,
      metaData: item.siMetaData,
      isSingleCandidate: true,
      company: item.companyName,
    };

    getRapidoDocumentStatuses(paramObject).then((res) => {
      const data = res.data;
      if (data) {
        if (data.success) {
          setIsRefreshDocumentStatusButtonEnabled(false);
          notification['success']({
            message: data.message ? data.message : 'Document Status Refreshed!'
          });
          setLeadDetails({});
        }
        else {
          notification['error']({
            message: data.message ? data.message : 'Error while Refreshing Latest Document Status. Please try again after 5 minutes.'
          });
        }
      }
      else
        notification['error']({
          message: 'Error while Refreshing Latest Document Status. Please try again after 5 minutes.'
        });
    }).catch((err) => {
      notification['error']({
        message: 'Error while Refreshing Latest Document Status. Please try again after 5 minutes.'
      });
    });
  };

  const nonuniqueCheck = (item) => {
    let nonUnique = false;
    item.timeline.map((ele) => {
      if ((ele.label === 'Candidate Uniqueness Check' && ele.value === 'Not Unique') || ele.label == 'Non Unique') {
        nonUnique = true;
      }
    });
    return nonUnique;
  }
  const getCombinedErrorMsg = (milestone) => {
    let documentStatuses = milestone.value;
    let documentUploadCompleted = milestone.documentUploadCompleted;
    let documentUploadStarted = milestone.documentUploadStarted;
    let documentUploadErrorMsg = '';
    let erroredDocuments = [];
    documentStatuses.forEach(statusMsg => {
      if (!statusMsg.toLowerCase().includes('success')) {
        erroredDocuments.push(statusMsg.split('-')[0].trim());
      }
    });

    if (erroredDocuments && erroredDocuments.length > 0) {
      documentUploadErrorMsg = `Oh ho! There are some isues with – ${erroredDocuments.join(', ')}. Kindly reupload`;
    } else if (documentUploadStarted && documentUploadCompleted) {
      documentUploadErrorMsg = 'Documents Accepted';
    } else if (documentUploadStarted && !documentUploadCompleted) {
      documentUploadErrorMsg = 'Processing documents, please check again in some time.';
    }
    return documentUploadErrorMsg;
  }

  const getChildItemStatus = (milestone, isNestedChild = false) => {
    let mitraAppStatus = milestone.value;
    console.log('mitraAppStatus :>> ', mitraAppStatus);
    if (milestone.label != 'Form 2 Status') {
      return <>
        {Object.keys(mitraAppStatus).map((status) => {
          return <div key={status}>
            <div style={{ display: 'flex', paddingLeft: isNestedChild ? '24px' : '2px' }}>
              <Text style={{ width: '140px' }}>{status}</Text>
              {
                milestone.jsonTypeKey && milestone.jsonTypeKey.length && milestone.jsonTypeKey.includes(status) ?
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {
                      Object.keys(mitraAppStatus[status]).map((ele, index) => {
                        return <>
                          <Text style={{ color: (mitraAppStatus[status][ele]?.toLowerCase()).includes('not') || mitraAppStatus[status][ele]?.toLowerCase()?.includes('incorrect') || mitraAppStatus[status] === 'Pending' ? '#C70039' : '#008000' }}>{ele}{(index < Object.keys(mitraAppStatus[status]).length - 1) ? ',' : null}</Text>
                        </>
                      })
                    }
                  </div>
                  :
                  <Text
                    style={{
                      color:
                        (mitraAppStatus[status]?.toLowerCase()).includes('not') ||
                          mitraAppStatus[status] === 'Pending' ? '#C70039' :
                          mitraAppStatus[status].toLowerCase().includes('after 3 days') ? 'red' : '#008000'
                    }}>
                    {mitraAppStatus[status]}
                  </Text>
              }
            </div>
          </div>
        })}
      </>;
    } else {
      return (
        <div style={{ display: 'flex', paddingLeft: '24px' }}>
          <Text style={{ width: '240px', color: (milestone.value?.toLowerCase()).includes('zepto') || milestone.value === 'Pending' ? '#C70039' : '#008000' }}>{milestone.value}</Text>
        </div>
      )
    }
  }

  const openSupportForm = () => {
    window.FreshworksWidget('open');
    window.FreshworksWidget('identify', 'ticketForm', {
      name: lead.firstName + ' ' + (lead.lastName === 'Unknown' ? '' : lead.lastName),
      email: mitraReducer?.mitraInfo?.email,
      group_id: 84000268346,
      custom_fields: {
        cf_your_registered_phone_number: mitraReducer?.mitraInfo?.phoneNumber,
        cf_manager_phone_number: managerMitraPhoneNumber ? managerMitraPhoneNumber : '',
        cf_candidate_phone_number: lead.phoneNumber,
        cf_candidate_city: lead.companyCity[0],
        cf_candidate_city642809: lead.companyCity[0],
        cf_client_name: (leadDetails.length && leadDetails[0].companyName) || ''
      },
    });
    window.FreshworksWidget('disable', 'ticketForm', [
      'name',
      'group_id',
      'custom_fields.cf_your_registered_phone_number',
      'custom_fields.cf_manager_phone_number',
      'custom_fields.cf_candidate_phone_number',
    ]);
    if (mitraReducer?.mitraInfo?.role === 'admin') {
      window.FreshworksWidget('hide', 'ticketForm', ['custom_fields.cf_manager_phone_number']);
    }
  };

  const onCloseDetailsPopup = () => {
    setShowDetail(false);
    window.FreshworksWidget('hide');
  };

  const whatsAppMessageModalCancel = () => {
    setWhatsAppMessageModal(false);
  };
  const onAddNotesClick = (recommendationHistoryId) => {
    trackEvent(
      'clicked_add_notes_decoupled_notes',
      {},
      CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS
    );
    setCurrentRhId(recommendationHistoryId);
    const userName = lead && lead.lastName.includes('Unknown')
      ? lead.firstName
      : lead.firstName + ' ' + lead.lastName;
    // history.push(`/custom-call-notes?userName=${userName}&userId=${lead.id}&rhId=${recommendationHistoryId}&phoneNumber=${lead.phoneNumber}`);
    setShowCallNoteSection(true);
  }

  const onKaleyraCallButtonClick = (lead, fromNumber) => {
    trackEvent(
      'clicked_call_button_follow_ups',
      {},
      CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS
    );
    const receiver = lead.parentPhoneNumber[0] ? lead.parentPhoneNumber[0] : lead.phoneNumber;
    mitraDispatch({ type: 'KaleyraCallDetails', value: { callerNumber: fromNumber, receiverNumber: receiver, currentLead: lead, currentSelectedSiId: selectedScheduledInterviewId } })
    // history.push(`/connecting-call`);

    getRecentCallingNumbers().then(res => {
      setCallerNumberList([...res.data, { id: 'custom', phoneNumber: '' }]);
      setShowCallerSelectionModal(true);
    }).catch(err => {
      console.log(err);
    });
  };

  const goToRecruiterDashboardNotification = () => {
    const placement = 'bottomRight';
    notification.open({
      message: 'Please visit the follow ups section to make this call.',
      duration: 0,
      placement,
      description: <Button type="primary" size="small" onClick={() => history.push(`/recruiter-candidates`)}>Take me</Button>
    })
  };

  const directCallFeature = () => {
    return (
      <a
        href={`tel:+91-${leadDetails.length > 0
          ? leadDetails[0].parentPhoneNumber
            ? leadDetails[0].parentPhoneNumber
            : leadDetails[0].phoneNumber
          : ''
          }`}
        onClick={(e) => {
          if (mitraReducer?.mitraInfo && rollOutCallHistory(mitraReducer?.mitraInfo) && source !== 'followUp' && showNotificationToKaleyraUser) {
            // if user has access to kaleyra call feature and 
            // view details popup has been accessed outside recruiter dashboard
            goToRecruiterDashboardNotification()
          }
          GAEventsTracker('CallCandidate');
          // checkActionTaken(lead, "Call now").then(response => console.log(response))
          logEvent(
            'CallCandidate',
            trackingConstants.EVENT_GROUP_VIEW_LEAD,
            trackingConstants.EVENT_SCREEN_CANDIDATE
          );
          trackEvent(
            'call_now_option_clicked',
            {},
            CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS
          );
        }}
      >
        <Button icon={<PhoneOutlined />} type="primary" className='d-flex align-items-center'>
          <Text style={{ color: '#fff' }}>Call Now</Text>
        </Button>
      </a>
    )
  };

  const kaleyraCallFeature = () => {
    return (

      <Button
        icon={<PhoneOutlined />}
        className='d-flex align-items-center'
        onClick={() => onKaleyraCallButtonClick(lead, mitraReducer.mitraInfo.phoneNumber)}
        type="primary">
        <Text style={{ color: '#fff' }}>Call Now</Text>
      </Button>
    )
  }

  const handleRaiseIssueOnClick = () => {
    trackEvent(
      'raised_issue_candidates_page_help',
      {},
      CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS
    );

    if (rollOutNewSupportForm(mitraReducer?.mitraInfo)) {
      setRaiseIssueSider(true);
      setShowDetail(false);
      const leadIndex = lead?.["recommendationHistoriesId"]?.findIndex((id) => id === selectedRecommendationHistoryId?.[0]);
      if (leadIndex !== -1) {
        const viewDetailLead = { ...leadDetails?.[0], latestMilestone: lead?.["latestMilestone"]?.[leadIndex], ujfmCreatedAt: lead?.["ujfmCompletedAt"]?.[leadIndex], mitraName: lead?.["mitraName"]?.[leadIndex] }
        setViewDetailLead(viewDetailLead);
      }
    }
    else
      openSupportForm()
  }

  const candidateInformation = () => {
    return (
      <div className="details-component-container">
        <div className="Contact-details-header">
          <div className="Candidate-details">
            {
              (leadDetails?.[0]?.ujfMetaData?.candidateLifetimeOrdersTrips || leadDetails?.[0]?.ujfMetaData?.["30dOrderCountSinceActivation"]) &&
              <div className='ride-details' style={{ marginBottom: '16px' }}>
                <div className="warning-message-at-risk-container">
                  <div className="warning-message-atrisk">
                    <InfoCircleOutlined style={{ color: '#D34612', margin: '5px 10px 0px 10px' }} />
                    Ride Count: {leadDetails?.[0]?.ujfMetaData?.candidateLifetimeOrdersTrips ? leadDetails?.[0]?.ujfMetaData?.candidateLifetimeOrdersTrips : leadDetails?.[0]?.ujfMetaData?.["30dOrderCountSinceActivation"]} Trips
                  </div>
                </div>
              </div>
            }
            <div className="head-details">
              <div className="name-number-container">
                <div className="lead-name">
                  {leadDetails.length > 0
                    ? leadDetails[0].lastName.includes('Unknown')
                      ? leadDetails[0].firstName
                      : leadDetails[0].firstName + ' ' + leadDetails[0].lastName
                    : ''}
                </div>
                <div className="lead-number">
                  {leadDetails.length > 0
                    ? leadDetails[0].parentPhoneNumber
                      ? `${leadDetails[0].parentPhoneNumber} (Edited)`
                      : leadDetails[0].phoneNumber
                    : ''}
                </div>
              </div>
              {/* <div className="user-image" >{leadDetails.length > 0 ? leadDetails[0].firstName.charAt(0).toUpperCase() : ''} */}
              {
                // lead ? lead.jobs ? checkAtRisk(lead) ? <div className="counter counter-lg"><InfoCircleOutlined /></div> : null : null : null
              }
              {/* </div> */}
              <div className="contact-buttons">
                {
                  mitraReducer && mitraReducer.mitraInfo && rollOutCallHistory(mitraReducer.mitraInfo) && source === 'followUp' ? kaleyraCallFeature() : directCallFeature()
                }

                <Button
                  onClick={(e) => {
                    GAEventsTracker('MessageCandidate');
                    setWhatsAppMessageModal(true);
                    logEvent(
                      'WhatsAppCandidate',
                      trackingConstants.EVENT_GROUP_VIEW_LEAD,
                      trackingConstants.EVENT_SCREEN_CANDIDATE
                    );
                    trackEvent(
                      'message_option_clicked',
                      {},
                      CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS
                    );
                  }}
                  icon={<WhatsAppOutlined style={{ color: '#fff' }} />}
                  style={{ backgroundColor: '#52C41A' }}
                  type="dashed"
                  className='d-flex align-items-center'
                >
                  <Text style={{ color: '#fff' }}>Message</Text>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ overflow: 'scroll', height: '100%' }}>
          {leadDetails.length > 0
            ? leadDetails.map((item, index) => {
              return (
                <Card
                  key={'card_profile_' + index}
                  style={
                    nonuniqueCheck(item)
                      ? { backgroundColor: '#F5F5F5', margin: '20px 0px' }
                      : { margin: '20px 0px' }
                  }
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Text>
                        <b>Call Note</b>
                      </Text>
                      <Button onClick={() => { onAddNotesClick(item.recommendationHistoryId) }} style={
                        { background: '#FFEAE0', border: '1px solid #D34612', display: 'flex', alignItems: 'center', padding: '1px 8px', width: '125px', height: '24px' }}>
                        <PlusOutlined style={{ color: '#D34612' }} />
                        <Text style={{ color: '#D34612' }}>
                          Add Remarks
                        </Text>
                      </Button>
                    </div>
                    <Divider style={{ margin: '10px 0px' }}></Divider>
                  </div>

                  <Timeline>
                    {item.timeline?.map((milestone, index) => {
                      if ((milestone.label === 'Vahan App Status') && item.companyName?.toLowerCase().indexOf('3pl') === -1) {
                        if (!rollOutRecruitmentMilestoneVisibility(mitraReducer?.mitraInfo))
                          return null;
                      }
                      let milestoneValue = milestone.value;
                      if (milestone.url) {
                        milestoneValue = (
                          <a href={milestone.url} rel="noreferrer" target="_blank">
                            {milestone.value}
                          </a>
                        );
                      }
                      return (
                        <Timeline.Item
                          key={'card_profile_' + milestone.label + index}
                          dot={
                            (milestone.completed) ? (
                              <CheckCircleOutlined />
                            ) : (
                              <CloseCircleOutlined />
                            )
                          }
                          color={milestone.completed ? (isBronzeML && (getLatestTripMilestoneMessage(item) && milestone?.label?.toLowerCase().includes(latestMilestone?.toLowerCase()) && !getLatestTripMilestoneMessage(item)?.toLowerCase().includes('great') && milestone?.label?.toLowerCase().includes('trip')) ? '#cccc00' : '#52C41A') : '#D34612'}
                        >
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              {
                                milestone.label === 'Vahan App Status' || milestone.label === 'Documents Status' || milestone.label == 'BGV Check' ? (
                                  <div>
                                    <div style={{ display: 'flex', cursor: checkCursorStyle(milestone.label) }}
                                      onMouseEnter={() => setCursorStyle(milestone.label, 'pointer')}
                                      onMouseLeave={() => setCursorStyle(milestone.label, 'default')}
                                      onClick={() => onExpandItem(milestone.label)}
                                    >
                                      <strong>{milestone.label === 'Vahan App Status' ? 'Vahan App Status' : milestone.label}</strong>
                                      <div style={{ marginLeft: '130px' }}>
                                        {checkIsItemExpanded(milestone.label) ? <UpOutlined /> : <DownOutlined />}
                                      </div>
                                    </div>
                                    <>
                                      {checkIsItemExpanded(milestone.label) ? getChildItemStatus(milestone) : null}
                                    </>
                                  </div>
                                ) :
                                  (milestone.label === 'Onboarding Form Filled (Form 2)')
                                    ? (
                                      <div>
                                        <div style={{ display: 'flex', cursor: checkCursorStyle(milestone.label) }}
                                          onMouseEnter={() => setCursorStyle(milestone.label, 'pointer')}
                                          onMouseLeave={() => setCursorStyle(milestone.label, 'default')}
                                          onClick={() => onExpandItem(milestone.label)}
                                        >
                                          <strong>{milestone.label}</strong>
                                          <div style={{ marginLeft: '130px' }}>
                                            {checkIsItemExpanded(milestone.label) ? <UpOutlined /> : <DownOutlined />}
                                          </div>
                                        </div>
                                        <>
                                          {checkIsItemExpanded(milestone.label) ?
                                            milestone.childItems.map((childItem, index) => {
                                              return (
                                                <div key={index} style={{ marginTop: '5px' }}>
                                                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px', cursor: checkCursorStyle(childItem.label) }}
                                                        onMouseEnter={() => setCursorStyle(childItem.label, 'pointer')}
                                                        onMouseLeave={() => setCursorStyle(childItem.label, 'default')}
                                                        onClick={() => onExpandItem(childItem.label)}
                                                      >
                                                        <strong style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>{(childItem.completed) ? (
                                                          <CheckCircleOutlined style={{ color: '#52C41A' }} />
                                                        ) : (
                                                          <CloseCircleOutlined style={{ color: '#D34612' }} />
                                                        )} {childItem.label}</strong>
                                                        <div style={{ marginLeft: '100px' }}>
                                                          {checkIsItemExpanded(childItem.label) ? <DownOutlined /> : <UpOutlined />}
                                                        </div>
                                                      </div>
                                                      <div>
                                                        {checkIsItemExpanded(childItem.label) ? getChildItemStatus(childItem, true) : null}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            })
                                            :
                                            <>
                                              {milestoneValue} {(milestone.date) ? ". " + moment(milestone.date).format('DD MMMM YYYY') : ''}</>
                                          }
                                        </>
                                      </div>
                                    )
                                    :
                                    (milestone.isMultipleExpiry) ?
                                      (
                                        <div>
                                          <strong>{milestone.label}</strong>
                                          <div>{milestone.label == 'Expiry Date' && Array.isArray(milestoneValue) ?
                                            milestoneValue.map(ele => { return <Text key={ele.milestone}> {ele.milestone} : {ele.expiryDate} <br /></Text> }) : milestoneValue}
                                          </div>
                                        </div>
                                      )
                                      :
                                      (milestone.date !== '' && milestone.date !== 'NO_DATE_UBER') && item.companyName.toLowerCase().indexOf('rapido') === -1 ? (
                                        <>
                                          <strong>{milestone.label}</strong>
                                          <div>
                                            {milestone.label == 'Document Upload Status' && Array.isArray(milestoneValue) ?
                                              <Text mark>{getCombinedErrorMsg(milestone)} <br /></Text>
                                              : milestoneValue
                                            }
                                            {
                                              milestone.label === 'Expiry Date' ?
                                                <p>{milestone.date} {milestone.previousDate ? `- ( Updated from ${milestone.previousDate} )` : null}</p>
                                                : `. ${moment(milestone.date).format('DD MMMM YYYY')}`
                                            }
                                          </div>
                                        </>
                                      ) :
                                        item.companyName.toLowerCase().indexOf('uber') !== -1 || item.companyName.toLowerCase().indexOf('rapido') !== -1 ? (
                                          <>
                                            <strong >{milestone.label}</strong>
                                            <div>{milestone.label == 'Document Upload Status' && Array.isArray(milestoneValue) ?
                                              milestoneValue.map(ele => { return (ele.toLowerCase().includes('approved')) ? <Text style={{ backgroundColor: '#90EE90' }}>{ele} <br /></Text> : (ele.toLowerCase().includes('review') || ele.toLowerCase().includes('verification')) ? <Text style={{ backgroundColor: '#D3D3D3' }}>{ele} <br /></Text> : <Text style={{ backgroundColor: '#FFCCCB' }}>{ele} <br /></Text> }) : milestoneValue} {(milestone.date) ? ". " + moment(milestone.date).format('DD MMMM YYYY') : ''}</div>
                                          </>
                                        ) :
                                          item.companyName.toLowerCase().indexOf('dunzo') !== -1 ? (<>
                                            <strong >{milestone.label}</strong>
                                            <div>{milestone.label == 'Document Upload Status' && Array.isArray(milestoneValue) ?
                                              milestoneValue.map((ele, index) => { return <Text key={index} mark>{ele} <br /></Text> }) : milestoneValue} </div>
                                          </>) :
                                            item.companyName.toLowerCase().indexOf('zepto_3pl') !== -1 ? (
                                              <>
                                                <strong >{milestone.label}</strong>
                                                <div>{milestone.label == 'Document Status' && Array.isArray(milestoneValue) ?
                                                  milestoneValue.map((ele, index) => { return <Text key={index} mark>{ele} <br /></Text> }) : milestoneValue} </div>
                                              </>
                                            ) :
                                              (
                                                <>
                                                  <strong>{milestone.label}</strong>
                                                  <div>{milestoneValue}</div>
                                                </>
                                              )
                              }

                            </div>
                            {milestone.label === 'Application Started' ? (
                              <div className="client-logo" style={{ marginLeft: 'auto' }}>
                                <img className="imgResponse" src={item.logo} />
                              </div>
                            ) : null}
                            {
                              (
                                milestone.label.includes('Lead Referred') ||
                                milestone.label.includes('Application Completed') ||
                                milestone.label.includes('Application Not Completed')
                              )
                                &&
                                AdditionalFormsURLEnum[item.companyName.toLowerCase()] ? ( // checking if there is any additional form for this company
                                item.id ? ( // here checking specifics for rapido_3pl
                                  item.companyName.toLowerCase().indexOf('rapido') !== -1 &&
                                    item.companyName.toLowerCase().indexOf('3pl') === -1 &&
                                    (item?.siMetaData?.rapidoMilestone?.value
                                      ?.toString()
                                      .includes('Application Complete') ||
                                      item?.siMetaData?.rapidoMilestone?.submissionType === 'uniquenessCheckOnly' ||
                                      item?.siMetaData?.rapidoMilestone?.submissionType == 'createApplicationOnly'
                                      // || !item?.siMetaData?.rapidoMilestone?.captainId
                                      || nonuniqueCheck(item)
                                    ) ? null : (
                                    <>
                                      {
                                        (
                                          !nonuniqueCheck(item)
                                        ) ?
                                          <>
                                            <Button
                                              className="show-only-desktop"
                                              style={{ margin: '5px 0px 5px auto' }}
                                              icon={<UploadOutlined />}
                                              type="primary"
                                              onClick={() => {
                                                editViewApplicationClick(
                                                  item,
                                                  'view_application_clicked_on_application_status_page'
                                                );
                                              }}
                                            >
                                              Edit/View Application
                                            </Button>
                                            <Button
                                              className="show-only-mobile"
                                              style={{ margin: '5px 0px 5px auto' }}
                                              icon={<UploadOutlined />}
                                              type="primary"
                                              shape="circle"
                                              onClick={() => {
                                                editViewApplicationClick(
                                                  item,
                                                  'view_application_clicked_on_application_status_page'
                                                );
                                              }}
                                            >
                                            </Button>
                                          </> : ''}

                                    </>
                                  )
                                ) : (
                                  <Button
                                    style={{ margin: '5px 0px 5px auto' }}
                                    icon={<UploadOutlined />}
                                    type="primary"
                                    onClick={() => {
                                      editViewApplicationClick(
                                        item,
                                        'complete_application_clicked_on_application_status_page'
                                      );
                                    }}
                                  >
                                    Complete Application
                                  </Button>
                                )
                              ) : null
                            }
                            {item.companyName.toLowerCase().indexOf('rapido') !== -1 && milestone.label === 'Document Upload Status' && Array.isArray(milestone.value) && item.milestone && !item.milestone.includes('activation_date') && !(moment(item.expiryDate).isBefore(moment())) ? (
                              <><Button
                                className="show-only-desktop"
                                style={{ margin: '5px 0px 5px auto' }}
                                icon={<FileSyncOutlined />}
                                type="secondary"
                                onClick={() => {
                                  getLatestRapidoDocumentStatuses(item);
                                }}
                                disabled={!isRefreshDocumentStatusButtonEnabled}
                              >
                                Refresh Document Status
                              </Button>
                                <Button
                                  className="show-only-mobile"
                                  style={{ margin: '5px 0px 5px auto' }}
                                  icon={<FileSyncOutlined />}
                                  shape="circle"
                                  type="secondary"
                                  onClick={() => {
                                    getLatestRapidoDocumentStatuses(item);
                                  }}
                                  disabled={!isRefreshDocumentStatusButtonEnabled}
                                >
                                </Button>
                              </>
                            ) :
                              item.companyName.toLowerCase().indexOf('zepto') > -1 && item?.siMetaData?.isUniquenessChecked && milestone.label === 'Vahan App Status'
                                // && (() => CONSTANTS.ZEPTO_3PL_DOC_MISSING_ARRAY.find((doc) => !item[doc]))()
                                ?
                                <>
                                  <Button
                                    className="show-only-desktop"
                                    style={{ margin: '5px 0px 5px auto' }}
                                    icon={<UploadOutlined />}
                                    type="secondary"
                                    onClick={() => {
                                      uploadOtherDocumentsClick(
                                        item,
                                        'upload_missing_documents_clicked_on_application_status_page'
                                      );
                                    }}
                                  >
                                    Upload Other Documents
                                  </Button>
                                  <Button
                                    className="show-only-mobile"
                                    style={{ margin: '5px 0px 5px auto' }}
                                    icon={<UploadOutlined />}
                                    type="secondary"
                                    shape="circle"
                                    onClick={() => {
                                      uploadOtherDocumentsClick(
                                        item,
                                        'upload_missing_documents_clicked_on_application_status_page'
                                      );
                                    }}
                                  >
                                  </Button>
                                </> : null
                            }
                            {
                              milestone.label.includes('Rapido OTP verification') ?
                                <>
                                  <Button
                                    className="show-only-desktop"
                                    style={{ margin: '5px 0px 5px auto' }}
                                    icon={<UploadOutlined />}
                                    type="primary"
                                    onClick={() => {
                                      editViewApplicationClick(
                                        item,
                                        'view_application_clicked_on_application_status_page'
                                      );
                                    }}
                                  >
                                    Verify OTP
                                  </Button>
                                  <Button
                                    className="show-only-mobile"
                                    style={{ margin: '5px 0px 5px auto' }}
                                    icon={<UploadOutlined />}
                                    type="primary"
                                    onClick={() => {
                                      editViewApplicationClick(
                                        item,
                                        'view_application_clicked_on_application_status_page'
                                      );
                                    }}
                                  >
                                    Verify OTP
                                  </Button>
                                </>
                                : null
                            }
                          </div>
                          {
                            isBronzeML && (milestone?.label?.toLowerCase().includes('mitra app status') || (item?.companyName?.toLowerCase().includes('zepto') && (milestone?.label?.toLowerCase().includes('onboarding form filled (form 2)')))) ? (
                              <div style={{ width: '150px', position: 'relative', padding: '5px', visibility: getMitraAppDownloadMessage(item) ? 'visible' : 'hidden' }}>
                                <div style={{ border: getMitraAppDownloadMessage(item)?.toLowerCase().includes('great') ? '1px solid green' : '1px solid orange', borderRadius: '10px' }}>
                                  <div
                                    style={{
                                      backgroundColor: getMitraAppDownloadMessage(item)?.toLowerCase().includes('great')
                                        ? '#ADDFAD'
                                        : '#FDD9B5',
                                      padding: '5px',
                                      borderRadius: '8px',
                                    }}
                                  >
                                    {(milestone?.label?.toLowerCase().includes('mitra app status') || (item?.companyName?.toLowerCase().includes('zepto') && milestone?.label?.toLowerCase().includes('onboarding form filled (form 2)'))) ? (
                                      getMitraAppDownloadMessage(item) ? getMitraAppDownloadMessage(item) : null
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            ) : null
                          }
                          {
                            isBronzeML && milestone?.label?.toLowerCase().includes(latestMilestone?.toLowerCase()) ? (
                              <div style={{ width: '150px', position: 'relative', padding: '5px', visibility: getLatestTripMilestoneMessage(item) ? 'visible' : 'hidden' }}>
                                <div style={{ border: getLatestTripMilestoneMessage(item)?.toLowerCase().includes('great') ? '1px solid green' : '1px solid orange', borderRadius: '10px' }}>
                                  <div
                                    style={{
                                      backgroundColor: getLatestTripMilestoneMessage(item)?.toLowerCase().includes('great')
                                        ? '#ADDFAD'
                                        : '#FDD9B5',
                                      padding: '5px',
                                      borderRadius: '8px',
                                    }}
                                  >
                                    {milestone?.label?.toLowerCase().includes(latestMilestone?.toLowerCase()) ? (
                                      getLatestTripMilestoneMessage(item) ? getLatestTripMilestoneMessage(item) : null
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            ) : null
                          }

                        </Timeline.Item>
                      );
                    })}
                  </Timeline>
                  {/* {
                index < leadDetails.length-1 ? <Divider style={{margin: '0px', marginTop: '-20px'}} /> : ''
              } */}
                </Card>
              );
            })
            : null}
        </div>

        {/* <div className="container-help-section">
          <div className="help-text">Need any help?</div>
          <div className="help-button">
            <Button onClick={handleRaiseIssueOnClick}>
              <QuestionOutlined /> Raise Issue
            </Button>
          </div>
        </div> */}
      </div>
    )
  }

  const onTabChange = (key) => {
    if (key === 'callHistory') {
      trackEvent('view_candidate_details_follow_ups_calling_screen', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
    }
  }

  const goBackFromReAssignment = () => {
    if (isLeadReassigned) {
      onCloseDetailsPopup()
    }
    else {
      setShowReAssignmentSection(false)
    }
  }

  const getModalTitle = () => {
    if (showCallNoteSection) {
      return (
        <div className='d-flex align-items-center' style={{ gap: '12px' }}>
          <ArrowLeftOutlined onClick={() => setShowCallNoteSection(false)} />
          Add Call Note
        </div>
      )
    } else if (showReAssignmentSection) {
      return (
        <div className='d-flex align-items-center' style={{ gap: '12px' }}>
          <ArrowLeftOutlined onClick={() => goBackFromReAssignment()} />
          <span className='black85 semibold20-28'>
            Re-assign Candidate
          </span>
        </div>
      )
    }
    else {
      return "Candidate Profile"
    }
  }

  const closeCallNotePopup = () => {
    fetchCallHistory();
    setShowCallNoteSection(false);
  }

  const onReassignClicked = () => {
    trackEvent('opened_reassignment_dialog_from_candidate_profile_dialog', {
      page: page ? page : "",
    }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    setShowReAssignmentSection(true);
  }

  const onLeadReassigned = () => {
    setIsLeadReassigned(true)
    leadReassignedViaPopup()
  }

  return (
    <>
      {whatsAppMessageModal ?
        <WhatsappTemplateModal
          lead={lead}
          leadDetails={leadDetails}
          whatsAppMessageModal={whatsAppMessageModal}
          whatsAppMessageModalCancel={whatsAppMessageModalCancel}
        ></WhatsappTemplateModal>
        : null}
      <CallerNumberSelectionModal
        showModal={showCallerSelectionModal}
        closeModal={closeCallerSelectionModal}
        callerNumberList={callerNumberList}
      >
      </CallerNumberSelectionModal>


      <Modal
        title={getModalTitle()}
        centered
        visible={visible && lead}
        onCancel={() => onCloseDetailsPopup()}
        footer={null}
        bodyStyle={{ height: '600px', overflow: 'scroll', justifyContent: 'space-between', background: showCallNoteSection ? '#0000000A' : '#FFF' }}
        width={620}
        className="modal-candidate-profile"
      >
        {
          showCallNoteSection ? (
            <CustomCallNotes
              parentUserName={lead?.firstName}
              parentUserId={lead?.id}
              parentRhId={currentRhId}
              parentPhoneNumber={lead?.phoneNumber}
              showHeader={false}
              onSubmit={closeCallNotePopup}
            />
          ) :
            showReAssignmentSection ? (
              <ReassignLeadsDialog showHeader={false} onBackClick={() => setShowReAssignmentSection(false)} rhIdForSingleReassignment={leadDetails && leadDetails.length > 0 ? leadDetails[0].recommendationHistoryId : ""} totalFilteredCandidates={1} allFiltersFromParent={{ teamMemberIds: [leadDetails[0].mitraId] }} allTeamMembersList={null} isBulkReassignment={false} pageType={"single"} isLeadReassigned={onLeadReassigned} />
            )
              :
              (
                <Tabs
                  defaultActiveKey="candidateInfo"
                  size={'medium'}
                  onChange={onTabChange}
                  centered
                  tabBarStyle={{ display: 'flex', justifyContent: 'space-between' }}>
                  <TabPane tab={<span className="d-flex align-items-center">
                    Candidate Information</span>} key="candidateInfo">
                    {candidateInformation()}
                  </TabPane>
                  <TabPane tab={<span className="d-flex align-items-center">
                    {
                      mitraReducer?.mitraInfo && rollOutCallHistory(mitraReducer.mitraInfo) ?
                        'Call History' : 'Call Notes'
                    }
                  </span>} key="callHistory">
                    <div className='details-component-container'>
                      <div className="Contact-details-header">
                        <div className="Candidate-details">
                          <div className="head-details">
                            <div className="name-number-container">
                              <div className="lead-name">
                                {leadDetails.length > 0
                                  ? leadDetails[0].lastName.includes('Unknown')
                                    ? leadDetails[0].firstName
                                    : leadDetails[0].firstName + ' ' + leadDetails[0].lastName
                                  : ''}
                              </div>
                              <div className="lead-number">
                                {leadDetails.length > 0
                                  ? leadDetails[0].parentPhoneNumber
                                    ? `${leadDetails[0].parentPhoneNumber} (Edited)`
                                    : leadDetails[0].phoneNumber
                                  : ''}
                              </div>
                            </div>
                            {/* <div className="user-image" >{leadDetails.length > 0 ? leadDetails[0].firstName.charAt(0).toUpperCase() : ''} */}
                            {
                              // lead ? lead.jobs ? checkAtRisk(lead) ? <div className="counter counter-lg"><InfoCircleOutlined /></div> : null : null : null
                            }
                            {/* </div> */}
                            <div className="contact-buttons">
                              {
                                mitraReducer?.mitraInfo && rollOutCallHistory(mitraReducer.mitraInfo) && source === 'followUp' ? kaleyraCallFeature() : directCallFeature()
                              }

                              <Button
                                onClick={(e) => {
                                  GAEventsTracker('MessageCandidate');
                                  setWhatsAppMessageModal(true);
                                  logEvent(
                                    'WhatsAppCandidate',
                                    trackingConstants.EVENT_GROUP_VIEW_LEAD,
                                    trackingConstants.EVENT_SCREEN_CANDIDATE
                                  );
                                  trackEvent(
                                    'message_option_clicked',
                                    {},
                                    CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS
                                  );
                                }}
                                icon={<WhatsAppOutlined style={{ color: '#fff' }} />}
                                style={{ backgroundColor: '#52C41A' }}
                                className='d-flex align-items-center'
                                type="dashed"
                              >
                                <Text style={{ color: '#fff' }}>Message</Text>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ overflow:'scroll', height:'100%',marginTop:'20px'}}>
                        <Button onClick={()=>{onAddNotesClick(leadDetails?.[0]?.recommendationHistoryId)}} style={
                            {background:'#FFEAE0', border: '1px solid #D34612', display: 'flex',alignItems:'center',padding: '1px 8px', width:'125px', height:'24px'}}>
                            <PlusOutlined style={{color: '#D34612'}}/> 
                            <Text style={{color: '#D34612'}}>
                              Add Remarks
                            </Text>
                        </Button>
                        {
                          callHistory.length > 0 ? callHistory.map((item, index) => {
                            return (
                              <CallHistoryCard
                                history={item}
                                key={index} />
                            )
                          }) : <Empty
                            description={
                              <span>
                                No Call History!!
                              </span>
                            }
                          />
                        }
                      </div>

                      {/* <div className="container-help-section">
                        <div className="help-text">Need any help?</div>
                        <div className="help-button">
                          <Button onClick={handleRaiseIssueOnClick}>
                            <QuestionOutlined /> Raise Issue
                          </Button>
                        </div>
                      </div> */}
                    </div>
                  </TabPane>
                </Tabs>
              )
        }

        <div className='cpp-footer-parent d-flex justify-content-between align-items-center'>
          {
            !showReAssignmentSection ?
              <Divider />
              :
              null
          }

          {
            showReAssignmentSectionFooter ?
              <div className='cpp-footer d-flex justify-content-between w-100'>
                {
                  showReAssignmentSection ?
                    null
                    :
                    <div className='w-100 d-flex justify-content-center'>
                      <div className='d-flex w-50 align-items-center'>
                        <UserOutlined className='cpp-footer-tc-icon' />
                        <span className='cpp-footer-tc-txt semibold16-24 black45'>
                          Added by&nbsp;
                        </span>
                        <span className='bold16-24 black45'>
                          {leadDetails[0]?.mitraName}
                        </span>
                        {
                          leadDetails && leadDetails.length > 0 && reassignAccess ?
                            <span className='cpp-edit jp-cp disp-flex' onClick={onReassignClicked}>
                              <EditOutlined className='cpp-edit-icon primary-orange2' />
                            </span>
                            :
                            null
                        }
                      </div>

                      <div className="help-button w-50 d-flex justify-content-end">
                        <Button onClick={handleRaiseIssueOnClick}>
                          <QuestionOutlined /> Raise Issue
                        </Button>
                      </div>
                    </div>
                }

              </div>
              :
              null
          }


        </div>

      </Modal>
    </>
  );
};
export default CandidateProfilePopupV2;
