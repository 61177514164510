import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import * as amplitude from 'amplitude-js';
import "formiojs/dist/formio.full.min.css"
import "formiojs/dist/formio.full.min.js"

import { init as initApm } from '@elastic/apm-rum'
var apm = initApm({
  serviceName: 'mitra-leader-pwa',
  serverUrl: 'https://realusermonitoring.vahan.co',
  serviceVersion: '__ML_PWA_APM_VERSION__',
  environment: process.env.NODE_ENV,
  active: process.env.NODE_ENV === 'production'
})

amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_API_KEY);
// eslint-disable-next-line no-undef
window.Moengage = moe({
	app_id: 'A7BUJ1RRESMUMVUOLEOLJ1XD',
	debug_logs: process.env.NODE_ENV === 'production' ? 0 : 1,
  });
ReactDOM.render(
		<App />,
	document.getElementById('root')
);

// serviceWorker.register();
