import React, {useState, useEffect, useContext} from 'react';
import { DatePicker, Button, PageHeader, Modal } from 'antd';
import { DownOutlined, FileDoneOutlined, InfoCircleOutlined, GlobalOutlined, CloudDownloadOutlined, CloseOutlined, PhoneOutlined, UserOutlined, LinkOutlined, SearchOutlined, DownloadOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { trackEvent } from "../../service/engagementMonitoringService";
import CONSTANTS from "../../constants/constants";
import ShareOnPlatform from "./shareOnPlatform.component";
import SubmitPopup from "./SubmitPopup.component";
import {useParams} from "react-router-dom";
import {useForm} from 'react-hook-form';
import * as jobDemandsService from '../../service/jobDemandsService';

const JobPostingPage = () => {
    const {posterId} = useParams();
    const {register} = useForm();
    const [submitText, setSubmitText] = useState('Your response has been submitted! One of our representatives will call you back shortly!')
	const [phoneNumberError, setPhoneNumberError] = useState(false)
	const [nameError, setNameError] = useState(false)
	const [APIError, setAPIError] = useState("")
	const [showAPIError, setShowAPIError] = useState(false)
	const [name, setName] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
    const [showSubmitPopup, setShowSubmitPopup] = useState(false);
	const [jobPostingImageUrl, setJobPostingImageUrl] = useState({});

    const handleNameChange = (event) => {
		setName(event.target.value);
	};
	const handleNumberChange = (event) => {
		setPhoneNumber(event.target.value);
	};

    useEffect(() => {
        getJobPostingImage();
    }, []);

    const getJobPostingImage = async () => {
		jobDemandsService.getJobPosterById(posterId).then(response => {
            console.log(response);

            if(response?.data?.data) {
                console.log('disabled');
			    setJobPostingImageUrl(response.data.data);
            }
		});
		return '';
	}
    const createJobPostingResponse = async (posterId, name, phoneNumber) => {
		try {
			if (name === undefined || name.trim() === '') {
				setNameError(true)
				return
			}
			else {
				setNameError(false)
			}
			if (phoneNumber === undefined || phoneNumber.trim() === '' || phoneNumber.length > '10' || phoneNumber.length < '10') {
				setPhoneNumberError(true);
				return;
			}
			else {
				setPhoneNumberError(false)
			}
			jobDemandsService.createJobPostingResponse(posterId, name, phoneNumber).then((result) => {
				if (result && result.data.success) {
                    setShowSubmitPopup(true);
				}
			})
				.catch(error => {
					console.log(error.response)
					if (error.response.data) {
						setShowAPIError(true)
						setAPIError(error.response.data.message)
					}
				})
		} catch (error) {
			console.log(error);
		}
	}
    return (
        <div className="jobs-page" style={{marginTop: '1em'}}>
            <div title='Preview poster' className="preview">
                {jobPostingImageUrl ? <img className="companyName" src={jobPostingImageUrl} /> : ''}

                <div className="input-fields">
                    {
                        nameError ?
                            <div className="error">Please enter Name</div>
                            : null
                    }
                    <input
                        className="dropdown-control"
                        ref={register({required: true, minLength: 2})}
                        type="text"
                        placeholder="Enter your full name"
                        name="name"
                        onChange={handleNameChange}
                        required
                    />
                    {
                        phoneNumberError ?
                            <div className="error">Please enter a Valid Phone Number</div>
                            : null
                    }
                    <input
                        className="dropdown-control"
                        ref={register({required: true, minLength: 10, maxLength: 10})}
                        type="tel"
                        placeholder="Your Mobile number"
                        name="phoneNumber"
                        onChange={handleNumberChange}
                        required
                    />
                    <button className="createViewWeblink" onClick={(e) => {createJobPostingResponse(posterId, name, phoneNumber);}} style={{width: '95%'}}>
                        Apply Now
                    </button>
                    {
                        showAPIError ?
                            <div className="error">{APIError}</div>
                        : null
                    }
                </div>
            </div>
            <SubmitPopup visible={showSubmitPopup} setShowPopup={setShowSubmitPopup} text={submitText}></SubmitPopup>
        </div>
    )
};

export default JobPostingPage;
