import React, { useState, useEffect, useContext, useReducer } from 'react';
import HotLead from '../common/candidateApplication/CandidateApplicationCardForHotLead.component';
// import CandidateProfilePopupV2 from './candidateProfilePopupV2Components'
import { useHistory } from 'react-router-dom';
import moment from "moment";
import useGAEventsTracker from '../../service/useGSEventsTracker';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { DatePicker, Button, Tag, Space, Typography, Skeleton, Pagination, Empty, Tabs, Modal, Result, Dropdown, Menu, notification, Row, Col, Select } from 'antd';
import { AppContext } from '../../App';
import { getActiveClientsForFilter, disabledDateLessThan25daysForHotLeads, getStatusListForFilters, fetchActivatedAndReferredLeads, getRapidoDocumentStatuses, getLocationForFilter, getMitraTeamMembersList } from './../../service/commonService'
import { SearchOutlined, FireOutlined, FilterOutlined, WhatsAppOutlined, InfoCircleOutlined, ExclamationCircleOutlined, ProfileOutlined } from '@ant-design/icons';
import { showHotLeadsPopUpBackend, getCandidatesData, downloadCandidatesPageData, displayexpiryDateChangedBanner, getHotLeadData } from './../../service/leadService'
import SideSheet from "../common/SideSheet"
import SideInput from "../common/SiderInput"
import axios from 'axios';
import PageHeaderComponent from '../common/PageHeader';
import '../../css/lead.scss';
import { useLocation } from "react-router-dom";
import WhatsappTemplateModal from '../whatsappTemplateModal/WhatsappTemplateModal';
import { getViewDetailsData } from './../../service/leadService';
import CandidateProfilePopupV2 from './candidateProfilePopupV2Components/candidateProfilePopupV2';
import { useParams } from "react-router-dom";
import * as queryString from 'query-string';
import RaiseIssueSider from '../help/RaiseIssueSider.component';
import LocationFilterComponent from '../common/LocationFilter.component';
import VerifyEmailPopup from './../common/VerifyEmailPopup.component';
import { rollOutRecruitmentMilestoneVisibility, jobseekerApplicationInML } from '../../constants/utils';
import { getDisplayableClientName } from './../../utils/commonFunction';
import NewLoader from '../common/NewLoader/NewLoader';
import SearchComponent from '../common/globalSearch';
import axiosInstance from '../../service/interceptorService';
import ReassignLeadsDialog from './ReassignLeads/ReassignLeadsDialog';

const { Text } = Typography;
const { RangePicker } = DatePicker;
const louTours = {
	'Candidate page tracking': 815030298538
}
const HotLeadsPage = () => {
	const location = useLocation();
	const [pathNameCandidates, setPathNameCandidates] = useState(window.location.href.indexOf("candidates") > -1)
	const stateData = JSON.parse(location.state ? location.state : '{}');
	const history = useHistory();
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	// const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const AllCandidatesGAEventsTracker = useGAEventsTracker("Action buttons clicked")
	// const CriticalCandidatesGAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const { mitraReducer, setAlert, setSpin } = useContext(AppContext);
	const [leads, setLeads] = useState([
		{
            "recommendationHistoriesId": [
                "b5935354-60b2-494e-b87d-06d5c427724a"
            ],
            "logos": [
                "https://uploads-a.vahan.co/fileUploder/2022-06-22/torU9W-zomato.png"
            ],
            "scheduledInterviewId": [
                "d41953a5-f151-4239-a6f8-d78a31febaac"
            ],
            "siMetaData": [
                null
            ],
            "jobId": [
                "2cdc7637-be6e-43b9-886e-c70ae5e6d4c4"
            ],
            "jobDemandId": [
                "653cd09e-0f5d-4f1e-b8ea-ca0d348e1862"
            ],
            "firstName": "Raghukumar",
            "lastName": "Unknown",
            "id": "0d57ad6a-aa69-4705-96ad-aac507c4ee79",
            "phoneNumber": "8748003876",
            "mitraAppUserLoginAt": "2024-02-24T11:52:18.501Z",
            "siCreatedAt": [
                "2024-02-24T11:44:30.363Z"
            ],
            "rhCreatedAt": [
                "2024-02-24T11:44:30.288Z"
            ],
            "ujfmCompletedAt": [
                "2024-02-24T00:00:00.000Z"
            ],
            "companies": [
                "Zomato"
            ],
            "mitraName": [
                "Vetrivel"
            ],
            "productSource": [
                null
            ],
            "parentPhoneNumber": [
                null
            ],
            "jobType": [
                "Delivery"
            ],
            "companyCity": [
                "Aligarh"
            ],
            "role": [
                "Delivery Executive"
            ],
            "latestMilestone": [
                "Activated"
            ],
            "ujfmCreatedAt": [
                "2024-02-24T11:44:30.798Z"
            ],
            "pickedByVahan": [
                false
            ],
            "aadhaarCard": 0,
            "drivingLicense": 0,
            "vehicleRc": 0,
            "panCard": 0,
            "userSelfie": 0,
            "bankPassbookOrCancelledCheque": 0,
            "jobseekerDocsConfirmation": 0,
            "bankDetailsVerified": 0,
            "zomatoMilestones": "[{\"milestoneName\":\"onboarding_fee_payment_date\",\"milestoneRank\":380,\"completedAt\":\"2024-02-24T00:00:00.000Z\",\"metaData\":{}},{\"milestoneName\":\"referral_date\",\"milestoneRank\":100,\"completedAt\":\"2024-02-24T00:00:00.000Z\",\"metaData\":{}},{\"milestoneName\":\"activation_date\",\"milestoneRank\":500,\"completedAt\":\"2024-02-24T00:00:00.000Z\",\"metaData\":{}},{\"milestoneName\":\"document_successfully_uploaded_date\",\"milestoneRank\":330,\"completedAt\":\"2024-02-25T00:00:00.000Z\",\"metaData\":{}},{\"milestoneName\":\"mitra_app_download\",\"milestoneRank\":350,\"completedAt\":\"2024-02-24T11:52:18.503Z\",\"metaData\":{}},{\"milestoneName\":\"marked_unique\",\"milestoneRank\":200,\"completedAt\":\"2024-02-24T11:44:30.798Z\",\"metaData\":{}},{\"milestoneName\":\"referral_date_si\",\"milestoneRank\":50,\"completedAt\":\"2024-02-24T11:44:30.439Z\",\"metaData\":{}},{\"milestoneName\":\"document_status\",\"rank\":null,\"completedAt\":null,\"value\":\"APPROVED\",\"completed\":true},{\"milestoneName\":\"bank_doc_submitted\",\"rank\":null,\"completedAt\":null,\"value\":1,\"completed\":true},{\"milestoneName\":\"pan_card_doc_submitted\",\"rank\":null,\"completedAt\":\"2024-02-24T00:00:00.000Z\",\"value\":1,\"completed\":true},{\"milestoneName\":\"ob_fees_paid\",\"rank\":360,\"completedAt\":\"2024-02-24T00:00:00.000Z\"},{\"milestoneName\":\"client_app_downloaded\",\"rank\":250,\"completedAt\":\"2024-02-27\"},{\"milestoneName\":\"p0_upto_5_sections_filled\",\"rank\":320,\"completedAt\":\"2024-02-27\"}]",
            "priority": "high"
        },
	]);
	const [startDate, setStartDate] = useState(moment().subtract(6, 'days').format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
	const [showDetail, setShowDetail] = useState(false);
	const [selectedLead, setSelectedLead] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [mitra, setMitra] = useState();
	const [filterSider, setfilterSider] = useState(false);
	const [clickRangePicker, setClickRangePicker] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false)
	const [breadcrumb, setbreadcrumb] = useState(pathNameCandidates ? [{ link: '/recruiter-candidates', label: 'Candidates' }, { link: '/hot-leads-candidates', label: 'Hot Leads' }] : [{ link: '/', label: 'Home' }, { link: '/hot-leads-candidates', label: 'Hot Leads' }])
	const [clientSelected, setClientSelcted] = useState([])
	const [referAgain, setReferAgain] = useState(false);
	const [currentJob, setCurrentJob] = useState({});
	const [submitdisable, setSubmitdisable] = useState(true);
	const [alternateNumber, setAlternateNumber] = useState();
	const [statusesSelected, setStatusesSelected] = useState([]);
	const dateFormat = 'YYYY/MM/DD';
	const dateFormatList = ['DD-MM-YYYY', 'DD/MM/YY', 'DD/MM/YYYY', 'DD-MM-YY'];
	const [filterApplied, setFilterApplied] = useState(true)
	const [mitraList, setMitraList] = useState([])
	const [totalApplicationCount, setTotalApplicationCount] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)
	const [pageLimit, setPageLimit] = useState(50)
	const [downloadLoading, setDownloadLoading] = useState(false)
	const [pageTypeforLeadReassignment, setPageTypeforLeadReassignment] = useState('hot_leads')
	const [currentTab, setCurrentab] = useState('priority1')
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [candidatePostReferralSteps, setCandidatePostReferralSteps] = useState([]);
	const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);
	const [leadDetails, setLeadDetails] = useState([]);
	const [selectedScheduledInterviewId, setSelectedScheduledInterviewId] = useState([]);
	const [selectedRecommendationHistoryId, setSelectedRecommendationHistoryId] = useState([]);
	const trackMoengageEvent = (event, obj) => {
		trackEvent(event, obj || {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}
	const [showBanner, setShowBanner] = useState(false)
	const [trackUpdatedExpiryDate, setTrackUpdatedExpiryDate] = useState(false)
	const { tab } = useParams();
	const [raiseIssueSider, setRaiseIssueSider] = useState(false);
	const [viewDetailLead, setViewDetailLead] = useState({});
	const [locationList, setLocationList] = useState([])
	const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
	const [changeEmail, setChangeEmail] = useState(false);
	const [mitraAppDownloadedFilter, setMitraAppDownloadedFilter] = useState([{ label: 'Downloaded', value: false, key: 'downloaded' }, { label: 'Not Downloaded', value: false, key: 'not_downloaded' }]);
	const [documentStatusFilter, setDocumentStatusFilter] = useState([{ label: 'Complete', value: false, key: 'complete' }, { label: 'Incomplete', value: false, key: 'incomplete' }]);
	const [applicationType, setApplicationType] = useState([{ label: 'Mitra App Applications', value: false, key: 'ma_applications' }, { label: 'Mitra Leader Applications', value: false, key: 'ml_applications' }])
	const [tagsSelected, setTagsSelected] = useState([...CONSTANTS.RECRUITER_DASHBOARD_CALL_STATUS_TAGS]);
	const [candidateStatusFilter, setCandidateStatusFilter] = useState([...CONSTANTS.RECRUITER_DASHBOARD_CANDIDATE_STATUS_TAGS]);
	const [searchText, setSearchText] = useState('');
	const [cancelToken, setCancelToken] = useState(null); // State to store the cancel token
	const [hasDownloadAccess, setHasDownloadAccess] = useState(false);
	const [hasReassignAccess, setHasReassignAccess] = useState(false);
	const [showLeadReassignDialog, setShowLeadReassignDialog] = useState(false);
	const [allFiltersList, setAllFiltersList] = useState({});
	const key = `open${Date.now()}`;
	const placement = 'topRight';

	const handleScroll = () => {
		let leadsWrapperDiv = document.getElementsByClassName('leads-wrapper')[0];
		let backToTopBtn = document.getElementById("back-to-top");
		let y = leadsWrapperDiv.scrollTop;
		if (y >= 1500) {
			backToTopBtn.className = "cta showCta"
		} else {
			backToTopBtn.className = "cta hideCta"
		}
	};

	useEffect(() => {
		if (mitraReducer && mitraReducer.allowedFeatures) {
			setHasDownloadAccess(mitraReducer?.allowedFeatures[CONSTANTS.DOWNLOAD_DATA_ACCESS]?.isEnabledOverall)
			setHasReassignAccess(mitraReducer?.allowedFeatures?.[CONSTANTS.CAN_REASSIGN_LEADS]?.isEnabledOverall)
		}
	}, [mitraReducer])

	useEffect(() => {
		// let zomatoRequiredMilestoneKeys = ["p3AppDownloadedButNoSectionFilled", "p2Only1or2SectionsFilled", "p1Upto3or4SectionsFilled", "p0Upto5SectionsFilled", "obFeesPaid"]
		let zomatoRequiredMilestoneKeys = [
			{ key: "Very High", value: false, label: "Very High", priority: 'high' },
			{ key: "High", value: false, label: "High", priority: 'medium' },
			{ key: "Medium", value: false, label: "Medium", priority: 'low' }
		]
		console.log("tab:>> in useEffect:>>", tab)
		if (mitraReducer?.mitraInfo) {
			setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
			setMitra(mitraReducer?.mitraInfo ? mitraReducer?.mitraInfo : {})

			// if (jobseekerApplicationInML(mitraReducer?.mitraInfo)) {
			// 	setApplicationType([{ label: 'Mitra App Applications', value: true, key: 'ma_applications' }, { label: 'Mitra Leader Applications', value: false, key: 'ml_applications' },{label: 'Claimed Applications', value: false, key: 'claimed_applications'}])
			// }

			getActiveClientsForFilter().then(data => {
				setClientSelcted(data.data.activeClients)
			})
			// getStatusListForFilters().then(data => {
			// 	console.log(data.data.statuses);
			//     let filteredStatuses = data.data.statuses.filter(status => {
			//         return zomatoRequiredMilestoneKeys.includes(status.key);
			//       });
			//     console.log("called getStatusList:>>", filteredStatuses)
			// 	setStatusesSelected(filteredStatuses)
			// })
			setStatusesSelected(zomatoRequiredMilestoneKeys)
			getLocationForFilter().then(response => {
				setLocationList(response.data.locationList)
			})
			fetchActivatedAndReferredLeads().then(res => {
				// adding high intent events

			}).catch(err => console.log(err));
			if (tab === 'priority1') {
				// setCurrentab('action-needed')
				// setBreadcrumb([{link: '/leads/all-candidates', label: 'Candidates'},{link: '/leads/action-needed', label: 'Action Needed'}])
			}
			getMitraTeamMembersList().then(response => {
				setMitraList(response.data.data);
			});
		}
		setPageLimit(50)
	}, [mitraReducer])

	useEffect(() => {
		console.log("called useEffect [mitra, filterApplied]");
		let element = document.getElementsByClassName('leads-wrapper')[0];
		element.addEventListener('scroll', handleScroll, { passive: true });
		if (mitra?.id && filterApplied) {
			setFilterApplied(false)
			getHotLeadCandidates()
			if (currentTab === 'all-candidates' && !trackUpdatedExpiryDate) {
				displayexpiryDateChangedBanner({ startDate: startDate, endDate: endDate }).then(response => {
					setShowBanner(response.data.showBanner)
				})
			}

		}
	}, [mitra, filterApplied])

	useEffect(() => {
		const params = queryString.parse(window.location.search);
		if (params?.startDate && params?.endDate) {
			handleDateRange([moment(params.startDate), moment(params.endDate)]);
		}
	}, []);

	const getHotLeadCandidates = () => {
		setLoaded(false)
		let clientList = [], statusList = [], selectLocationList = [], selectedMitraAppDownloadedFilter, selectedDocumentStatusFilter, selectedApplicationType, teamMemberList = [], callStatusTagList = [], candidateStatusList = [];
		clientList.push('zomato')
		// statusList = ["leadReferred","p3AppDownloadedButNoSectionFilled","p2Only1or2SectionsFilled","p1Upto3or4SectionsFilled","p0Upto5SectionsFilled"];

		clientSelected.map(client => {
			if (client.value) {
				clientList.push(client.label.toLowerCase())
			}
		})

		statusesSelected.map(status => {
			if (status.value) {
				statusList.push(status.key)
			}
		})

		locationList.map(status => {
			if (status.value) {
				selectLocationList.push(status.label)
			}
		})

		mitraList.map(single_mitra => {
			if (single_mitra.value) {
				teamMemberList.push(single_mitra.id)
			}
		})

		tagsSelected.map(tag => {
			if (tag.value) {
				if (tag.key === 'connected' || tag.key === 'notConnected') callStatusTagList.push(tag.key)
				else callStatusTagList.push(tag.label)
			}
		});

		candidateStatusFilter.map(status => {
			if (status.value) {
				candidateStatusList.push(status.key)
			}
		});

		selectedMitraAppDownloadedFilter = mitraAppDownloadedFilter.find(item => item.value)?.key;

		selectedDocumentStatusFilter = documentStatusFilter.find(item => item.value)?.key;

		selectedApplicationType = applicationType.find(item => item.value)?.key;

		if (selectLocationList.length > 0) {
			trackEvent('clicked_city_filter_candidates_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		}
		const searchParams = new URLSearchParams(window.location.search);
		const isOlap = searchParams.get("isOlap");
		console.log('isOlap 1:>> ', isOlap);
		if (!isOlap) {

			let data = {
				startDate: moment(startDate).format("YYYY-MM-DD"),
				endDate: moment(endDate).format('YYYY-MM-DD'),
				clients: JSON.stringify(clientList),
				statuses: JSON.stringify(statusList),
				offset: currentPage * pageLimit - pageLimit,
				limit: pageLimit,
				targetClient: 'zomato',
				// incompleteApplications: currentTab === 'all-candidates' ? false : true,
				updatedExpiryDateLeads: trackUpdatedExpiryDate,
				locations: selectLocationList,
				// mitraAppDownloadedFilter: selectedMitraAppDownloadedFilter,
				isEligibleForRecruitmentFlow: rollOutRecruitmentMilestoneVisibility(mitraReducer?.mitraInfo),
				documentStatusFilter: selectedDocumentStatusFilter,
				applicationType: selectedApplicationType,
				teamMemberIds: JSON.stringify(teamMemberList),
				callStatusTagList: JSON.stringify(callStatusTagList),
				candidateStatus: JSON.stringify(candidateStatusList),
				zomatoHotLeadsTab: 'combined',
				searchKey: searchText
			}
			createFiltersListForLeadReassignment(clientList, statusList, callStatusTagList, teamMemberList, selectLocationList)
			console.log("currentTab before calling get Candidates Data API:>>", currentTab);
			const t0 = performance.now();

			if (cancelToken) {
				cancelToken.cancel(); // Used to cancel previous request to prevent data discrepancy
			}
			const newCancelToken = axios.CancelToken.source(); // Create a new CancelToken
			setCancelToken(newCancelToken); // Set the new cancel token

			getHotLeadData(data, newCancelToken.token).then((result) => {
				if (location.state) {
					openNextStepsPopUp()
				}
				setLoaded(true)
				setLeads(result.data.result);
				// if(result.data.count && Number(result.data.count) > 0){
				setTotalApplicationCount(Number(result.data.count))
				// }
				// setFilterApplied(false)
				const t1 = performance.now();
				console.log(`Api getCandidatesData call took ${t1 - t0} milliseconds.`);
				trackEvent('candidates_page_load_time', {
					candidates_page_load_time: (t1 - t0)
				}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			})
		} else {
			let data = {
				startDate: moment(startDate).format("YYYY-MM-DD"),
				endDate: moment(endDate).format('YYYY-MM-DD'),
				clients: JSON.stringify(clientList),
				statuses: JSON.stringify(statusList),
				offset: currentPage * pageLimit - pageLimit,
				limit: pageLimit,
				targetClient: 'zomato',
				// incompleteApplications: currentTab === 'all-candidates' ? false : true,
				updatedExpiryDateLeads: trackUpdatedExpiryDate,
				locations: selectLocationList,
				// mitraAppDownloadedFilter: selectedMitraAppDownloadedFilter,
				isEligibleForRecruitmentFlow: rollOutRecruitmentMilestoneVisibility(mitraReducer?.mitraInfo),
				documentStatusFilter: selectedDocumentStatusFilter,
				applicationType: selectedApplicationType,
				teamMemberIds: JSON.stringify(teamMemberList),
				callStatusTagList: JSON.stringify(callStatusTagList),
				candidateStatus: JSON.stringify(candidateStatusList),
				zomatoHotLeadsTab: 'combined'
			}
			console.log("currentTab before calling get Candidates Data API:>>", currentTab);
			const t0 = performance.now();

			if (cancelToken) {
				cancelToken.cancel(); // Used to cancel previous request to prevent data discrepancy
			}
			const newCancelToken = axios.CancelToken.source(); // Create a new CancelToken
			setCancelToken(newCancelToken); // Set the new cancel token

			getCandidatesData(data, newCancelToken.token).then((result) => {
				if (location.state) {
					openNextStepsPopUp()
				}
				setLoaded(true)
				setLeads(result.data.result);
				// if(result.data.count && Number(result.data.count) > 0){
				setTotalApplicationCount(Number(result.data.count))
				// }
				setFilterApplied(false)
				const t1 = performance.now();
				console.log(`Api getCandidatesData call took ${t1 - t0} milliseconds.`);
				trackEvent('candidates_page_load_time', {
					candidates_page_load_time: (t1 - t0)
				}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			})
		}
	}

	const createFiltersListForLeadReassignment = (clientList = [], statusList = [], callStatusTagList = [], teamMemberList = [], selectLocationList = []) => {
		let data = {
			startDate: moment(startDate).format("YYYY-MM-DD"),
			endDate: moment(endDate).format("YYYY-MM-DD"),
			clients: clientList,
			statuses: statusList,
			callStatusTagList: callStatusTagList,
			teamMemberIds: teamMemberList,
			locations: selectLocationList.length > 0 ? selectLocationList : [],
		}
		setAllFiltersList(data)
		console.log("ALL_FILTERS_FOR_LEAD_REASSIGNMENT", data)
	}

	const openNextStepsPopUp = () => {
		setTimeout(() => {
			let serialNo = 0;
			const steps = CONSTANTS.CANDIDATE_POST_REFERRAL_STEPS.map(ele => {
				if (ele?.clients?.includes(stateData?.clientPreference?.toLowerCase())) {
					serialNo++;
					if (!ele.hasClickableUrl) {
						return <h5 style={{ textAlign: 'left' }} key={'point' + serialNo}> {serialNo} {ele.message}</h5>;
					} else {
						// eslint-disable-next-line
						var urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
						let urls = ele.message.match(urlRegex)
						let msgBreakup = [];
						let msg = ele.message;
						urls.forEach((url) => {
							let index = msg.indexOf(url)
							msgBreakup.push(msg.slice(0, index));
							msgBreakup.push(msg.slice(index, index + url.length));
							msg = msg.slice(index + url.length, msg.length);
						})
						msgBreakup.push(msg);
						let main = <span>
							{
								msgBreakup.map((element, index) => {
									if (urls.includes(element)) {
										return <a key={index} href={element} target='_blank' rel="noreferrer">{element}</a>;
									} else {
										return element;
									}
								})
							}
						</span>
						return <h5 style={{ textAlign: 'left' }} key={'point' + serialNo}> {serialNo}. {main} </h5>;
					}
				}
			})
			setCandidatePostReferralSteps(steps)
			setIsModalOpen(true)
		}, 500);
	}
	const deleteChip = (name, item) => {
		if (name === 'status') {
			let newStatus = [...statusesSelected]
			newStatus.map((single_status) => {
				if (single_status.label === item.label) {
					single_status.value = false;
				}
			})
			setStatusesSelected(newStatus)
		}
		else if (name === 'category') {
			let newCategory = [...clientSelected]
			newCategory.map((single_category) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setClientSelcted(newCategory)
		}
		else if (name === 'updateExpiryFilter') {
			setTrackUpdatedExpiryDate(false)
		}
		else if (name === 'location') {
			let newCategory = [...locationList]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setLocationList(newCategory)
		}
		else if (name === 'mitraAppDownload') {
			let newCategory = [...mitraAppDownloadedFilter]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setMitraAppDownloadedFilter(newCategory)
		}
		else if (name === 'documentStatus') {
			let newCategory = [...documentStatusFilter]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setDocumentStatusFilter(newCategory)
		}
		else if (name === 'applicationType') {
			let newCategory = [...applicationType]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setApplicationType(newCategory)
		}
		else if (name === 'candidateStatus') {
			let newcandidateStatuses = [...candidateStatusFilter]
			newcandidateStatuses.map((single_status, index) => {
				if (single_status.label === item.label) {
					single_status.value = false;
				}
			})
			setCandidateStatusFilter(newcandidateStatuses)
		}
		else if (name === 'team_member') {
			let newCategory = [...mitraList]
			newCategory.map((single_category, index) => {
				if (single_category.name === item.name) {
					single_category.value = false
				}
			})
			setMitraList(newCategory)
		}
		else if (name === 'call_status') {
			let newCategory = [...tagsSelected]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setTagsSelected(newCategory)
		}
		setFilterApplied(true)
	}

	const handleDownload = () => {
		trackEvent('zomatoHotLeadsFileDownloadClicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		setShowVerifyEmailPopup(true)
	}

	const handleSheet = async (email) => {
		if (leads.length > 0) {
			setDownloadLoading(true)
			let clientList = [], statusList = [], selectedLocationList = [], selectedMitraAppDownloadedFilter, selectedDocumentStatusFilter, selectedApplicationType, teamMemberList = [];
			clientSelected.map(client => {
				if (client.value) {
					clientList.push(client.label.toLowerCase())
				}
			})

			statusesSelected.map(status => {
				if (status.value) {
					statusList.push(status.key)
				}
			})
			mitraList.map(single_mitra => {
				if (single_mitra.value) {
					teamMemberList.push(single_mitra.id)
				}
			})
			locationList.map(location => {
				if (location.value) {
					selectedLocationList.push(location.label)
				}
			})

			selectedMitraAppDownloadedFilter = mitraAppDownloadedFilter.find(item => item.value)?.key;

			selectedDocumentStatusFilter = documentStatusFilter.find(item => item.value)?.key;
			selectedApplicationType = applicationType.find(item => item.value)?.key;

			const data = {
				startDate: startDate.format('YYYY-MM-DD'),
				endDate: moment(endDate).format('YYYY-MM-DD'),
				clients: JSON.stringify(['zomato']),
				targetClient: 'zomato',
				statuses: JSON.stringify(statusList),
				// incompleteApplications: currentTab === 'all-candidates' ? false : true,
				updatedExpiryDateLeads: trackUpdatedExpiryDate,
				locations: selectedLocationList.length > 0 ? selectedLocationList : [],
				// mitraAppDownloaded: selectedMitraAppDownloadedFilter,
				documentStatus: selectedDocumentStatusFilter,
				applicationType: selectedApplicationType,
				teamMemberIds: JSON.stringify(teamMemberList),
				email,
				changeEmail,
				zomatoHotLeadsTab: currentTab
			}
			downloadCandidatesPageData(data).then((response) => {
				if (response.data.responseCandidatesUrl) {
					window.open(response.data.responseCandidatesUrl, '_blank')
				}
				else {
					setAlert({
						display: true,
						text: response.data.message,
						type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
					});
				}
				setDownloadLoading(false)
			})
		}
		else {
			setAlert({
				display: true,
				text: 'No data Present',
				type: 'error'
			});
		}
	}

	const whatsAppMessageModalCancel = () => {
		setWhatsAppMessageModal(false);
	};
	const onPhoneNumberChange = async (e) => {
		if (e.target.value.length > 10) {
			setAlert({
				display: true,
				text: 'Please enter only the 10 digit mobile no. without any prefix (+91 or 0)',
				type: 'error'
			});
			setSubmitdisable(true);
			return;
		}
		else {
			const alternatePhone = e.target.value;
			const regex = new RegExp("^[0-9]{10}$");
			if (regex.test(alternatePhone)) {
				setAlternateNumber(alternatePhone);
				setSubmitdisable(false);
			} else {
				setSubmitdisable(true);
			}

		}

	}
	const whatsappModalOpen = () => {
		const lead = leads.find(lead => lead.phoneNumber == stateData.phoneNumber)
		setSelectedLead(lead)
		if (lead?.recommendationHistoriesId || lead?.scheduledInterviewId) {
			let data = {
				recommendationHistoryIds: lead.recommendationHistoriesId,
				scheduledInterviewId: lead?.scheduledInterviewId,
			};
			getViewDetailsData(data).then((result) => {
				setLeadDetails(result.data);
				setWhatsAppMessageModal(true)

			});
		}
	}
	const scrollToTop = () => {
		trackEvent('Click_on_go_to_top_candidates_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		const leadsWrapper = document.getElementsByClassName("leads-wrapper");
		leadsWrapper[0].scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}
	const submitLeadFilter = () => {
		const data = new FormData();

		data.append('userId', selectedLead.id);
		data.append('oldPhoneNumber', currentJob.phoneNumber);
		data.append('userPhoneNumber', currentJob.phoneNumber);
		data.append('phoneNumber', alternateNumber);
		data.append('desiredRole', currentJob.jobType);
		data.append('jobId', currentJob.jobId);
		data.append('sourceName', 'mitra-leader');
		data.append('jobDemandId', currentJob.jobDemandId);
		data.append('sourceId', currentJob.mitraId);
		data.append('name', currentJob.name);
		data.append('clientPreference', currentJob.companyName);
		data.append('cityPreference', currentJob.companyCity);
		data.append('role', currentJob.role);
		data.append('companyCity', currentJob.companyCity);
		data.append('managerMitraID', mitra.managerMitraID ? mitra.managerMitraID : currentJob.mitraId);
		let url = process.env.REACT_APP_BACKEND_URL + '/referAgain';

		let response = axiosInstance.post(url, data, { withCredentials: true });
		setAlert({
			display: true,
			text: 'Trying to refer with other number....'
		});
		response.then(function (response) {
			if (!response.data.status) {
				let message = response.data.message ? response.data.message : 'Sorry, try another number.';
				setAlert({
					display: true,
					text: message,
					type: 'error'
				});
			} else {
				setAlert({
					display: true,
					text: 'successfully referred!!',
					type: 'success'
				});

				setReferAgain(false);
				setFilterApplied(true);
			}
		});
		setSubmitdisable(true);
	}
	const handlePicker = () => {
		setClickRangePicker(!clickRangePicker);
	}

	const handleDateRange = (dates) => {
		setClickRangePicker(!clickRangePicker);
		if (dates === null) {
			setStartDate(moment().subtract(7, 'days').format('YYYY-MM-DD'));
			setEndDate(moment());
		}
		else {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	}

	const clearAll = () => {
		clientSelected.map(item => {
			item.value = false;
		})
		statusesSelected.map(item => {
			item.value = false;
		})
		mitraList.map(item => {
			item.value = false;
		})
		tagsSelected.map(item => {
			item.value = false;
		})
		candidateStatusFilter.map(item => {
			item.value = false;
		})
		setStartDate(moment().subtract(7, 'days').format('YYYY-MM-DD'));
		setEndDate(moment());
		setFilterApplied(true)
	}



	const onClickTags = (label, index) => {
		if (label === 'Priority') {
			statusesSelected[index].value = !statusesSelected[index].value;
		}
		if (label === 'Call Status') {
			tagsSelected[index].value = !tagsSelected[index].value;
		}
		if (label === 'Select Team Member') {
			mitraList[index].value = !mitraList[index].value;
		}
		if (label === 'Candidate Status') {
			candidateStatusFilter[index].value = !candidateStatusFilter[index].value;
		}
		// if(label === 'Clients'){
		// 	clientSelected[index].value=!clientSelected[index].value;
		// }
		// if(label === 'Location'){
		// 	locationList[index].value=!locationList[index].value;
		// }
		// if(label === 'Mitra App (Mitra Clients)' || label === 'Mitra App'){
		// 	mitraAppDownloadedFilter[index].value = !mitraAppDownloadedFilter[index].value;
		// 	mitraAppDownloadedFilter[1 - index].value = false;
		// }
		// if(label === 'Document Status (Mitra Clients)'){
		// 	documentStatusFilter[index].value = !documentStatusFilter[index].value;
		// 	documentStatusFilter[1 - index].value = false;
		// }
		// if(label === 'Application Type'){
		// 	applicationType.map(ele=>ele.value = false);
		// 	applicationType[index].value = !applicationType[index].value;
		// 	// applicationType[1 - index].value = false;

		// }
		forceUpdate();
	}

	const preventDefault = (e, label, item) => {
		e.preventDefault()
		deleteChip(label, item)
		console.log('Clicked! But prevent default.');
	}

	const applyFilterCondition = () => {
		let result = false;
		let arr = [statusesSelected, clientSelected, endDate];
		console.log("statuses selected in applyFilterCondition:>>", statusesSelected);
		arr.map(item => {
			if (item) {
				for (let i = 0; i < item.length; i++) {
					if (item[i].value === true) {
						result = true;
						break;
					}
				}
			}
			if (moment(endDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
				result = true;
			}
		})
		return result;
	}
	const submitFilter = () => {
		setFilterApplied(true)
	}

	const commonTag = (label, arrObj) => {
		return <div className='tag-containers'>
			<div>{label}</div>
			{label === "Date Range" ?
				<Space>
					<RangePicker
						onClick={handlePicker}
						open={clickRangePicker}
						defaultValue={[moment(startDate, dateFormatList[0]), moment(endDate, dateFormatList[0])]}
						value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
						format={dateFormatList}
						onChange={handleDateRange}
						ranges={{
							Today: [moment().startOf('day'), moment().endOf('day')],
							'This Month': [moment().startOf('month'), moment().endOf('month')],
							'Last 7 days': [moment().startOf('day').subtract(6, 'days'), moment().endOf('day')],
							'Last 10 days': [moment().startOf('day').subtract(9, 'days'), moment().endOf('day')],
						}}
						disabledDate={disabledDateLessThan25daysForHotLeads}
						allowClear={false}
						className="range-picker-leads"
					/>
				</Space> :
				label === "Location" ?
					<LocationFilterComponent
						locationList={locationList}
						onClickTags={onClickTags}
						label={label}
						setLocationList={setLocationList}
					/>
					:
					<div className='tag-wrapper-container'>
						{arrObj.map((item, index) => {
							return <Tag className={!item.value ? 'tags' : 'tags tag-active'}
								key={index}
								// closable={item.value}
								onClick={() => { onClickTags(label, index) }}
							>
								{item.label || item.name}
								{item.priority ?
									<img className="imgResponse" style={{ width: 40, heigth: 'auto', marginLeft: '5px' }} alt="priority" src={"/image/" + item.priority + "-priority-hot-lead.png"} />
									: null}
							</Tag>
						})}
					</div>
			}

		</div>
	}
	const onFilterClick = () => {
		setfilterSider(true)
		setClickRangePicker(true)
	};
	const openFilterSlider = () => {
		setfilterSider(true);
		setClickRangePicker(false);
	};

	const handlePageChangeSelection = (value) => {
		trackEvent('candidates_page_tab_switch', { tabName: value }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);

		if (value === 'all') {
			history.push({ pathname: '/leads/all-candidates' })
		} else if (value === 'actionRequired') {
			history.push({ pathname: '/leads/action-needed-candidates' })
		} else if (value === 'leadsToFollowUp') {
			history.push({ pathname: '/recruiter-candidates' })
		} else if (value === 'hotLeads') {
			history.push({ pathname: '/hot-leads-candidates' })
		} else if (value === 'preReferralLeads') {
			history.push({ pathname: '/pre-referral-leads-candidates' })
		}
	}
	// const onChangeTabs = (index) => {
	// 	console.log("index of tab:>>", index);
	// 	if (index === '1') {
	// 		// history.push({pathname: '/leads/all-candidates'})
	// 		setCurrentab('priority1')
	// 		trackEvent('zomatoHotLeadsP1TabClicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	// 		// setBreadcrumb([{link: '/leads/all-candidates', label: 'Candidates'},{link: '/leads/all-candidates', label: 'All Candidates'}])
	// 		setFilterApplied(true)
	// 	} else if (index === '2') {
	// 		// history.push({pathname: '/leads/action-needed'})
	// 		setCurrentab('priority2')
	// 		trackEvent('zomatoHotLeadsP2TabClicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	// 		setTrackUpdatedExpiryDate(false)
	// 		setShowBanner(false)
	// 		// setBreadcrumb([{link: '/leads/all-candidates', label: 'Candidates'},{link: '/leads/action-needed', label: 'Action Needed'}])
	// 		setFilterApplied(true)
	// 		// trackMoengageEvent('action_needed_section_visited');
	// 	} else if (index === '3') {
	// 		setCurrentab('priority3')
	// 		trackEvent('zomatoHotLeadsP3TabClicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	// 		setFilterApplied(true)
	// 	}
	// }

	const getUpdatedExpiryApplications = () => {
		trackEvent('view_expiry_list_candidates_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		setShowBanner(false)
		setTrackUpdatedExpiryDate(true)
		setFilterApplied(true)
	}

	const getLatestRapidoDocumentStatuses = (item) => {
		const paramObject = {
			mitraId: mitra?.id,
			isSingleCandidate: false,
			company: item,
		};

		getRapidoDocumentStatuses(paramObject).then((res) => {
			const data = res.data;
			if (data) {
				if (data.success) {
					notification['success']({
						message: data.message ? data.message : 'Latest Document Status Refreshed Successfully.'
					});
				}
				else {
					notification['error']({
						message: data.message ? data.message : 'Error while Refreshing Latest Document Status.'
					});
				}
			}
			else
				notification['error']({
					message: 'Error while Refreshing Latest Document Status.'
				});
		}).catch((err) => {
			notification['error']({
				message: 'Error while Refreshing Latest Document Status.'
			});
		});
	};

	const onRefreshDocumentStatusButtonClick = (e) => {
		const { key } = e;

		switch (key) {
			case 'rapido':
				trackEvent(`refresh_doc_status_rapido`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
				getLatestRapidoDocumentStatuses(key);
				break;
			case 'rapido_3pl':
				trackEvent(`refresh_doc_status_rapido_3pl`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
				getLatestRapidoDocumentStatuses(key);
				break;
			default:
				break;
		}
	};

	const refreshDocumentStatusMenu = (
		<Menu onClick={(e) => onRefreshDocumentStatusButtonClick(e)}>
			{CONSTANTS.REFRESH_DOCUMENT_STATUS_MENU.map((item, index) => {
				return <Menu.Item key={item.key}>
					{getDisplayableClientName(item.label)}
				</Menu.Item>
			})}
		</Menu>
	);

	const searchCandidate = (e) => {
		setSearchText(e)
		setFilterApplied(true)
		setCurrentPage(1)
	}

	const onReassignLeadsClicked = () => {
		if (allFiltersList?.teamMemberIds?.length == 0) {
			notification.open({
				message: "Please select at least one team member from filters to proceed",
				key,
				duration: 5,
				placement,
				icon: <ExclamationCircleOutlined className='red-negative' />,
			});
		}
		else if (!loaded) {
			notification.open({
				message: "Please wait while candidates are loading.",
				key,
				duration: 5,
				placement,
				icon: <ExclamationCircleOutlined className='red-negative' />,
			});
		}
		else {
			trackEvent('opened_reassignment_dialog_from_listing', {
				page: "hot_leads",
			  }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			setShowLeadReassignDialog(true)
		}
	}

	return (
		<>
			<div className="LeadPage mainContentZ">
				<div className="header">
					<PageHeaderComponent breadcrumb={breadcrumb} />
				</div>
				<div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row' }}>
					<div className='page-filter-options show-only-desktop'>
						<Button onClick={(e) => handlePageChangeSelection('hotLeads')} style={{ display: 'flex', alignItems: 'center', borderRadius: 0 }} type="primary" ghost>
							<FireOutlined />Hot Leads
						</Button>
						<Button onClick={(e) => handlePageChangeSelection('preReferralLeads')} style={{ borderRadius: 0 }}>
							Pre-Referral leads
						</Button>
						<Button onClick={(e) => handlePageChangeSelection('leadsToFollowUp')} style={{ borderRadius: 0, borderLeft: 'none' }}>
							Leads to Follow Up With
						</Button>
						{/* <Button onClick={(e) => handlePageChangeSelection('actionRequired')} style={{ borderRadius: 0 }}>
							Action required
						</Button> */}
						<Button onClick={(e) => handlePageChangeSelection('all')} style={{ borderRadius: 0 }}>
							All
						</Button>
					</div>

					<div style={{ width: '40%', alignItems: 'flex-end', margin: '2%', display: 'flex', justifyContent: 'right' }}>
						<SearchComponent placeholder={"Search Candidates"} searchItem={searchCandidate} />
					</div>

					<Select className='show-only-mobile hot-leads-select'
						style={{ margin: '2%', height: 'fit-content', width: '60%', textAlign: 'left', color: '#D34612' }}
						onChange={handlePageChangeSelection} defaultValue="hotLeads">
						<Select.Option style={{ textAlign: 'left' }} value="leadsToFollowUp">Leads to Follow Up With</Select.Option>
						{/* <Select.Option style={{ textAlign: 'left' }} value="actionRequired">Action required</Select.Option> */}
						<Select.Option style={{ textAlign: 'left' }} value="preReferralLeads">Pre-Referral Leads</Select.Option>
						<Select.Option className="hot-leads-select" style={{ textAlign: 'left', color: '#D34612', backgroundColor: '#FFF' }} value="hotLeads"><FireOutlined style={{ marginRight: '2%' }} />Hot Leads</Select.Option>
						<Select.Option style={{ textAlign: 'left' }} value="all">All</Select.Option>
					</Select>
					<div className='show-only-mobile-search'>
						<Button onClick={() => {
							history.push("/search-page")
						}} style={{ color: '#000000D9', borderColor: '#00000040' }} icon={<SearchOutlined />} danger ghost size={'medium'} ></Button>
					</div>
				</div>
				<div className="more-filters">
					<Space style={{ marginTop: '1%' }}>
						<RangePicker
							open={false}
							defaultValue={[moment(startDate, dateFormatList[0]), moment(endDate, dateFormatList[0])]}
							value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
							format={dateFormatList}
							onClick={onFilterClick}
							allowClear={false}
							className="range-picker-leads"
						/>
					</Space>
					<div className="filter-set-container">
						<Button style={(applyFilterCondition()) ? { display: 'flex', alignItems: 'center', color: '#D34612', backgroundColor: '#FFEAE0', border: '1px solid #D34612' } : { display: 'flex', alignItems: 'center', color: '#D34612', border: '1px solid #D34612' }} onClick={openFilterSlider} icon={<FilterOutlined />}>Filter</Button>
						{/* <div className="desktop-pagination" style={{}}>
							{
								totalApplicationCount > 0 ?
									<Pagination
										total={totalApplicationCount > 0 && totalApplicationCount}
										showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
										defaultPageSize={pageLimit}
										defaultCurrent={currentPage}
										responsive={true}
										showLessItems={true}
										onChange={(page) => { setCurrentPage(page); setFilterApplied(true) }}
										// hideOnSinglePage={true}
										showSizeChanger={false}
									/>
									: null
							}
						</div> */}
					</div>
					<div className="Chips-container">
						{
							jobseekerApplicationInML(mitraReducer?.mitraInfo) && applicationType?.map((single_status, index) => {
								if (single_status.value) {
									return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'applicationType', single_status)}>{single_status.label} </Tag>
								}
							})
						}
						{
							tagsSelected?.map((single_tag, index) => {
								if (single_tag.value) {
									return <Tag key={index} className="single-chip" closable onClose={(e) => preventDefault(e, 'call_status', single_tag)}>{single_tag.label} </Tag>
								}
							})
						}
						{
							mitraList?.map((single_mitra, index) => {
								if (single_mitra.value) {
									return <Tag key={index} className="single-chip" closable onClose={(e) => preventDefault(e, 'team_member', single_mitra)}>{single_mitra.name} </Tag>
								}
							})
						}
						{
							candidateStatusFilter?.map((single_status, index) => {
								if (single_status.value) {
									return <Tag key={index} className="single-chip" closable onClose={(e) => preventDefault(e, 'candidateStatus', single_status)}>{single_status.label} </Tag>
								}
							})
						}
						{/* {
								(startDate !== null && endDate !== null) ?<>
									<Tag className="single-chip"  onClose={(e)=>preventDefault(e,'date',startDate)}> <Moment format="DD MMM YYYY" withTitle>{startDate}</Moment> </Tag>
									<Text style={{marginRight: '3px'}}>to</Text>
									<Tag className="single-chip"  onClose={(e)=>preventDefault(e,'date',endDate)}> <Moment format="DD MMM YYYY" withTitle>{endDate}</Moment> </Tag>
								</>
									: null
							} */}
						{
							trackUpdatedExpiryDate ?
								<Tag className="single-chip" closable onClose={(e) => preventDefault(e, 'updateExpiryFilter', false)}> Expiry Date Changed</Tag>
								: null
						}
						{
							clientSelected?.map((single_category, index) => {
								if (single_category.value) {
									return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'category', single_category)}>{getDisplayableClientName(single_category.label)} </Tag>
								}
							})
						}

						{
							statusesSelected?.map((single_status, index) => {
								if (single_status.value) {
									return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'status', single_status)}>{single_status.label} </Tag>
								}
							})
						}
						{
							[...new Set(locationList)]?.map((single_status, index) => {
								if (single_status.value) {
									return <Tag key={index} className="single-chip" closable onClose={(e) => preventDefault(e, 'location', single_status)}>{single_status.label} </Tag>
								}
							})
						}
						{
							mitraAppDownloadedFilter?.map((single_status, index) => {
								if (single_status.value) {
									return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'mitraAppDownload', single_status)}>{single_status.label} </Tag>
								}
							})
						}
						{
							documentStatusFilter?.map((single_status, index) => {
								if (single_status.value) {
									return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'documentStatus', single_status)}>{single_status.label} </Tag>
								}
							})
						}
					</div>
				</div>

				{
					showBanner ?
						<div style={{ margin: '20px' }}>
							<div className="warning-message-at-risk-container">
								<div className="warning-message-atrisk" >
									<InfoCircleOutlined style={{ color: '#D34612', margin: '13px 10px' }} />
									<div className='flex-desktop-row-mobile-column'>
										<Text style={{ margin: '10px 0px' }}>Expiry dates for some of your candidates have changed due to updated time-gating conditions.</Text>
										<Text className='view-list-updated-expiry' onClick={() => getUpdatedExpiryApplications()}>
											<Button type="primary" style={{ margin: '5px 0px' }}>View list</Button>
										</Text>
									</div>

									{/* <CloseOutlined onClick={() => {setShowBanner(false)}} style={{margin: '5px 10px 0px auto'}} /> */}
								</div>
							</div>
						</div>
						: null
				}

				<Modal style={{ padding: '40px' }} key="informationModal" visible={isModalOpen} open={isModalOpen} onCancel={() => { setIsModalOpen(false); location.state = null; }} footer={[]}>
					<Result
						status="success"
						extra={[
							<h6 key="leadStatus" style={{ color: 'green' }}> Your lead has been referred.</h6>,
							<h3 style={{ textAlign: 'left' }} key="infoModalTextHeader">Complete the	 steps below to get your lead activated.</h3>,

							...candidatePostReferralSteps,
							<br key="break" />,
							<Button style={{
								background: '#53c31b',
								color: '#ffff',
								borderRadius: '5px'
							}} onClick={() => { whatsappModalOpen(); }} key="whtsappMessageButton">Message On <WhatsAppOutlined style={{ fontSize: '20px' }} /></Button>,
						]}
					/>
				</Modal>
				{
					whatsAppMessageModal ? <WhatsappTemplateModal
						lead={selectedLead}
						leadDetails={leadDetails}
						whatsAppMessageModal={whatsAppMessageModal}
						whatsAppMessageModalCancel={whatsAppMessageModalCancel}
					></WhatsappTemplateModal> : null
				}

				{/* Conditional button based on isAdmin */}
				{/* {isAdmin && */}
				{

				}


				<div style={{ width: '100%', textAlign: 'end', marginRight: '20px' }}>
					{
						hasReassignAccess && totalApplicationCount > 0 ?
							// disabled = { allFiltersList?.teamMemberIds?.length > 0}
							<Button style={{ margin: '5px 20px 5px auto' }} onClick={onReassignLeadsClicked} className={`${allFiltersList?.teamMemberIds?.length == 0 ? "opacity04" : ""}`}>
								Re-assign
							</Button>
							:
							null
					}
					{
						hasDownloadAccess ?
							<Button danger loading={downloadLoading} style={{ margin: '5px 20px 5px auto' }} onClick={() => { handleDownload(); AllCandidatesGAEventsTracker("DownloadAllCandidatesData"); }}>
								Download File
							</Button>
							:
							null
					}
				</div>



				{/* } */}

				<div className="leads-wrapper">

					{loaded ? leads.length === 0 ? (
						<div style={{ margin: '20px auto', height: '68vh' }}>
							<Empty
								description={
									<span>
										<button onClick={() => history.push('/add-single-candidate')} style={{ textDecoration: 'underline', color: 'red', fontWeight: '600' }}>Refer Candidates</button> to see data here.
									</span>
								} />
						</div>
					) : (
						leads.map((lead, index) => (
							<HotLead
								key={index}
								lead={lead}
								leadDetails={leadDetails}
								setShowDetail={setShowDetail}
								setSelectedLead={setSelectedLead}
								setReferAgain={setReferAgain}
								setCurrentJob={setCurrentJob}
								setSpin={setSpin}
								mitra={mitra}
								setSelectedScheduledInterviewId={setSelectedScheduledInterviewId}
								setSelectedRecommendationHistoryId={setSelectedRecommendationHistoryId}
							/>
						))
					) : (
						<div style={{ height: "65vh", width: "100%" }}>
							<NewLoader comingFromPage={'leadsPage'} />
						</div>
					)}
					<br />
					{/* <div className="mobile-pagination" style={{}}>
						{
							totalApplicationCount > 0 ?
								<Pagination
									total={totalApplicationCount > 0 && totalApplicationCount}
									showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
									defaultPageSize={pageLimit}
									defaultCurrent={currentPage}
									responsive={true}
									showLessItems={true}
									onChange={(page) => { setCurrentPage(page); setFilterApplied(true) }}
									showSizeChanger={false}
								/>
								: null
						}
					</div> */}
					<br />
					<a id="back-to-top" className="cta hideCta" onClick={() => scrollToTop()}>↑</a>
					<br />
				</div>
				<div className="bottom-detail-wrapper">
					{
						showDetail && (
							<CandidateProfilePopupV2
								selectedScheduledInterviewId={selectedScheduledInterviewId}
								selectedRecommendationHistoryId={selectedRecommendationHistoryId}
								visible={showDetail}
								setShowDetail={setShowDetail}
								lead={selectedLead}
								setRaiseIssueSider={setRaiseIssueSider}
								setViewDetailLead={setViewDetailLead}
								reassignAccess={hasReassignAccess}
								leadReassignedViaPopup={getHotLeadCandidates}
								page={"hot_leads"}
							>

							</CandidateProfilePopupV2>)
					}
				</div>
			</div >
			{referAgain &&
				<SideSheet submit={{ disabled: submitdisable, onClick: submitLeadFilter }} sidername='Add New Number' onDismiss={() => setReferAgain(false)}>
					<div className="siderInnerContainer1">
						<div className="outerContainer">
							<SideInput onChange={onPhoneNumberChange} placeholder={'Example : 8884228005'}
								imgsrc={'phone.svg'} labelname={"* Add Alternate Number"} />
							<SideInput readOnly={true} value={selectedLead.phoneNumber}
								imgsrc={'phone.svg'} labelname={"Contact Number"} />
						</div>
					</div>
				</SideSheet>
			}
			{
				filterSider &&
				<SideSheet submit={{
					disabled: false
					, onClick: submitFilter
				}}
					clearAll={{ disabled: false, onClick: clearAll }}
					sidername={"Filter Candidates"}
					onDismiss={() => { setfilterSider(false) }}>
					<div className='filter-home-wrapper'>
						{/* {jobseekerApplicationInML(mitraReducer?.mitraInfo) && commonTag("Application Type", applicationType)} */}
						{commonTag("Date Range", null)}
						{commonTag("Priority", statusesSelected)}
						{commonTag("Call Status", tagsSelected)}
						{commonTag("Select Team Member", mitraList)}
						{commonTag("Candidate Status", candidateStatusFilter)}
						{/* {commonTag("Clients",clientSelected)} */}
						{/* {commonTag("Location", locationList)} */}
						{/* {commonTag(rollOutRecruitmentMilestoneVisibility(mitraReducer?.mitraInfo) ? "Mitra App" : "Mitra App (Mitra Clients)", mitraAppDownloadedFilter)} */}
						{/* {commonTag("Document Status (Mitra Clients)", documentStatusFilter)} */}
					</div>
				</SideSheet>
			}
			{
				raiseIssueSider &&
				<RaiseIssueSider
					closeSider={() => setRaiseIssueSider(false)}
					mitraReducer={mitraReducer}
					selectedLead={viewDetailLead}
				/>
			}

			{
				showLeadReassignDialog &&
				<Modal
					key="leadReassignDialog"
					title={null}
					centered
					visible={showLeadReassignDialog}
					open={showLeadReassignDialog}
					footer={null}
					width={600}
					height={800}
					className='reassign-leads-dialog'
					onCancel={() => { setShowLeadReassignDialog(false) }}
				>
					<ReassignLeadsDialog closeModal={() => { setShowLeadReassignDialog(false) }} showHeader={true} totalFilteredCandidates={totalApplicationCount} allFiltersFromParent={allFiltersList} allTeamMembersList={mitraList} isBulkReassignment={true} pageType={pageTypeforLeadReassignment} isLeadReassigned={getHotLeadCandidates} />
				</Modal>
			}

			{
				showVerifyEmailPopup &&
				(
					<VerifyEmailPopup mitra={mitra} visible={showVerifyEmailPopup} closePopup={setShowVerifyEmailPopup} downloadFunction={handleSheet} changeEmail={changeEmail} setChangeEmail={setChangeEmail}></VerifyEmailPopup>
				)
			}
		</>

	);
};

export default HotLeadsPage;
