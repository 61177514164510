import React  ,{useEffect, useRef}from 'react';
import '../../../css/lead.scss';
import useGAEventsTracker from '../../../service/useGSEventsTracker';
import {EnvironmentOutlined} from '@ant-design/icons';
import {Card, Typography} from 'antd';
import SingleApplicationContainer from './SingleApplicationContainer.component'
import CandidateDetailsHeader from './CandidateDetailsHeader.component'


const {Text}= Typography;

const Lead = ({key, lead, leadDetails, setShowDetail, setSelectedLead,setReferAgain, setCurrentJob, setSpin, mitra,setSelectedScheduledInterviewId,setSelectedRecommendationHistoryId, realtimeUniquenessStatusData,siToCheckUniquenessStatus,showLoaderForUniqueness, showCompleteApplicationForUniqueSi, isUniquenessPending}) => {
  const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
  const candidateCardRef = useRef(null);
  const referagainBool = (index) => checkStatus({scheduledInterviewId: lead.scheduledInterviewId[index], productSource: lead.productSource.length > 0 ? lead.productSource[index] : null, parentPhoneNumber: lead.parentPhoneNumber ? lead.parentPhoneNumber[index] : null, childPhoneNumber: lead.childPhoneNumber?.length > 0 ? lead.childPhoneNumber[index] : null, companyName: lead.companies[index], status: lead.latestMilestone[index]});

  const checkStatus = (job) => {
    let data = false;
    if(job.scheduledInterviewId !== null && job.productSource !=='CrossReferRapidoToZomato' && job.productSource !== 'CrossReferShadowfaxToZomato' && job.parentPhoneNumber === null && job.childPhoneNumber === null && (job.companyName?.toLowerCase().indexOf('shadowfax') !== -1 || job.companyName?.toLowerCase().indexOf('zomato') !== -1)) { 
      const lastStatus = job.status;
      if(lastStatus && (lastStatus === "Candidate Not Unique" || lastStatus ==='Non Unique')) {
        data = true; 
     }
    }
    return data
  }

  return (
    <Card
        className='card-view-candidate'
        ref={candidateCardRef}
        hoverable>
            {/* <CandidateDetailsHeader lead={lead} setShowDetail={setShowDetail} setSelectedLead={setSelectedLead} /> */}
            {
                lead.recommendationHistoriesId.map((singleApplication, index) => {
                    if(referagainBool(index) && candidateCardRef.current){
                        candidateCardRef.current.style.backgroundColor='#FFF'
                    } 

                    // here client refers to Companies(Uber,Zomato etc)
                    let currentClientSiId=lead.scheduledInterviewId[index];
                    let currentClientRhId=singleApplication;
                    // console.log("lead:>>", lead); // RIP
                    // console.log("lead.latestMilestone:>>", lead.latestMilestone); // RIP
                    if((!lead.latestMilestone || (lead.latestMilestone && lead.latestMilestone[0] == null) && lead.companies[0].toLowerCase().includes('zomato'))) {
                      lead.latestMilestone = ['Lead Referred']
                    }
                    if(lead.zomatoMilestones) {
                      // console.log("lead.zomatoMilestones:>>", lead.zomatoMilestones) // RIP
                      // lead.zomatoMilestones = JSON.parse(lead.zomatoMilestones); // RIP
                    }

                    // let showViewDetails = index === 0 ? true : true
                    return <SingleApplicationContainer 
                    key={key} 
                    lead={lead} 
                    leadDetails={leadDetails}
                    index={index} 
                    setSpin={setSpin} 
                    setShowDetail={setShowDetail} 
                    showViewDetails={true} 
                    setSelectedLead={setSelectedLead} 
                    checkStatus={checkStatus} 
                    referagainBool={referagainBool} 
                    setReferAgain={setReferAgain} 
                    setCurrentJob={setCurrentJob}
                    mitra={mitra} 
                    setSelectedScheduledInterviewId={setSelectedScheduledInterviewId} 
                    currentClientSiId={currentClientSiId}
                    currentClientRhId={currentClientRhId}
                    setSelectedRecommendationHistoryId={setSelectedRecommendationHistoryId}
                    isUniquenessPending={isUniquenessPending}
                    realtimeUniquenessStatusData={realtimeUniquenessStatusData}
                    siToCheckUniquenessStatus={siToCheckUniquenessStatus}
                    showLoaderForUniqueness={showLoaderForUniqueness}
                    showCompleteApplicationForUniqueSi={showCompleteApplicationForUniqueSi}
                    />
                })
            }
    </Card>
  );
};
export default Lead;
