import React, { useContext, useEffect, useState } from 'react'
import {InfoCircleOutlined, UploadOutlined,DownloadOutlined, LinkOutlined, CloseCircleOutlined} from '@ant-design/icons';
import PageHeaderComponent from '../common/PageHeader';
import {Divider, Input, InputNumber, Modal, Radio, Select, Upload, message, notification} from 'antd';
import './SamvadiniModuleStyles.scss';
import { Button } from 'antd';
import { AppContext } from '../../App';
import { postBulkIntentDataForSamvadiniCalls } from '../../service/SamvadiniApiService';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';

const { Option } = Select;

function SamvadiniFileUpload({getAllSessionList, sessionList, allowToCreateNewSession}) {
    const [uploadedFile, setUploadedFile] = useState(null);
    const [errorField, setErrorField] = useState(null);
    const {mitraReducer, setSpin} = useContext(AppContext);
    const [selectedRadioOption, setSelectedRadioOption] = useState(null);
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [candidateCount, setCandidateCount] = useState(null);
    const [selectedDuration, setSelectedDuration] = useState(null);
    const [disableSubmission, setDisableSubmission] = useState(true);
    const [throughputType, setThroughputType] = useState([
        {
            id: 0,
            label: 'Excel Upload',
            key: 'File Upload',
            isSelected: true
        },
        {
            id: 1,
            label: `Vahan's Smart Logic`,
            key: 'Smart Select',
            isSelected: false
        }
    ]);

    const showLoader = (value, message=null) => {
        setSpin({
            loading: value, //false or true when actived
            delay: null,
            tip: message || 'Uploading document, please wait..'  //Loading copy here
        })
    }

    const [durationDetails, setDurationDetails] = useState([]);

    const props = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        customRequest: async ({ file, onSuccess }) => {
            setTimeout(() => {
                onSuccess("ok");
            }, 0);
        },
        onChange(info) {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                
                setUploadedFile(info?.fileList?.[0]?.originFileObj);
                setShowFileUploadModal(false);

                trackEvent('ml_samvadini_file_selected', {name: info.file.name, fileObj: info?.fileList?.[0]?.originFileObj}, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file uploading failed`);
                trackEvent('ml_samvadini_file_selection_error', {name: info.file.name}, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);
            } else if (info.file.status === "removed") {
                setUploadedFile([])
            }
        },
        maxCount: 1,
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
    };

    const resetForm = () => {
        setSelectedRadioOption(null);
        setUploadedFile(null);
        setCandidateCount(null);
        setSelectedDuration(null);
        setDisableSubmission(true);
    }

    const onDownloadTemplateClicked = () => {
        const link = document.createElement('a');
        link.href = "https://mesl-uploads-2.s3.ap-southeast-1.amazonaws.com/intent_calling_upload_template.xlsx";
        link.download = 'template.xlsx'; // Specify the desired filename

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    const onChange = (e) => {
        setSelectedRadioOption(e.target.value);

        trackEvent('ml_samvadini_chosen_candidate_selection_mode', {value: e?.target?.value}, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);
    };

    const handleChange = (value, o) => {
        setSelectedDuration(value);

        trackEvent('ml_samvadini_selected_duration', {value}, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);
    };

    const evaluateDuration = () => {
        const modifiedDuration = [];

        for (let minutes = 15; minutes <= 360; minutes+=15) {
            let hours = 0;
            let mins = 0;
            if(minutes >=60) {
                hours = minutes/60;
                mins = minutes%60;
            } else{
                mins=minutes;
            }

            modifiedDuration.push(
                {
                    label: hours ? `${parseInt(hours)} hour(s) ${mins ? `${mins} minutes`: ''}`: `${mins} minutes`, 
                    key: minutes, 
                    value: minutes
                }
            );
        }

        setDurationDetails([...modifiedDuration]);
    }

    useEffect(() => {
        evaluateDuration();
    }, [])

    useEffect(() => {
      if(!candidateCount || !selectedDuration || !selectedRadioOption) {
        setDisableSubmission(true);
        return;
      }

      if(selectedRadioOption === 'File Upload' && !uploadedFile?.name) {
        setDisableSubmission(true);
        return;
      }

      if(candidateCount && (parseInt(candidateCount)<5 || parseInt(candidateCount)>200)) {
        setDisableSubmission(true);
        return;
      }

      setDisableSubmission(false);
    }, [uploadedFile,selectedRadioOption,candidateCount, selectedDuration])
    
    
    const fileUploadInstructions = () => {
        return (
            <div style={{textAlign: 'start'}}>
                <b>{`Note:`}</b>
                <ol>
                    <li className='list-style'>{`Please upload at least 4 times the number of required candidates.`}</li>
                    <li className='list-style'>{`All candidates should be unique and should have been referred by you not more than 30 days ago.`}</li>
                    <li className='list-style'>{`No candidate should be in Onboarded or First Trip Stage`}</li>
                    <li className='list-style'>{`Only upload fresh candidates which your team hasn’t already called.`}</li>
                </ol>
            </div>
        )
    }

    const checkIfAllTHeFieldsArePopulated = () => {
        if(!candidateCount) {
            notification['error']({
                message: 'Enter interested candidates.'
            });
            return false;
        } else {
            if(parseInt(candidateCount)<5 || parseInt(candidateCount)>200) {
                notification['error']({
                    message: 'Interested candidates should be between 5 and 200.'
                });
                return false;
            }
        }
        if(!selectedDuration) {
            notification['error']({
                message: 'Select a duration.'
            });
            return false;
        }
        if(!selectedRadioOption) {
            notification['error']({
                message: 'Choose selection mode.'
            });
            return false;
        }

        if(selectedRadioOption) {
            if(selectedRadioOption === 'File Upload') {
                if(!uploadedFile?.name) {
                    notification['error']({
                        message: 'Upload the file to continue.'
                    });
                    return false;
                }
            }
        }
        console.log("allowToCreateNewSession:>>", allowToCreateNewSession);
        if(sessionList?.length && allowToCreateNewSession === false) {
            notification['error']({
                message: 'Previous uploads are in process, please wait till it completes..'
            });

            trackEvent('ml_samvadini_upload_failed_prev_session_still_in_process', {}, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);
            return false;
        }

        return true;
    }

    const submitDetails = async() => {

        trackEvent('ml_samvadini_clicked_form_submit', {}, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);

        if(checkIfAllTHeFieldsArePopulated()) {

            try {

                showLoader(true);
                const payload = {
                    expectedLeadCount: candidateCount,
                    sessionDuration: selectedDuration,
                    mitraId: mitraReducer?.mitraInfo?.id,
                    type: selectedRadioOption,
                    files: uploadedFile,
                    isManagerMitra: mitraReducer?.mitraInfo?.managerMitraID ? false: true,
                }

                const response = await postBulkIntentDataForSamvadiniCalls(payload);

                if(response?.data?.status) {
                    if(response?.data?.warning) {
                        notification['warning']({
                            message: response?.data?.warning
                        });
                    } else {
                        notification['success']({
                            message: 'Details submitted.'
                        });
                    }
        
                    resetForm();
                    getAllSessionList();
                    showLoader(false);

                    trackEvent('ml_samvadini_session_successfully_uploaded', {}, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);
                } else {
                    showLoader(false);
                    notification['error']({
                        message: response?.data?.message || 'Error in submitting the response.'
                    });

                    trackEvent('ml_samvadini_session_upload_api_failed', {reason: response?.data?.message}, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);
                }
            } catch (e) {
                showLoader(false);
                notification['error']({
                    message: 'Something went wrong, please try again.'
                });

                trackEvent('ml_samvadini_session_upload_api_failed', {reason: JSON.stringify(e)}, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);
            }
        }
    }

    const handleCandidateCountChange = (event) => {
        const inputValue = event.target.value;
        // Check if the input value is numeric using a simple regex
        if (/^\d*$/.test(inputValue)) {
            setCandidateCount(inputValue);

            trackEvent('ml_samvadini_entered_candidate_count', {inputValue}, CONSTANTS.ENGAGEMENT_TYPES.MOENGAGE);

            if(parseInt(inputValue) < 5 || parseInt(inputValue) >200) {
                setErrorField('candidateCount');
            } else {
                setErrorField(null);
            }
        }
    }

  return (
    <div className='upload-file-container'>
        <div className='samvadini-upload-header'>
            Candidate Requirement
        </div>

        <div className='form-container-samvadini'>
            <div className='individual-field-container'>
                <label className='text-align-start field-label'>Interested Candidates Required:</label>
                <div className='d-flex flex-column align-items-start' style={{flex: 1, marginTop: '18px'}}>
                    <Input style={errorField === 'candidateCount' ? {borderColor: '#D34B18'}:null} placeholder="Candidate count" className='input-width' value={candidateCount} onChange={handleCandidateCountChange}/>
                    <div style={errorField === 'candidateCount' ? {color: '#D34B18',fontSize: '12px', fontWeight: 600}:{color: '#0000008c',fontSize: '12px'}}>Should be in the range of 5-200.</div>
                </div>
            </div>
            <div className='individual-field-container'>
                <label className='text-align-start field-label'>Calling Duration:</label>
                <Select
                    placeholder="Select duration"
                    className='input-width'
                    onChange={(v,o)=>handleChange(v,o)}
                    options={durationDetails}
                    value={selectedDuration}
                />
            </div>
        </div>

        <div className='throughput-selection-container'>
            <label className='throughput-header'>Candidate selection mode: </label>
            <Radio.Group onChange={onChange} value={selectedRadioOption}>
            {
                throughputType?.map((item, index) => {
                    return (
                        <Radio key={item.id} value={item.key}>{item.label}</Radio>
                    )
                })
            }
            </Radio.Group>
        </div>

        <div className='throughput-details-container'>
            {
                selectedRadioOption === 'File Upload' ? (
                <>
                    {/* <Upload {...props} className='button-container'>
                        <Button className='file-upload-button'><UploadOutlined /> Select a file</Button>
                    </Upload> */}
                    <div className={`button-container ${uploadedFile?.name ? '':'flex-column'} align-items-center flex-wrap`} style={{gap: '8px'}}>
                        {
                            uploadedFile?.name ? (
                                <>
                                    <div style={{fontWeight:600}}>Uploaded File:</div>
                                    <div className='uploaded-file-details'>
                                        <LinkOutlined />
                                        {uploadedFile?.name}
                                        <div className='remove-file-icon' onClick={()=> setUploadedFile(null)}><CloseCircleOutlined style={{color:'#D34B18', fontWeight: 600}}/></div>
                                        
                                    </div>
                                </>
                            ): (
                                <div className='d-flex align-items-center flex-col-mobile'>
                                    <Button className='file-upload-button' onClick={()=>setShowFileUploadModal(true)}><UploadOutlined /> Select a file</Button>
                                    <div className='template-container'>
                                        <div className='template-header-text'><Button className='template-download-button' onClick={onDownloadTemplateClicked}><DownloadOutlined /> Download template</Button></div>
                                    </div>
                                </div>
                            
                            )
                        }
                    </div>
                    
                </>
                ):
                selectedRadioOption === 'Smart Select' ? (
                    <>
                        <div className='header-info'>
                            <InfoCircleOutlined style={{marginTop: '4px'}}/>
                            <div className='template-header-text' style={{marginTop: 0}}>We will automatically select candidates referred by you.</div>
                        </div>
                        
                    </>
                ): null
            }
        </div>

        <div className='file-upload-bottom-container'>
            <Button className='file-upload-button prim-button' onClick={submitDetails} disabled={disableSubmission} style={disableSubmission ? {opacity: '0.4'}:{}}>Submit</Button>
        </div>

        <div className='samvadini-upload-modal-container'>
            <Modal 
            title="File Upload" 
            visible={showFileUploadModal}
            onCancel={()=>setShowFileUploadModal(false)}
            className="samvadini-modal"
            footer={[
                <Upload key={1} {...props} className='button-container'>
                    <Button className='file-upload-button'><UploadOutlined /> Select a file</Button>
                </Upload>
            ]}
            >
                {fileUploadInstructions()}
            </Modal>
        </div>
    </div>
  )
}

export default SamvadiniFileUpload