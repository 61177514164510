import React, { useEffect, useState } from 'react';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { InfoCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Card, Tooltip, Skeleton, Typography, Divider } from 'antd';
import '../../css/report.scss'

const { Text } = Typography;


const MitraAppDownloadsSummary = ({ performanceDashboardData, mitraDispatch }) => {
    const history = useHistory();
    const routing = (title) => {
        trackEvent("viewed_candidates_list_overall_summary", {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)

        const { search } = history.location;
        const newSearch = new URLSearchParams(search);

        history.push({
            pathname: '/leads-page',
            search: `${title}&${newSearch}`
        })
        mitraDispatch()
    }

    return (
        <>
            {
                performanceDashboardData ?
                    <div className='overall-summary-box-mitra-app-downloads' >
                        <div className='remaining-milestone-container' >
                            {
                                performanceDashboardData.mitraAppDownloads &&
                                    performanceDashboardData.mitraAppDownloads.milestones &&
                                    performanceDashboardData.mitraAppDownloads.milestones.length ? performanceDashboardData.mitraAppDownloads.milestones.map((item, index) => (

                                        item ?
                                            <Card className='overall-card' key={index}>
                                                <div className='overall-header'>
                                                    <div style={{ backgroundColor: item && item.color ? item.color : "" }} className='summary-color'></div>
                                                    <Text className='summary-header'>{item.label}</Text>
                                                    <Tooltip title={item.description}>
                                                        <InfoCircleOutlined />
                                                    </Tooltip>
                                                </div>
                                                <div className='overall-number' onClick={() => routing(item.label)}>
                                                    <Text className='number'>{item.count}</Text>
                                                    <RightCircleOutlined className='right-circle-icon' />
                                                </div>
                                            </Card>
                                            :
                                            null

                                    )) : null
                            }
                        </div>
                        <div className='overall-summary-static-container'>
                            <div className='total-referral-box'>
                                <div className='total-referral-container'>
                                    <div className='vahan-download-container d-flex align-items-center'>
                                        <Text style={{ marginRight: '5px' }}>{performanceDashboardData.mitraAppDownloads?.value === 'total_mitra_app_downloads' ? 'Total Vahan App Downloads' : performanceDashboardData.mitraAppDownloads?.label}</Text>
                                        <Tooltip title={performanceDashboardData.mitraAppDownloads?.description}>
                                            <InfoCircleOutlined />
                                        </Tooltip>
                                    </div>
                                    {/* <Text className='total-referral-number' style={{marginTop: '10px'}}>{performanceDashboardData.mitraAppDownloads?.count}</Text> */}
                                    <div className='d-flex align-items-center' style={{ marginTop: '10px' }} onClick={() => routing(performanceDashboardData.mitraAppDownloads?.value === 'total_mitra_app_downloads' ? 'Total Vahan App Downloads' : performanceDashboardData.mitraAppDownloads?.label)}>
                                        <Text className='total-referral-number'>{performanceDashboardData.mitraAppDownloads?.count}</Text>
                                        <RightCircleOutlined className='right-circle-icon' />
                                    </div>
                                </div>

                                <Divider />

                                <div className='d-flex vahan-app-pre-ob'>
                                    {
                                        performanceDashboardData.mitraAppDownloads && performanceDashboardData.mitraAppDownloads.children && performanceDashboardData.mitraAppDownloads.children.map((child, index) => {
                                            return (
                                                <div className='pre-ob-box-container' style={{ borderLeft: index !== 0 ? '1px solid #f0f0f0' : '' }} key={index}>
                                                    <div className='total-referral-container'>
                                                        <div className='d-flex align-items-center pre-ob-text'>
                                                            <Text style={{ marginRight: '5px' }}>{child.label}</Text>
                                                            <Tooltip title={child.description}>
                                                                <InfoCircleOutlined />
                                                            </Tooltip>
                                                        </div>
                                                        <div className='d-flex align-items-center' style={{marginTop: '10px'}} onClick={() => routing(child.label)}>
                                                            <Text className='total-referral-number'>{child.count}</Text>
                                                            <RightCircleOutlined className='right-circle-icon' />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <Skeleton active />
            }
        </>
    )
}

export default MitraAppDownloadsSummary;
