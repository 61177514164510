import axios from "axios";

export const getZomatoCorrectionFormFields = (data) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/getCorrectionDetails`
    return axios.post(url, data, {withCredentials: true});
}

export const updateZomataCorrectionDetails = (data) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/updateCorrectionDetails`
    return axios.post(url, data, {withCredentials: true});
}

export const sendPostObOTP = (data) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/sendPostObOTP`
    return axios.post(url, data, {withCredentials: true});
}

export const verifyPostObOTP = (data) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/verifyPostObOTP`
    return axios.post(url, data, {withCredentials: true});
}

export const postObOtpStatus = (data) => {
    const url = `${process.env.REACT_APP_BACKEND_URL}/zomato/postObOtpStatus`
    return axios.post(url, data, {withCredentials: true});
}