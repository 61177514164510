import React, { useRef, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import '../../css/lead.scss';
import { AdditionalFormsURLEnum } from '../../utils/utility';
import WhatsappTemplateModal from '../whatsappTemplateModal/WhatsappTemplateModal';
import useGAEventsTracker from '../../service/useGSEventsTracker';
import moment from "moment";
// import useMoengage from '../../hooks/useMoengage';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { UpOutlined, DownOutlined, CheckOutlined, CloseOutlined, CheckCircleOutlined, DashOutlined, CalendarOutlined, ThunderboltFilled, PhoneOutlined, CopyOutlined, WhatsAppOutlined, UserOutlined, ArrowRightOutlined, ArrowUpOutlined, EnvironmentOutlined, UserAddOutlined, ExclamationCircleOutlined, PhoneFilled, PlusOutlined, UploadOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Card, Tag, Space, Typography, Divider } from 'antd';
import { getViewDetailsData } from '../../service/leadService';
import { AppContext } from '../../App';
import { rollOutCallHistory } from '../../constants/utils';
import CallerNumberSelectionModal from '../kaleyraCallComponents/callerNumberSelectionModal';
import { getRecentCallingNumbers } from '../../service/kaleyraCallService';

const { Text } = Typography;

const RecruiterCard = ({ key, lead, setShowDetail, setSelectedLead, setSpin, setSelectedScheduledInterviewId, setSelectedRecommendationHistoryId }) => {
  const { mitraReducer, mitraDispatch } = useContext(AppContext);
  const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
  const history = useHistory();
  // const [createMoengageUser, trackEvent] = useMoengage();
  const candidateCardRef = useRef(null);
  const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);
  const [leadDetails, setLeadDetails] = useState([]);
  const [showCallerSelectionModal, setShowCallerSelectionModal] = useState(false);
  const [callerNumberList, setCallerNumberList] = useState([]);
  const [selectedApplicationIndex, setSelectedApplicationIndex] = useState(null);
  const [callLogsOpened, setCallLogsOpened] = useState(false);
  const closeCallerSelectionModal = () => {
    setShowCallerSelectionModal(false);
  }

  const getLeadDetails = (lead) => {
    if (lead?.recommendationHistoriesId || lead?.scheduledInterviewId) {
      let data = {
        recommendationHistoryIds: lead.recommendationHistoriesId,
        scheduledInterviewId: lead?.scheduledInterviewId,
      };
      getViewDetailsData(data).then((result) => {
        setLeadDetails(result.data);
      });
    }
  }

  const checkStatus = (job) => {
    let data = false;
    if (job.scheduledInterviewId !== null && job.productSource !== 'CrossReferRapidoToZomato' && job.productSource !== 'CrossReferShadowfaxToZomato' && job.parentPhoneNumber === null && job.childPhoneNumber === null && (job.companyName.toLowerCase().indexOf('shadowfax') !== -1 || job.companyName.toLowerCase().indexOf('zomato') !== -1)) {
      const lastStatus = job.status;
      if (lastStatus && (lastStatus === "Candidate Not Unique" || lastStatus === 'Non Unique')) {
        data = true;
      }
    }
    return data
  }
  const onClickUpDown = (event) => {
    event.stopPropagation();
    if(!callLogsOpened) {
        trackEvent('viewed_expandable_call_notes', { candidate_phone_number: lead.phoneNumber }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    }
    setCallLogsOpened(!callLogsOpened);
}

  const viewAdditionalForm = (lead) => {
    // localStorage.setItem('lead', JSON.stringify(lead))
    if (lead?.companyName?.toLowerCase().indexOf('zomato') !== -1) {
      history.push({
        pathname: '/zomato-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search: new URLSearchParams(lead).toString()
      })
    } else if (lead?.companyName?.toLowerCase().indexOf('blinkit') !== -1) {
      history.push({
        pathname: '/blinkit-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search: new URLSearchParams(lead).toString()
      })
    } else if (lead?.companyName?.toLowerCase().indexOf('swiggy soc') !== -1) {
      history.push({
        pathname: '/swiggy-soc-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search: new URLSearchParams(lead).toString()
      })
    } else if (lead?.companyName?.toLowerCase() === 'zepto') {
      history.push({
        pathname: '/zepto-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search: new URLSearchParams(lead).toString()
      })
    } else if (lead?.companyName?.toLowerCase().indexOf('swiggy') !== -1) {
      history.push({
        pathname: '/swiggy-additional-form',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search: new URLSearchParams(lead).toString()
      })
    } else {
      history.push({
        pathname: '/moreInfo',
        route: !lead.scheduledInterviewId ? 'jobRecommendationPage' : null,
        search: new URLSearchParams(lead).toString()
      })
    }

  }

  const viewAdditionalFormHandler = (lead, index) => {
    viewAdditionalForm({ name: lead.lastName.includes('Unknown') ? lead.firstName : lead.firstName + " " + lead.lastName, phoneNumber: lead.phoneNumber, scheduledInterviewId: lead.scheduledInterviewId[index], companyName: lead.companies[index], jobId: lead.jobId[index], jobDemandId: lead.jobDemandId[index] });
  }

  const onClickViewDetails = (e, selectedSiId, selectedRhId) => {
    setSelectedScheduledInterviewId([selectedSiId]);
    setSelectedRecommendationHistoryId([selectedRhId]);
    trackEvent('view_details_button_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    setSpin({
      loading: true, //false or true when actived
      delay: null,
      tip: 'Loading...'  //Loading copy here
    })
    setShowDetail(true);
    setSelectedLead(lead);
    GAEventsTracker('OpenViewDetails')
    setSpin({
      loading: false, //false or true when actived
      delay: null,
      tip: 'Loading...'  //Loading copy here
    })
    e.stopPropagation();
  }

  const onClickCompleteApplication = (index) => {
    viewAdditionalFormHandler(lead, index);
    trackEvent('complete_application_clicked_on_candidate_card', { candidate_phone_number: lead.phoneNumber, application_id: lead.scheduledInterviewId[key] }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
  }

  const whatsAppMessageModalCancel = (e) => {
    e.stopPropagation();
    setWhatsAppMessageModal(false);
  };
  const callNotesArray = (index) => {
    return (
        <>
        <div className='hot-tip-main-container' style={{marginTop: '10px'}}>
            <PhoneFilled className='call-notes-icon' /> 
            <span className='call-notes-heading'>Call Notes</span>
        </div>
        <div className='call-notes-tag'>
            {lead?.callLogsTagsArray[index]?.map((item, idx) => (
                item ?
                    (item.toLowerCase().includes('not connected') ?
                        <Tag style={{marginTop: 8}}><CloseOutlined className='not-connected-tag' />{item}</Tag> :
                        (item.toLowerCase().includes('connected') ?
                        <Tag style={{marginTop: 8}}><CheckOutlined className='connected-tag' />{item}</Tag>:
                        <Tag style={{marginTop: 8}}>{item}</Tag>)
                        ) : null
            ))}
            {lead.callLogCommentArray?.[index]?.length && lead.callLogCommentArray[index] != "" ?
                <div className='call-notes-comment'>
                    "{lead.callLogCommentArray[index]}"
                </div>
            : null}
        </div>
        </>
    )
  };

  const returnLineImage = (lineLength = 0, dashed = false, isMobile = false) => {
    return (
        Array.from({ length: lineLength }).map(() => {
            return isMobile ? (
                dashed ? <div style={{ width: "1px", height: "4px", backgroundColor: "rgba(0, 0, 0, 0.15)", marginLeft: "7px", marginTop: "2px" }}></div> : <div style={{ width: "1px", height: "6px", backgroundColor: "rgba(0, 0, 0, 0.15)", marginLeft: "7px" }}></div>
            ) : (
                dashed ? <DashOutlined className='application-timeline-line-img' /> : <hr className='hr-no-margin' style={{ width: "4px", height: "1px", border: 'none', borderTop: '1px solid rgba(0, 0, 0, 0.15)' }} />
            )
        })
    )
  }

  const setCallInformation = (lead, fromNumber, currentSiId) => {
    const receiver = lead.parentPhoneNumber[0] ? lead.parentPhoneNumber[0] : lead.phoneNumber;
    // mitraDispatch({type:'KaleyraCallDetails', value: {callerNumber:fromNumber,receiverNumber:receiver, currentLead: lead, currentSelectedSiId: [currentSiId]}})
  };

  const editCallRemark = (lead, fromNumber, currentSiId, receiverStatus, callId) => {
    trackEvent('click_add_remark_incoming_calls', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
    console.log(lead, fromNumber, currentSiId, receiverStatus, callId);
    setCallInformation(lead, fromNumber, currentSiId);
    const callAnswered = receiverStatus === 'ANSWER' ? true : false;
    history.push(`/call-status?callId=${callId}&&callAnswered=${callAnswered}`);
  }
  const openWhatsAppPop = (event, index) => {
    event.stopPropagation();
    setSelectedApplicationIndex(index);
    setWhatsAppMessageModal(true);
  }
  const copyPhoneNumberFunc = (event, phoneNumber) => {
    event.stopPropagation();
    navigator.clipboard.writeText(phoneNumber);
  }
  return (
    <>
      {whatsAppMessageModal ? <WhatsappTemplateModal
        lead={lead}
        leadDetails={leadDetails}
        whatsAppMessageModal={whatsAppMessageModal}
        whatsAppMessageModalCancel={whatsAppMessageModalCancel}
        selectedApplicationIndex={selectedApplicationIndex}
      ></WhatsappTemplateModal> : null}
      <CallerNumberSelectionModal
        showModal={showCallerSelectionModal}
        closeModal={closeCallerSelectionModal}
        callerNumberList={callerNumberList}
      ></CallerNumberSelectionModal>
      {
        lead.scheduledInterviewId.map((ele, index) => {
          return (
            <Card
              key={'card' + index}
              className='card-view-candidate'
              ref={candidateCardRef}
              onClick={(event) => { onClickViewDetails(event, ele, lead.recommendationHistoriesId[index]); setCallInformation(lead, mitraReducer.mitraInfo.phoneNumber, ele) }}
              hoverable
            >
              <Space key={index} direction='vertical' style={{ width: '100%' }}>
                <div className='d-flex justify-content-between'>
                  <div className='hot-lead-main-container-left-side' style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                    <div className='d-flex flex-column'>
                      <div className=" d-flex">
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className="client-logo">
                              <img className="imgResponse" style={{ width: 68, heigth: 32 }} alt={lead.companies[index]} src={lead?.logos[index]} />
                            </div>
                            <div style={{ marginLeft: '1em', color: '#000000D9', fontWeight: '500', fontSize: '16px' }}>
                              {lead.lastName.includes('Unknown') ? lead.firstName : lead.firstName + " " + lead.lastName}
                            </div>
                            {lead.highIntent ?
                              <div className='high-intent-lead show-only-desktop'>
                                <ThunderboltFilled style={{marginLeft: 16, marginRight: 6}}/>High Intent
                              </div>
                            : null}
                          </div>
                        </div>
                      </div>
                      {lead.companyCity ?
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <div className="display-city-name">
                              <Tag color="blue" style={{ fontWeight: 'bold', color: '#1890FF' }}>Leads to follow up with</Tag>
                              <EnvironmentOutlined style={{ marginLeft: '5px', marginRight: '3px' }} /> {lead.companyCity[index]}
                              <div style={{display: 'flex', alignItems: 'center', marginLeft: '10px'}}>
                                <CalendarOutlined style={{ marginRight: '3px' }} />
                                <span className='show-only-desktop'>Referred on&nbsp;</span> {moment(lead.ujfmCreatedAt[index]).format('Do MMM YYYY')}
                              </div>
                            </div> 
                            {
                              lead.onboardedFromZomato ?
                                <div style={{display: 'flex', alignItems: 'center', color: '#00000073', fontSize: '12px'}}>
                                  <div style={{ margin: '0px 8px' }}>{'\u2022'}</div>
                                  <SyncOutlined style={{marginRight: '3px'}} />
                                  <span>Onboarded from Zomato</span>
                                </div>
                              : null
                            }
                            
                          </div>
                        : null
                      }
                      <Space direction='horizontal' className='application-timeline-view show-only-desktop'>
                        <div className='d-flex left-of-separator'>
                            <div className='d-flex flex-column justify-content-center align-items-center recently-completed-milestone'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    {returnLineImage(4, true, false)}
                                    <CheckCircleOutlined className='completed-step-icon' />
                                    <div className='d-flex' style={{ gap: 0 }}>
                                        {returnLineImage(16, false, false)}
                                    </div>
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <Text className='recently-completed-milestone-txt'>
                                        {lead?.completedMilestone?.[index]?.milestone}
                                    </Text>
                                    <Text className='next-txt'>
                                        {lead?.completedMilestone?.[index]?.time}
                                    </Text>
                                </div>
                            </div>

                            {returnLineImage(24, false)}

                            <div className='d-flex flex-column justify-content-center align-items-center next-milestone'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    {returnLineImage(12, false, false)}
                                    <div className='orange-circle' />
                                    {returnLineImage(4, true, false)}
                                </div>
                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                    <Text className='next-milestone-txt'>
                                        {lead?.nextMilestone?.[index]?.milestone}
                                    </Text>
                                    <Text className='next-txt'>
                                        {lead?.nextMilestone?.[index]?.time}
                                    </Text>
                                </div>
                            </div>
                        </div>

                        <div className='timeline-steps-completed-separator' style={{marginLeft: '15px', marginRight: '15px'}}>
                            <div className='application-timeline-view-separator'>

                            </div>
                        </div>

                        <div className='steps-completed d-flex justify-content-center align-items-center flex-column'>
                            <div className='trips-count-completed-txt'>{lead.totalOrders?.[index] ? lead.totalOrders[index] : '0' }</div>
                            <div className='trips-completed-txt'>
                                Trips completed
                            </div>
                        </div>
                    </Space>
                    <Space direction='horizontal' className='application-timeline-view show-only-mobile'>
                        <div className='d-flex flex-row align-center'>
                            <div className='d-flex flex-column left-of-separator-mobile'>
                                <div className='d-flex flex-column justify-content-center align-items-center recently-completed-milestone'>
                                    <div className='d-flex flex-column justify-content-flex-start align-items-flex-start'>
                                        <div style={{ marginBottom: "-6px" }}>
                                            {returnLineImage(2, true, true)}
                                        </div>
                                        <div className='d-flex flex-row justify-content-center align-items-center'>
                                            <CheckCircleOutlined className='completed-step-icon-mobile' />
                                            <div className='d-flex flex-column justify-content-flex-start align-items-flex-start recently-completed-milestone-mobile'>
                                                <Text className='recently-completed-milestone-txt recently-completed-milestone-txt-mobile'>
                                                    {lead?.completedMilestone?.[index]?.milestone}
                                                </Text>
                                                <Text className='next-txt'>
                                                    {lead?.completedMilestone?.[index]?.time}
                                                </Text>
                                            </div>
                                        </div>
                                        {returnLineImage(2, false, true)}
                                    </div>
                                </div>

                                <div style={{ marginTop: "-16px" }}>
                                    {returnLineImage(4, false, true)}
                                </div>

                                <div className='d-flex flex-row justify-content-flex-start align-items-flex-start next-milestone'>
                                    {/* <div className='d-flex justify-content-center align-items-center'> */}
                                    {/* {returnLineImage(4, false)} */}
                                    <div className='orange-circle-mobile' />
                                    {/* {returnLineImage(4, true)} */}
                                    {/* </div> */}
                                    <div className='d-flex flex-column justify-content-flex-start align-items-flex-start next-milestone-mobile'>
                                        <Text className='next-milestone-txt-mobile'>
                                          {lead?.nextMilestone?.[index]?.milestone}
                                        </Text>
                                        <Text className='next-txt'>
                                          {lead?.nextMilestone?.[index]?.time}
                                        </Text>
                                    </div>
                                </div>
                                <div style={{ marginTop: "-16px" }}>
                                    {returnLineImage(4, true, true)}
                                </div>
                            </div>

                            <div className='timeline-steps-completed-separator-mobile'>
                                <div className='application-timeline-view-separator-mobile'>

                                </div>
                            </div>

                            <div className='steps-completed-mobile d-flex flex-column justify-content-center align-items-center'>
                                  <div className='trips-count-completed-txt'>{lead.totalOrders?.[index] ? lead.totalOrders[index] : '0' }</div>
                                  <div className='trips-completed-txt'>
                                      Trips completed
                                  </div>
                            </div>
                        </div>

                        
                    </Space>
                    <div className="hot-lead-main-container-right-side show-only-mobile">
                      {lead?.callLogsTagsArray?.[index]?.length && callLogsOpened ?
                          <div className='main-container-divider-right' style={callLogsOpened ? { height: '85px' } : {height: '50px' }}>
                            {callNotesArray(index)}
                          </div>
                      : null}
                    </div>
                      {/* <Space direction='horizontal' className="onboarding-schedule">
                        <div className="leadsCardStatus d-flex flex-row" style={lead.latestMilestone[index] !== "Activated" ? { color: '#52C41A' } : null}>
                          {lead.latestMilestone[index]}
                        </div>
                        <div style={{ color: '#00000073', fontSize: '12px' }}>{'\u2022'}</div>
                        <div className="diffInDate">
                          {moment(lead.ujfmCreatedAt[index]).format('Do MMM YYYY')}
                        </div>
                      </Space> */}

                      <div className='show-only-mobile-footer'>
                          {lead.mitraName ? <div className='added-mitra-name' style={{width: '60%'}}><UserOutlined style={{ marginRight: '3px' }} />Added by&nbsp;<span className='display-mitra-name'>{[...new Set(lead.mitraName)].toString()}</span></div> : null}
                          {lead?.callLogsTagsArray?.[index]?.length ?
                          <div className='added-mitra-name' style={{width: '40%', justifyContent: 'right'}} onClick={(e) => onClickUpDown(e)}>
                              {callLogsOpened ?
                                  <UpOutlined className='call-logs-opened-arrow-icon'/>
                                  : <DownOutlined className='call-logs-opened-arrow-icon' />
                              }
                          </div> 
                          : null}
                      </div>
                    </div>

                  </div>
                  <div className="hot-lead-main-container-right-side show-only-desktop">
                        {lead?.callLogsTagsArray?.[index]?.length ?
                            <div className='main-container-divider-right' style={{height: '85px'}}>
                              {callNotesArray(index)}
                            </div>
                        : null}
                    </div>
                  <Space direction='vertical'>
                  <div className='d-flex flex-column'>
				{(CONSTANTS.highIntentEligibleMitras.includes(mitraReducer?.mitraInfo?.id) ||
                    CONSTANTS.highIntentEligibleMitras.includes(mitraReducer?.mitraInfo?.managerMitraID)) &&
                    lead.highIntent ?
                      <div className='high-intent-lead show-only-mobile'>
                        <ThunderboltFilled style={{marginLeft: 16, marginRight: 6}}/>High Intent
                      </div>
                    : null}
                  </div>
                  </Space>
                </div>

                {/* <div className='d-flex flex-row justify-content-between' style={{ marginTop: '1.2em', alignItems: 'center' }}> */}
                  {
                    // <div className='call-tags-info'>
                    //   <div className='d-flex justify-content-start flex-direction-row flex-wrap' style={{ gap: '8px' }}>
                    //     {
                    //       lead.latestCallType?.[index] !== 'incoming' ?
                    //         (lead.latestCallIsConnected?.[index] === true ?
                    //           <Tag color="green" className='call-tag-recruiter-card-custom'>
                    //             <PhoneFilled style={{ color: '#52C41A' }} />Connected{lead.latestCallStartedAt?.[index] ? `: ${moment(lead.latestCallStartedAt?.[index]).format('DD MMM')}` : null}
                    //           </Tag> :
                    //           lead.latestCallIsConnected?.[index] === false ?
                    //             <Tag color="red" className='call-tag-recruiter-card-custom'>
                    //               <PhoneFilled style={{ color: '#F5222D' }} />Not Connected{lead.latestCallStartedAt?.[index] ? `: ${moment(lead.latestCallStartedAt?.[index]).format('DD MMM')}` : null}
                    //             </Tag> : null) : null
                    //     }
                    //     {
                    //       lead.latestCallType?.[index] === 'incoming' ? <Tag color="blue" className='call-tag-recruiter-card-custom'>
                    //         <PhoneFilled style={{ color: 'blue' }} />Incoming Call</Tag> : null
                    //     }
                    //     {
                    //       lead.latestCallTag?.[index] ? <Tag>{lead.latestCallTag[index]}</Tag> : null
                    //     }
                    //     {
                    //       lead.latestFollowUpNeeded?.[index] ? <Tag>Follow up again</Tag> : null
                    //     }
                    //     {
                    //       lead.followUpMessage?.[index] ?
                    //         <div className='action-banner' style={{ marginLeft: 0 }}>
                    //           <Text
                    //             style={{ fontFamily: 'Nunito Sans', color: '#00000073', fontStyle: 'normal', fontWeight: '700', fontSize: '12px', lineHeight: '20px' }}>
                    //             {'\u2022'} Start following up:{`  ${lead.followUpMessage?.[index]}`}
                    //           </Text>
                    //         </div> : null
                    //     }
                    //     {
                    //       lead.latestCallType?.[index] === 'incoming' && !lead.latestCallTag?.[index] ?
                    //         <Button
                    //           style={{ color: 'block', background: '#FFFFFF', fontSize: '12px', height: '24px', padding: '2px 5px 2px 5px', border: '1px solid #2F54EB', display: 'flex', alignItems: 'center' }}
                    //           onClick={() => editCallRemark(lead, mitraReducer.mitraInfo.phoneNumber, ele, lead.latestCallReceiverStatus?.[index], lead.latestCallId?.[index])}
                    //         ><PlusOutlined />Add Remark</Button> : null
                    //     }
                    //   </div>
                    //   {/* {
                    //         lead.scheduledInterviewId.length > index+1 ? <Divider></Divider>: null
                    //       } */}
                    // </div>
                  }

                  
                {/* </div> */}
              </Space>
              <Divider></Divider>
              <div className='d-flex justify-content-between show-only-desktop'>
                {lead.mitraName ? <div className='added-mitra-name'><UserOutlined style={{ marginRight: '3px' }} />Added by&nbsp;<span className='display-mitra-name'>{[...new Set(lead.mitraName)].toString()}</span></div> : null}
                <div className='d-flex' style={{ alignItems: 'center', justifyContent: 'center' }}>
                  <div className="contact-buttons">
                    <Button onClick={(e) => copyPhoneNumberFunc(e, lead.parentPhoneNumber[0] ? `${lead.parentPhoneNumber[0]}(edited)` : lead.phoneNumber)}
                      icon={<CopyOutlined />} type="primary" ghost>
                      {lead.parentPhoneNumber[0] ? `${lead.parentPhoneNumber[0]}(edited)` : lead.phoneNumber}
                    </Button>
                    <Button onClick={(e) => openWhatsAppPop(e, index)} icon={<WhatsAppOutlined />} type="primary" ghost>Message</Button>
                    {
                        // app complete
                        // && lead.latestMilestone[index] === 'Unique'
                        (
                          ( 
                              lead.latestMilestone[index] === 'Incomplete Application' && 
                              lead.companies[index].toLowerCase() !== 'swiggy' && 
                              lead.companies[index].toLowerCase() !== 'zomato' // Excluding zomato out of this check as we donot allow non-unique to see additional info form
                          ) 
                          ||
                          ( 
                            lead.latestMilestone[index] === 'Lead Referred' && 
                            lead.companies[index].toLowerCase() === 'zepto'
                          )
                          || 
                          ( 
                            lead.latestMilestone[index] === 'Unique' && 
                            lead.companies[index].toLowerCase() === 'blinkit'
                          )
                          ||
                          (
                            // for cohort based handling, excluded swiggy from above condition as we will show the edit application button
                            // to only unique swiggy leads that too to the ones who are in our list
                            lead.companies[index].toLowerCase() === 'swiggy' && 
                            lead.latestMilestone[index] === 'Unique'
                          )
                          ||
                          (lead.companies[index].toLowerCase() === 'zomato' && lead.latestMilestone[index] === 'Unique')
                      ) ?
                          <>
                            {
                              AdditionalFormsURLEnum[lead.companies[index]?.toLowerCase()] ?
                                // <div onClick={onClickCompleteApplication}>
                                
                                <div className='d-flex'>
                                <Button onClick={() => onClickCompleteApplication(index)} style={{ margin: 0, display: "flex", justifyContent: "center", alignItems: "center" }} icon={<UploadOutlined />} type="primary">
                                  <span style={{ fontWeight: 800 }}>Complete Application</span>
                                </Button>
                                {
                                  (['zomato','swiggy','zepto', 'blinkit'].includes(lead.companies[index].toLowerCase())) ? <img src="/image/new-icon.gif" style={{height: '25px',width: 'auto'}} />: null
                                }
                                </div>
                                
                                // </div> 
                                : null
                            }
                          </>
                          : null
                      }
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-between show-only-mobile'>
                <div className='d-flex' style={{ alignItems: 'center', marginTop: '-10px', width: '100%' }}>
                  <div className="contact-buttons" style={{ width: '30%'}}>
                    <a
                      href={`tel:+91-${lead.parentPhoneNumber[0] ? lead.parentPhoneNumber[0] : lead.phoneNumber}`}
                      onClick={(e) => {
                        GAEventsTracker("CallCandidate"); e.stopPropagation();
                        trackEvent('call_now_option_clicked', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS)
                      }}>
                      <Button className='button-phone-number'
                        icon={<PhoneOutlined />} type="primary" ghost>
                      </Button>
                    </a>
                    <Button onClick={(e) => openWhatsAppPop(e, index)} className='button-phone-number' type="primary" ghost icon={<WhatsAppOutlined />}></Button>
                    </div>
                    <div style={{ width: '70%'}}>
                    {
                    // app complete
                    // && lead.latestMilestone[index] === 'Unique'
                    (
                      ( 
                          lead.latestMilestone[index] === 'Incomplete Application' && 
                          lead.companies[index].toLowerCase() !== 'swiggy' && 
                          lead.companies[index].toLowerCase() !== 'zomato' // Excluding zomato out of this check as we donot allow non-unique to see additional info form
                      ) 
                      ||
                      ( 
                        lead.latestMilestone[index] === 'Lead Referred' && 
                        lead.companies[index].toLowerCase() === 'zepto'
                      )
                      || 
                      (
                        // for cohort based handling, excluded swiggy from above condition as we will show the edit application button
                        // to only unique swiggy leads that too to the ones who are in our list
                        lead.companies[index].toLowerCase() === 'swiggy' && 
                        lead?.latestMilestone?.[index]?.toLowerCase() === 'unique'
                      )
                      ||
                      (
                        lead.companies[index].toLowerCase() === 'blinkit' && 
                        lead?.latestMilestone?.[index]?.toLowerCase() === 'unique'
                      )
                      || 
                      (lead.companies[index].toLowerCase() === 'zomato' && lead?.latestMilestone?.[index]?.toLowerCase() === 'unique')
                  ) ?
                      <>
                        {
                          AdditionalFormsURLEnum[lead.companies[index]?.toLowerCase()] ?
                            // <div onClick={onClickCompleteApplication}>
                            
                            <div className='d-flex'>
                            <Button onClick={() => onClickCompleteApplication(index)} style={{ margin: 0, display: "flex", justifyContent: "right", alignItems: "center" }} icon={<UploadOutlined />} type="primary">
                              <span style={{ fontWeight: 800 }}>Complete Application</span>
                            </Button>
                            {
                              ['zomato','swiggy', 'blinkit','zepto'].includes(lead.companies[index].toLowerCase()) ? <img src="/image/new-icon.gif" style={{height: '25px',width: 'auto'}} />: null
                            }
                            </div>
                            
                            // </div> 
                            : null
                        }
                      </>
                      : null
                  }
                  </div>
                </div>
              </div>
            </Card>
          )
        })
      }
    </>
  );
};
export default RecruiterCard;
